import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import * as ACTIONS from "../store/actions/actions";
import {
  IsocodeReducer,
  initialState,
} from "../store/reducers/isocode-reducer";

export const IsocodeContext = createContext();

export const IsocodeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(IsocodeReducer, initialState);

  const addValidCountryState = (validCountryStateCheck) => {
    dispatch({
      type: ACTIONS.ADD_VALID_COUNTRY_STATE_ISOCODE,
      payload: {
        validCountryStateCheck,
      },
    });
  };

  const resetValidCountryState = () => {
    dispatch({
      type: ACTIONS.RESET_VALID_COUNTRY_STATE_ISOCODE,
    });
  };

  const value = {
    validCountryStateCheck: state.validCountryStateCheck,
    addValidCountryState,
    resetValidCountryState,
  };

  return (
    <IsocodeContext.Provider value={value}>{children}</IsocodeContext.Provider>
  );
};

IsocodeProvider.defaultProps = {
  children: {},
};
IsocodeProvider.propTypes = {
  children: PropTypes.node,
};

const useValidCountriesStateLookup = () => {
  const context = useContext(IsocodeContext);

  if (context === undefined) {
    throw new Error(
      "useValidCountriesStateLookup must be used with IsocodeContext"
    );
  }

  return context;
};

export default useValidCountriesStateLookup;

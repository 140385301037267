import * as ACTIONS from "../actions/actions";

export const initialState = {
  groupDetails: {
    requestedBy: "",
    groupName: "",
    reason: "",
    members: [],
    camFunctions: [],
  },
  groupValidState: {
    name: { state: "", message: "" },
    reason: { state: "", message: "" },
    functions: { state: "", message: "" },
    members: { state: "", message: "" },
  },
};

export const GroupsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.CREATE_GROUP_DETAILS:
      return {
        ...state,
        groupDetails: { ...payload.groupDetails },
      };
    case ACTIONS.RESET_GROUP_DETAILS:
      return {
        ...state,
        groupDetails: { ...initialState.groupDetails },
      };
    case ACTIONS.CREATE_GROUP_VALID_STATE:
      return {
        ...state,
        groupValidState: { ...payload.groupValidState },
      };
    case ACTIONS.RESET_GROUP_VALID_STATE:
      return {
        ...state,
        groupValidState: { ...initialState.groupValidState },
      };
    default:
      return state;
  }
};

import {
  ActionMenu,
  Badge,
  DialogContainer,
  Flex,
  Heading,
  Item,
  Link as V3Link,
  Text,
  View,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { error } from "@react/react-spectrum/Toast";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { crossSystemAccountApi } from "../../../../../api/lookupApi";
import Table from "../../../../../components/Common/Table";
import SearchSourceAccountsDialog from "../../../../../components/Dialog/SearchSourceAccountsDialog";
import { EAMSplitSyncSetColumns } from "../../../../../constants/Columns";
import { EAMSourceSystemAccountLabel } from "../../../../../constants/SlidingPaneLabels";

const splitNewAccountOptions = [
  { id: "choose-existing", label: "Choose existing crm account" },
  { id: "create-new", label: "Create new account (default)" },
];

export default function SplitCreateSyncSetStep({
  title,
  syncSet,
  setSyncSet,
  setSidePaneDetails,
  origSourceAccount,
}) {
  const [searchDialog, setSearchDialog] = useState({
    isOpen: false,
    sourceSystem: "",
  });
  const eamSplitSyncSetColumns = EAMSplitSyncSetColumns();
  const { authState } = useOktaAuth();

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  const isCompleteSync = (subscribers) =>
    subscribers?.every((sourceSystem) =>
      syncSet?.some((acc) => acc?.source_system === sourceSystem)
    );

  useEffect(() => {
    if (origSourceAccount) {
      crossSystemAccountApi
        .fetchMissingSourceSystems(
          origSourceAccount?.source_system,
          authState.accessToken
        )
        .then((data) => {
          if (!isCompleteSync(data)) {
            data?.forEach((sourceSystem) => {
              setSyncSet((prev) => {
                if (
                  prev.some(
                    (prevItem) => prevItem?.source_system === sourceSystem
                  )
                )
                  return prev;

                return [
                  ...prev,
                  {
                    ...origSourceAccount,
                    source_system: sourceSystem,
                    account_id: "new",
                    id: `new:${sourceSystem}`,
                    syncSource: false,
                    isNewAccount: true,
                  },
                ];
              });
            });
          } else {
            setSyncSet((prev) =>
              prev?.map((prevItem) => {
                if (prevItem?.account_id === "new")
                  return {
                    ...origSourceAccount,
                    source_system: prevItem?.source_system,
                    account_id: prevItem?.account_id,
                    id: prevItem?.id,
                    syncSource: prevItem?.syncSource,
                    isNewAccount: prevItem?.isNewAccount,
                  };

                return prevItem;
              })
            );
          }
        })
        .catch((e) => handleError(e));
    }
  }, [origSourceAccount]);

  const handleMoreActions = (key, sourceSystem) => {
    if (key === "choose-existing")
      setSearchDialog({ sourceSystem, isOpen: true });
    if (key === "create-new")
      setSyncSet((prev) =>
        prev?.map((prevItem) => {
          if (prevItem?.source_system === sourceSystem)
            return {
              ...origSourceAccount,
              source_system: sourceSystem,
              account_id: "new",
              id: `new:${sourceSystem}`,
              syncSource: false,
              isNewAccount: true,
            };
          return prevItem;
        })
      );
  };

  const renderMoreActions = (row) => (
    <ActionMenu
      items={splitNewAccountOptions}
      isQuiet
      onAction={(key) => handleMoreActions(key, row?.source_system)}
    >
      {(item) => <Item key={item?.id}>{item?.label}</Item>}
    </ActionMenu>
  );

  const renderCell = (colKey, row) => {
    let cellStyle = {};

    if (colKey === "account_id" && !row?.isNewAccount)
      return (
        <Flex gap="size-125">
          <V3Link
            isQuiet
            onPress={() => {
              setSidePaneDetails(
                row,
                row.account_name,
                EAMSourceSystemAccountLabel()
              );
            }}
            alignSelf={row?.syncSource ? "center" : "normal"}
          >
            {row[colKey]}
          </V3Link>
          {row?.syncSource && (
            <Badge
              variant="seafoam"
              alignSelf="center"
              UNSAFE_style={{ fontSize: "x-small" }}
            >
              Original source
            </Badge>
          )}
        </Flex>
      );

    if (colKey === "actions" && row?.isNewAccount)
      return renderMoreActions(row);

    if (row?.isNewAccount) {
      cellStyle = { color: "green" };
    }
    if (colKey === "address_key" && row?.address) {
      const address = `${row.address[0].street ? row.address[0].street : ""} ${
        row.address[0].street_sup ? row.address[0].street_sup : ""
      } ${row.address[0].city ? row.address[0].city : ""}, ${
        row.address[0].state ? row.address[0].state : ""
      } ${row.address[0].country ? row.address[0].country : ""} ${
        row.address[0].postalcode ? row.address[0].postalcode : ""
      }`;
      return <Text>{address}</Text>;
    }
    return <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };

  return (
    <Flex direction="column" gap="size-150" marginTop="size-300">
      <Flex justifyContent="left">
        <View
          backgroundColor="static-white"
          borderRadius="medium"
          borderWidth="thin"
          borderColor="gray-300"
          padding="size-125"
          width="size-2000"
          UNSAFE_className="stepper-box"
        >
          <Flex justifyContent="center">
            <Heading>{title}</Heading>
          </Flex>
        </View>
      </Flex>
      <Flex
        direction="row"
        gap="size-125"
        UNSAFE_style={{
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
        UNSAFE_className="stepper-box"
      >
        <Table
          columns={eamSplitSyncSetColumns}
          rows={syncSet}
          renderCell={renderCell}
          hideHeader
          density="compact"
        />
      </Flex>
      <DialogContainer
        onDismiss={() =>
          setSearchDialog((prev) => ({ ...prev, isOpen: false }))
        }
      >
        {searchDialog?.isOpen && (
          <SearchSourceAccountsDialog
            sourceSystem={searchDialog.sourceSystem}
            setSyncSet={setSyncSet}
          />
        )}
      </DialogContainer>
    </Flex>
  );
}

SplitCreateSyncSetStep.propTypes = {
  title: PropTypes.string.isRequired,
  syncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  setSyncSet: PropTypes.func.isRequired,
  setSidePaneDetails: PropTypes.func.isRequired,
  origSourceAccount: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
    ])
  ).isRequired,
};

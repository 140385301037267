import {
  Button,
  ButtonGroup,
  ComboBox,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Item,
  Text,
  useDialogContainer,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { eamApi } from "../../api/eamApi";
import useValidCountriesStateLookup from "../../context/isocode-context";

export default function PickStateCountryDialog({ handleNext, handleBack }) {
  const [isoCodes, setIsoCodes] = useState([]);
  const [stateCountry, setStateCountry] = useState({ state: "", country: "" });
  const dialog = useDialogContainer();
  const { authState } = useOktaAuth();

  const { validCountryStateCheck } = useValidCountriesStateLookup();

  useEffect(() => {
    eamApi
      .fetchISOCodes(authState.accessToken)
      .then((data) => setIsoCodes(data));
  }, []);

  return (
    <Dialog size="L">
      <Heading>Pick standardized 2 letter country/state ISO code</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-500">
          <Text>
            Original source of sync set does not seem to have standardized 2
            letter country/state ISO code. Kindly select the country/state ISO
            code from below:
          </Text>
          <Flex gap="size-125">
            <ComboBox
              label="Choose country"
              defaultItems={isoCodes}
              onSelectionChange={(value) => {
                setStateCountry((prev) => ({ ...prev, country: value }));
                setStateCountry((prev) => ({ ...prev, state: "" }));
              }}
            >
              {(item) => (
                <Item key={item?.code}>{`${item?.label} (${item?.code})`}</Item>
              )}
            </ComboBox>
            {stateCountry?.country &&
              validCountryStateCheck.countries.includes(
                stateCountry?.country
              ) && (
                <ComboBox
                  label="Choose state"
                  defaultItems={
                    isoCodes?.find(
                      (isocode) => isocode?.code === stateCountry?.country
                    )?.children
                  }
                  onSelectionChange={(value) =>
                    setStateCountry((prev) => ({ ...prev, state: value }))
                  }
                >
                  {(item) => (
                    <Item
                      key={item?.code}
                    >{`${item?.label} (${item?.code})`}</Item>
                  )}
                </ComboBox>
              )}
          </Flex>
        </Flex>
      </Content>
      <ButtonGroup>
        <Button
          variant="secondary"
          onPress={() => {
            handleBack();
            dialog.dismiss();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="accent"
          isDisabled={
            !stateCountry?.country ||
            stateCountry?.country?.length === 0 ||
            (validCountryStateCheck.countries.includes(stateCountry?.country) &&
              (stateCountry?.state?.length === 0 || !stateCountry?.state))
          }
          onPress={() => {
            dialog.dismiss();
            handleNext(stateCountry?.state, stateCountry?.country);
          }}
        >
          Confirm
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

PickStateCountryDialog.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

import { Flex, Heading, Text } from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import React from "react";
import "./stepper.css";

export default function Stepper({ items, activeItemStep }) {
  //   const changeStepStyle = () => {
  //     items.forEach((item) => {
  //       let stepBackgroundColor = "rgb(117, 113, 117)";
  //       let lineColor = "#e0e0e0";
  //       let content = item.step;

  //       if (item.step < activeItemStep) {
  //         // stepBackgroundColor = "rgb(62, 142, 211)";
  //         stepBackgroundColor = "#1da973";
  //         // lineColor = "rgb(62, 142, 211)";
  //         lineColor = "#1da973";
  //         content = "\u2713";
  //       }

  //       document
  //         .getElementById(`step${item.step}`)
  //         .style.setProperty("--step-background-color", stepBackgroundColor);
  //       document
  //         .getElementById(`step${item.step}`)
  //         .style.setProperty("--line-color", lineColor);

  //       document
  //         .getElementById(`step${item.step}`)
  //         .setAttribute("data-content", content);
  //     });
  //   };

  //   useEffect(() => {
  //     if (activeItemStep) {
  //       changeStepStyle();
  //     }
  //   }, [activeItemStep]);

  //   return (
  //     <Flex direction="column">
  //       <div className="c-stepper">
  //         {items.map((item) => (
  //           <div
  //             className="c-stepper__item"
  //             data-content={item.step}
  //             id={`step${item.step}`}
  //             key={`step${item.step}`}
  //           >
  //             <h3 className="c-stepper__title">{item.title}</h3>
  //             <p className="c-stepper__desc">{item.description}</p>
  //           </div>
  //         ))}
  //       </div>
  //       <br />
  //       <View margin="size-300">
  //         <Button
  //           variant="cta"
  //           onPress={() => {
  //             setActiveItemStep((prev) => {
  //               if (prev <= items.length) {
  //                 return prev + 1;
  //               }
  //               return 1;
  //             });
  //           }}
  //         >
  //           Next
  //         </Button>
  //       </View>
  //     </Flex>
  //   );
  return (
    <Flex direction="column">
      <Flex wrap="wrap" UNSAFE_className="c-stepper">
        {items.map((item) => {
          let dataStepStatus = "tbd";
          if (activeItemStep === item.step) dataStepStatus = "current";
          else if (activeItemStep > item.step) {
            dataStepStatus = "finished";
          }

          return (
            <Flex
              direction="column"
              flex={1}
              data-content={item.step < activeItemStep ? "\u2713" : item?.step}
              id={`step${item.step}`}
              key={`step${item.step}`}
              UNSAFE_className="c-stepper__item"
              data-step-status={dataStepStatus}
            >
              <Heading level={3} marginBottom="0.5rem">
                {item.title}
              </Heading>
              <Text>{item.description}</Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}

Stepper.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ).isRequired,
  activeItemStep: PropTypes.number.isRequired,
};

import {
  ActionButton,
  Icon,
  Item,
  Menu,
  MenuTrigger,
  Text,
  View,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import Avatar from "@react/react-spectrum/Avatar";
import LogOut from "@spectrum-icons/workflow/LogOut";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import avatar from "../../../../assets/images/profile-icon.png";
import { AppLogoutPath } from "../../../../constants/Routes";
import useUserProfile from "../../../../context/user-context";

const AvatarIcon = (
  <Avatar
    src={avatar}
    alt="User"
    style={{ width: "1.5rem", height: "1.5rem" }}
  />
);

const InfoDropDown = () => {
  const [isPicLoaded, setIsPicLoaded] = useState(false);
  const { user } = useUserProfile();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  const fetchPic = async () => {
    await fetch(`https://s7d2.scene7.com/is/image/IMGDIR/${user?.userId}`)
      .then((res) => {
        if (res.status === 200) setIsPicLoaded(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (user?.userId !== null) {
      fetchPic();
    }
  }, [user]);

  const renderAvatar = () => {
    const loadedIcon = isPicLoaded ? (
      <Avatar
        style={{ width: "2rem", height: "2rem" }}
        src={`https://s7d2.scene7.com/is/image/IMGDIR/${user?.userId}`}
        alt="User"
      />
    ) : (
      AvatarIcon
    );

    return loadedIcon;
  };
  return (
    <View>
      <MenuTrigger>
        <ActionButton isQuiet UNSAFE_style={{ borderRadius: "50%" }}>
          {renderAvatar()}
        </ActionButton>
        <Menu
          onAction={(key) => {
            if (key === "logout_button") {
              oktaAuth.tokenManager.clear();
              sessionStorage.removeItem("ims-token-storage");
              history.push(AppLogoutPath, { isLoggedOut: true });
            }
          }}
        >
          <Item key="display_pic_name">
            <Icon
              UNSAFE_style={{
                width: "2rem",
                height: "2rem",
              }}
              justifySelf="center"
              alignSelf="center"
            >
              <Avatar
                src={`https://s7d2.scene7.com/is/image/IMGDIR/${user?.userId}`}
                alt="User"
              />
            </Icon>
            <Text>{user?.name}</Text>
            <Text slot="description">{user?.email}</Text>
          </Item>
          <Item key="logout_button">
            <>
              <LogOut color="negative" />
            </>
            <Text>Logout</Text>
          </Item>
        </Menu>
      </MenuTrigger>
    </View>
  );
};

export default InfoDropDown;

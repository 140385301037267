import { View } from "@adobe/react-spectrum";
import {
  SideNav,
  SideNavHeading,
  SideNavItem,
} from "@react/react-spectrum/SideNav";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function CustomSideNav({ items }) {
  const history = useHistory();

  const [activeNavId, setActiveNavId] = useState();

  useEffect(() => {
    setActiveNavId(
      items?.find((item) => history.location.pathname.includes(item.url))?.url
    );
  }, [items]);

  const handleOnSelect = (key) => {
    history.push(key);
  };
  return activeNavId ? (
    <View padding="1rem">
      <SideNav value={activeNavId} onSelect={(key) => handleOnSelect(key)}>
        <SideNavHeading label="Overview">
          {items.map((item) => (
            <SideNavItem key={item?.url} value={item?.url}>
              {item?.label}
            </SideNavItem>
          ))}
        </SideNavHeading>
      </SideNav>
    </View>
  ) : (
    <></>
  );
}

CustomSideNav.defaultProps = {
  items: [],
};

CustomSideNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.object)),
};

import {
  ActionButton,
  Button,
  Flex,
  Heading,
  Text,
  View,
} from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import { error } from "@react/react-spectrum/Toast";
import { useOktaAuth } from "@okta/okta-react";
import Stepper from "../../../components/Common/Stepper/Stepper";
import { OverrideLagGroupSteps } from "../../../constants/StepperItems";
import { LookupLogicalAccountGroupingPath } from "../../../constants/Routes";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import { lagApi } from "../../../api/lagApi";
import { LAGOverrideRule } from "../../../constants/Columns";
import LoadingDialog from "../../../components/Dialog/LoadingDialog";
import useUserProfile from "../../../context/user-context";

export default function OverrideLAG() {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(1);
  const [overrideHeading, setOverrideHeading] = useState();
  const [overrideAttributes, setOverrideAttributes] = useState({}); // attributes which CAN be overrode
  const [changedAttributes, setChangedAttributes] = useState({}); // atttributes which ARE overriden
  const [groupDescription, setGroupDescription] = useState("");
  const [overrideGroupColumns, setOverrideGroupColumns] = useState(
    LAGOverrideRule()
  );
  const STEP_ITEMS = OverrideLagGroupSteps();
  const { authState } = useOktaAuth();
  const [isPageLoading, setPageLoading] = useState(false);
  const { user } = useUserProfile();

  const handleError = (e) => {
    setPageLoading(false);
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    setOverrideHeading(
      `Override ${history?.location?.state?.overrideGroup.group_key}`
    );
  });

  useEffect(() => {
    lagApi.fetchGroupKeys(authState.accessToken).then((data) => {
      setGroupDescription(
        data.groups.find(
          (attr) =>
            attr.group_key === history?.location?.state?.overrideGroup.group_key
        ).group_description
      );
    });
  }, []);

  const handlePrevStep = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleNextStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleOverride = () => {
    setPageLoading(true);
    const overrideAttrRequest = [];
    Object.keys(overrideAttributes).map((attr) =>
      overrideAttrRequest.push({
        field_name: attr,
        old_value: overrideAttributes[attr],
        new_value: Object.keys(changedAttributes).includes(attr)
          ? changedAttributes[attr]
          : overrideAttributes[attr],
      })
    );
    lagApi
      .sendOverrideChanges(
        {
          group_key: history?.location?.state?.overrideGroup.group_key,
          attributes: overrideAttrRequest,
          modified_by: user,
        },
        authState.accessToken
      )
      .then(() => {
        setPageLoading(false);
        handleNextStep();
      })
      .catch((e) => handleError(e));
  };

  const handleBack = () => {
    if (activeStep === 2)
      history.push(LookupLogicalAccountGroupingPath, {
        searchFilter: {},
      });
    history.push(LookupLogicalAccountGroupingPath, {
      searchFilter: history?.location?.state?.searchFilter,
    });
  };

  return (
    <Flex direction="row" gap="size-125">
      <LoadingDialog isOpen={isPageLoading} />
      <Flex direction="column" gap="size-125" margin="size-300" width="80%">
        <Flex gap="size-125" justifyContent="space-between">
          <Heading>{overrideHeading}</Heading>
        </Flex>
        <Flex direction="column" gap="size-125">
          <View
            backgroundColor="static-white"
            borderRadius="medium"
            borderWidth="thin"
            borderColor="gray-300"
            padding="size-125"
            UNSAFE_className="stepper-box"
          >
            <Stepper items={STEP_ITEMS} activeItemStep={activeStep} />
          </View>
          <View data-multi-step UNSAFE_className="wrapper">
            <Step1
              activeStep={activeStep}
              overrideGroup={history?.location?.state?.overrideGroup}
              changedAttributes={changedAttributes}
              setChangedAttributes={setChangedAttributes}
              overrideGroupColumns={overrideGroupColumns}
              setOverrideGroupColumns={setOverrideGroupColumns}
              overrideAttributes={overrideAttributes}
              setOverrideAttributes={setOverrideAttributes}
              groupDescription={groupDescription}
            />
            <Step2
              activeStep={activeStep}
              overrideGroup={history?.location?.state?.overrideGroup}
              changedAttributes={changedAttributes}
              overrideGroupColumns={overrideGroupColumns.filter(
                (col) => col.uid !== "edit"
              )}
              overrideAttributes={overrideAttributes}
              groupDescription={groupDescription}
            />
          </View>
          <Flex marginTop="size-300" justifyContent="space-between">
            <Flex>
              <ActionButton isQuiet onPress={handleBack}>
                <ChevronLeft color="informative" />
                <Text>Logical Account Grouping</Text>
              </ActionButton>
            </Flex>
            <Flex gap="size-125">
              {activeStep > 1 && activeStep <= STEP_ITEMS.length - 1 && (
                <Button variant="primary" onPress={handlePrevStep}>
                  <Text>Previous</Text>
                </Button>
              )}
              {activeStep <= STEP_ITEMS.length - 1 &&
                activeStep !== STEP_ITEMS.length - 1 && (
                  <Button variant="accent" onPress={() => handleNextStep()}>
                    <Text>Next</Text>
                  </Button>
                )}

              {activeStep === STEP_ITEMS.length - 1 && (
                <Button
                  variant="accent"
                  onPress={handleOverride}
                  isDisabled={Object.keys(changedAttributes).length === 0}
                >
                  Submit
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

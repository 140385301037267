import {
  Content,
  Flex,
  Form,
  Heading,
  IllustratedMessage,
  Item,
  Link as V3Link,
  Picker,
  Text,
  View,
} from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import NotFound from "@spectrum-icons/illustrations/NotFound";
import Roles from "../../../constants/Roles";
import { ADMIN } from "../../../constants/RoleType";

const GroupMembersInfoForm = ({ data }) => {
  const [filters, setFilters] = useState({ role: ADMIN });
  const [members, setMembers] = useState([]);
  const roles = Roles();

  const filterTableData = () =>
    data.filter((user) => user.role === filters.role);

  useEffect(() => {
    setMembers(filterTableData());
  }, [filters]);

  return (
    <Flex
      direction="column"
      gap="size-500"
      UNSAFE_style={{ backgroundColor: "#F5F5F5" }}
    >
      <View>
        <Picker
          label="Choose Role"
          selectedKey={filters.role}
          onSelectionChange={(val) => {
            setFilters({ ...filters, role: val });
          }}
          items={roles}
        >
          {(role) => <Item key={role.id}>{role.label}</Item>}
        </Picker>
      </View>
      <Form UNSAFE_style={{ backgroundColor: "#F5F5F5" }}>
        {members.length > 0 ? (
          members?.map((item) => (
            <Flex
              key={item.email}
              marginBottom={14}
              justifyContent="space-between"
            >
              <Text UNSAFE_style={{ color: "#4B4B4B", fontSize: "0.82rem" }}>
                {item.name}
              </Text>
              <V3Link isQuiet>{item.email}</V3Link>
            </Flex>
          ))
        ) : (
          <IllustratedMessage>
            <NotFound />
            <Heading level={6}>
              No members found with role {filters.role}
            </Heading>
            <Content>Try with another role</Content>
          </IllustratedMessage>
        )}
      </Form>
    </Flex>
  );
};

GroupMembersInfoForm.defaultProps = {
  data: [],
};
GroupMembersInfoForm.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
  ),
};

export default GroupMembersInfoForm;

import {
  ActionButton,
  Badge,
  Button,
  DialogContainer,
  Flex,
  Heading,
  Text,
  View,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { error, success } from "@react/react-spectrum/Toast";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { eamApi } from "../../../../api/eamApi";
import { crossSystemAccountApi } from "../../../../api/lookupApi";
import CamSlidingPane from "../../../../components/Common/CamSlidingPane/CamSlidingPane";
import ConfirmCompleteSyncDialog from "../../../../components/Dialog/ConfirmCompleteSyncDialog";
import LoadingDialog from "../../../../components/Dialog/LoadingDialog";
import PickStateCountryDialog from "../../../../components/Dialog/PickStateCountryDialog";
import AccountMappingForm from "../../../../components/Forms/AccountMappingForm/AccountMappingForm";
import { ExternalAccountMappingPath } from "../../../../constants/Routes";
import useUserProfile from "../../../../context/user-context";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import useValidCountriesStateLookup from "../../../../context/isocode-context";

export default function CompleteSyncSet() {
  const [originalSourceAccount, setOriginalSourceAccount] = useState();
  const [currentSyncSet, setCurrentSyncSet] = useState([]);
  const [modifiedSyncSet, setModifiedSyncSet] = useState([]);
  const [subscriberSourceSystems, setSubscriberSourceSystems] = useState([]);
  const [completeSyncSetPayload, setCompleteSyncSetPayload] = useState({
    requested_by: "",
    orig_source_system_account_id: "",
    orig_source_system_name: "",
    sync_accounts: [],
  });

  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [sidePaneData, setSidePaneData] = useState([]);
  const [sidePaneTitle, setSidePaneTitle] = useState("");
  const [sidePaneLabels, setSidePaneLabels] = useState([]);
  const [isPageLoading, setPageLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [selectedKeys, setSelectedKeys] = useState(new Set([]));
  const [typeOfDialog, setTypeOfDialog] = useState("");

  const { user } = useUserProfile();
  const history = useHistory();
  const { originalSourceAccountId, originalSourceSystem } = useParams();
  const { authState } = useOktaAuth();

  const { validCountryStateCheck } = useValidCountriesStateLookup();

  const handleBack = () => {
    history.push(ExternalAccountMappingPath, {
      searchedAcc: history?.location?.state?.searchedAcc,
    });
  };

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
    setPageLoading(false);
    // handleBack();
  };

  useEffect(() => {
    if (history.location.state?.originalSourceAccount) {
      setOriginalSourceAccount(history.location.state?.originalSourceAccount);
      setModifiedSyncSet(history.location.state?.syncSet);
      setCurrentSyncSet(history.location.state?.syncSet);
      setSubscriberSourceSystems(
        history.location.state?.subscriberSourceSystems
      );
    } else {
      setPageLoading(true);
      eamApi
        .fetchExternalAccountMappings(
          `/api/eam/mcsmappings/osa/${originalSourceAccountId}`,
          { sourceSystem: originalSourceSystem },
          authState.accessToken
        )
        .then((data) => {
          setOriginalSourceAccount(data?.find((acc) => acc?.syncSource));
          setModifiedSyncSet(data);
          setCurrentSyncSet(data);
          setPageLoading(false);
        })
        .catch((e) => handleError(e));
    }
  }, []);

  useEffect(() => {
    if (user?.userId) {
      setCompleteSyncSetPayload((prev) => ({
        ...prev,
        requested_by: user?.userId,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (originalSourceAccount) {
      setCompleteSyncSetPayload((prev) => ({
        ...prev,
        orig_source_system_account_id: originalSourceAccount?.account_id,
        orig_source_system_name: originalSourceAccount?.source_system,
      }));
      if (!history.location.state?.subscriberSourceSystems) {
        setPageLoading(true);
        crossSystemAccountApi
          .fetchMissingSourceSystems(
            originalSourceAccount?.source_system,
            authState.accessToken
          )
          .then((data) => {
            setSubscriberSourceSystems(data);
            setPageLoading(false);
          })
          .catch((e) => handleError(e));
      }
    }
  }, [originalSourceAccount]);

  useEffect(() => {
    if (subscriberSourceSystems.length > 0) {
      subscriberSourceSystems?.forEach((sourceSystem) => {
        setModifiedSyncSet((prev) => {
          if (prev.some((prevItem) => prevItem?.source_system === sourceSystem))
            return prev;

          return [
            ...prev,
            {
              ...originalSourceAccount,
              source_system: sourceSystem,
              account_id: "new",
              id: `new:${sourceSystem}`,
              syncSource: false,
              isNewAccount: true,
            },
          ];
        });
      });
    }
  }, [subscriberSourceSystems]);

  const setSidePaneDetails = (data, title, labels) => {
    setSidePaneLabels(labels);
    setSidePaneData(data);
    setSidePaneTitle(title);
    setIsSidePaneOpen(!isSidePaneOpen);
  };

  const handleSuccess = (message, data) => {
    setModifiedSyncSet(data?.mcs_synced_accounts);
    setPageLoading(false);
    success(message, {
      timeout: 5000,
    });
    setActiveStep(activeStep + 1);
  };

  const handleNext = () => {
    setPageLoading(true);
    if (typeOfDialog === "end-map") {
      eamApi
        .endMap(completeSyncSetPayload, authState.accessToken)
        .then((data) =>
          handleSuccess("End map has been performed successfully!", data)
        )
        .catch((e) => handleError(e));
    } else {
      eamApi
        .completeSyncSet(completeSyncSetPayload, authState.accessToken)
        .then((data) =>
          handleSuccess(
            "Complete sync set has been performed successfully!",
            data
          )
        )
        .catch((e) => handleError(e));
    }
  };

  const handleCompleteSync = () => {
    const newAccount = modifiedSyncSet?.find(
      (acc) => acc?.account_id === "new"
    );
    let syncOrigSourceData = null;
    if (
      originalSourceAccount?.address[0]?.country?.length !== 2 ||
      (validCountryStateCheck.countries.includes(
        originalSourceAccount?.address[0]?.country
      ) &&
        !validCountryStateCheck.countryStateMap[
          originalSourceAccount?.address[0]?.country
        ].includes(originalSourceAccount?.address[0]?.state))
    ) {
      syncOrigSourceData = {
        address: {
          country: newAccount?.address[0]?.country,
          state: newAccount?.address[0]?.state,
        },
      };
    }
    setCompleteSyncSetPayload((prev) => ({
      ...prev,
      orig_source_data: syncOrigSourceData,
      sync_accounts: modifiedSyncSet
        .filter((acc) => acc?.isNewAccount)
        .map((acc) => ({
          crm_sourcesystem_name: acc?.source_system,
        })),
    }));
    setTypeOfDialog("complete-sync");
  };

  const handleEndMap = () => {
    setCurrentSyncSet((prev) =>
      prev?.map((prevItem) => {
        if ([...selectedKeys.values()].includes(prevItem?.id))
          return { ...prevItem, status: "removed" };
        return prevItem;
      })
    );
    setCompleteSyncSetPayload((prev) => ({
      ...prev,
      sync_accounts: modifiedSyncSet
        .filter((acc) => [...selectedKeys?.values()]?.includes(acc?.id))
        .map((acc) => ({
          crm_account_id: acc?.account_id,
          crm_sourcesystem_name: acc?.source_system,
        })),
    }));
    setTypeOfDialog("end-map");
  };

  useEffect(() => {
    if (selectedKeys === "all") {
      const origAndNewAccount = modifiedSyncSet
        ?.filter(
          (acc) => acc?.id !== originalSourceAccount?.id && !acc?.isNewAccount
        )
        .map((acc) => acc?.id);
      if (origAndNewAccount && origAndNewAccount.length > 0) {
        setSelectedKeys(new Set([...origAndNewAccount]));
      }
    }
  }, [selectedKeys]);

  useEffect(() => {
    const newAccount = modifiedSyncSet?.find(
      (acc) => acc?.account_id === "new"
    );
    if (
      newAccount &&
      (newAccount?.address[0]?.country?.length !== 2 ||
        (validCountryStateCheck.countries.includes(
          newAccount?.address[0]?.country
        ) &&
          !validCountryStateCheck.countryStateMap[
            newAccount?.address[0]?.country
          ].includes(newAccount?.address[0]?.state)))
    )
      setTypeOfDialog("pickStateCountry");
  }, [modifiedSyncSet]);

  const handleDialogNext = (state, country) => {
    setModifiedSyncSet((prev) =>
      prev.map((prevItem) => {
        if (prevItem?.account_id === "new") {
          return {
            ...prevItem,
            address: [
              {
                ...prevItem?.address[0],
                state: state || prevItem?.address[0]?.state,
                country: country || prevItem?.address[0]?.country,
              },
            ],
          };
        }
        return prevItem;
      })
    );
  };

  return (
    <Flex direction="row" gap="size-125">
      <LoadingDialog isOpen={isPageLoading} />
      <Flex direction="column" gap="size-125" margin="size-300" width="70%">
        <Flex gap="size-125" justifyContent="space-between">
          <Heading>Complete sync set</Heading>
          <Badge
            variant="seafoam"
            UNSAFE_style={{ fontSize: "small" }}
          >{`CAM Id: ${originalSourceAccount?.cam_id}`}</Badge>
        </Flex>
        <View data-multi-step UNSAFE_className="wrapper">
          <Step1
            activeStep={activeStep}
            originalSourceAccount={originalSourceAccount}
            selectedKeys={selectedKeys}
            syncSet={modifiedSyncSet}
            setSelectedKeys={setSelectedKeys}
            setSidePaneDetails={setSidePaneDetails}
          />
          <Step2 activeStep={activeStep} newMapping={modifiedSyncSet} />
        </View>

        <Flex marginTop="size-300" justifyContent="space-between">
          <Flex>
            <ActionButton isQuiet onPress={handleBack}>
              <ChevronLeft color="informative" />
              <Text>External account mapping</Text>
            </ActionButton>
          </Flex>
          {activeStep < 2 && (
            <Flex gap="size-125">
              <Button
                variant="accent"
                onPress={handleEndMap}
                isDisabled={selectedKeys?.size < 1 || selectedKeys === "all"}
              >
                End map
              </Button>
              <Button variant="accent" onPress={handleCompleteSync}>
                Complete sync
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
      <DialogContainer onDismiss={() => setTypeOfDialog("")}>
        {typeOfDialog === "pickStateCountry" && (
          <PickStateCountryDialog
            handleNext={(state, country) => {
              handleDialogNext(state, country);
            }}
            handleBack={handleBack}
          />
        )}
        {(typeOfDialog === "complete-sync" || typeOfDialog === "end-map") && (
          <ConfirmCompleteSyncDialog
            currentMapping={currentSyncSet}
            newMapping={
              typeOfDialog === "end-map"
                ? currentSyncSet.filter(
                    (acc) => ![...selectedKeys.values()].includes(acc?.id)
                  )
                : modifiedSyncSet
            }
            type={typeOfDialog === "end-map" ? "end map" : "complete sync"}
            handleNext={handleNext}
          />
        )}
      </DialogContainer>
      {isSidePaneOpen && (
        <CamSlidingPane
          isPaneOpenFlag={isSidePaneOpen}
          handleOpenDialog={() => setIsSidePaneOpen(false)}
          paneTitle={sidePaneTitle}
          Component={
            <AccountMappingForm data={sidePaneData} labels={sidePaneLabels} />
          }
        />
      )}
    </Flex>
  );
}

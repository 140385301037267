import React, { useEffect, useState } from "react";
import { lightTheme, Provider as ProviderV3 } from "@adobe/react-spectrum";
import Provider from "@react/react-spectrum/Provider";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import WebFont from "webfontloader";
import Main from "./Main";

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    WebFont.load({
      typekit: {
        id: "aoj1mew",
      },
      active: () => setIsLoaded(true),
    });
  }, []);

  return (
    isLoaded && (
      <ProviderV3 theme={lightTheme} height="100vh" colorScheme="light">
        <Provider theme="light" typekitId="aoj1mew" style={{ height: "100%" }}>
          <Router>
            <Main />
          </Router>
        </Provider>
      </ProviderV3>
    )
  );
}

export default App;

import { Flex, ProgressCircle } from "@adobe/react-spectrum";
import React from "react";
import PropTypes from "prop-types";

export default function LoadingDialog({ isOpen }) {
  return (
    isOpen && (
      <Flex UNSAFE_className="modal-minus-header">
        <Flex justifyContent="center" alignItems="center" width="100%">
          <ProgressCircle aria-label="Loading…" isIndeterminate />
        </Flex>
      </Flex>
    )
  );
}

LoadingDialog.defaultProps = {
  isOpen: false,
};

LoadingDialog.propTypes = {
  isOpen: PropTypes.bool,
};

import {
  ActionButton,
  Badge,
  Button,
  DialogContainer,
  Flex,
  Heading,
  Text,
  View,
} from "@adobe/react-spectrum";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StepCard from "../../../../components/Common/Card/StepCard/StepCard";
import Stepper from "../../../../components/Common/Stepper/Stepper";
import ConfirmFlipMappingDialog from "../../../../components/Dialog/ConfirmFlipMappingDialog";
import LoadingDialog from "../../../../components/Dialog/LoadingDialog";
import PickOrigSourceDialog from "../../../../components/Dialog/PickOrigSourceDialog";
import { ExternalAccountMappingPath } from "../../../../constants/Routes";
import { RemapExternalAccountSteps } from "../../../../constants/StepperItems";
import "./remap-external-account.css";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";

export default function RemapExternalAccount() {
  const [currentMapping, setCurrentMapping] = useState();
  const [newMapping, setNewMapping] = useState();
  const [originalSourceAccount, setOriginalSourceAccount] = useState();
  const [activeStep, setActiveStep] = useState(1);
  const [moveInAccount, setMoveInAccount] = useState();
  const [typeOfDialog, setTypeOfDialog] = useState();
  const [isPageLoading, setPageLoading] = useState(false);
  const history = useHistory();

  const STEP_ITEMS = RemapExternalAccountSteps();

  useEffect(() => {
    if (!history?.location?.state?.currentSyncSet)
      history.push(ExternalAccountMappingPath);
    setActiveStep(1);
    setCurrentMapping(history?.location?.state?.currentSyncSet);
    setNewMapping(history?.location?.state?.currentSyncSet);
    setOriginalSourceAccount(history?.location?.state?.originalSourceAccount);
  }, []);

  useEffect(() => {
    if (moveInAccount) {
      setCurrentMapping((prev) =>
        prev?.map((prevItem) => {
          if (prevItem?.id === history.location.state?.fromAccount?.id)
            return { ...prevItem, status: "removed" };
          return prevItem;
        })
      );
      setNewMapping(
        history?.location?.state?.currentSyncSet?.map((prevItem) => {
          if (prevItem?.id === history.location.state?.fromAccount?.id)
            return { ...moveInAccount, status: "added" };
          return prevItem;
        })
      );
    }
  }, [moveInAccount]);

  const handlePrevStep = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleNextStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    history.push(ExternalAccountMappingPath, {
      searchedAcc: history?.location?.state?.searchedAcc,
    });
  };

  return (
    <Flex direction="row" gap="size-125">
      <Flex direction="column" gap="size-125" margin="size-300" width="70%">
        <Flex gap="size-125" justifyContent="space-between">
          <Heading>Remap external account</Heading>
          <Badge
            variant="seafoam"
            UNSAFE_style={{ fontSize: "small" }}
          >{`CAM Id: ${originalSourceAccount?.cam_id}`}</Badge>
        </Flex>
        <Flex direction="column" gap="size-125">
          <View
            backgroundColor="static-white"
            borderRadius="medium"
            borderWidth="thin"
            borderColor="gray-300"
            padding="size-125"
            UNSAFE_className="stepper-box"
          >
            <Stepper items={STEP_ITEMS} activeItemStep={activeStep} />
          </View>
          <View data-multi-step UNSAFE_className="wrapper">
            <Step1
              activeStep={activeStep}
              fromAccount={history?.location?.state?.fromAccount}
              setMoveInAccount={setMoveInAccount}
              moveInAccount={moveInAccount}
              originalSourceAccount={originalSourceAccount}
            />
            <Step2
              activeStep={activeStep}
              currentMapping={currentMapping}
              newMapping={newMapping}
            />
            <Step3 activeStep={activeStep} newMapping={newMapping} />
          </View>
          <Flex marginTop="size-300" justifyContent="space-between">
            <Flex>
              <ActionButton isQuiet onPress={handleBack}>
                <ChevronLeft color="informative" />
                <Text>External account mapping</Text>
              </ActionButton>
            </Flex>
            <Flex gap="size-125">
              {activeStep > 1 && activeStep <= STEP_ITEMS.length - 1 && (
                <Button variant="primary" onPress={handlePrevStep}>
                  <Text>Previous</Text>
                </Button>
              )}
              {activeStep <= STEP_ITEMS.length - 1 &&
                activeStep !== STEP_ITEMS.length - 1 && (
                  <Button
                    variant="accent"
                    isDisabled={moveInAccount === undefined}
                    onPress={() => {
                      if (history?.location?.state?.fromAccount?.syncSource) {
                        setTypeOfDialog("origAccountFlipped");
                      } else {
                        handleNextStep();
                      }
                    }}
                  >
                    <Text>Next</Text>
                  </Button>
                )}

              {activeStep === STEP_ITEMS.length - 1 && (
                <Button
                  variant="accent"
                  onPress={() => setTypeOfDialog("confirmMapping")}
                >
                  Submit
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex direction="column" gap="size-125" marginTop="size-300" width="20%">
        <Flex gap="size-125" direction="column">
          <View>
            <Heading>Move-out account</Heading>
          </View>
          <View
            height="fit-content"
            UNSAFE_className="remap-card-item stepper-box"
            borderRadius="medium"
            borderWidth="thin"
            borderColor="gray-300"
            padding="size-250"
          >
            <StepCard account={history.location.state?.fromAccount} />
          </View>
        </Flex>

        {moveInAccount && (
          <Flex gap="size-125" direction="column">
            <View>
              <Heading>Move-in account</Heading>
            </View>
            <View
              height="fit-content"
              UNSAFE_className="remap-card-item stepper-box"
              borderRadius="medium"
              borderWidth="thin"
              borderColor="gray-300"
              padding="size-250"
            >
              <StepCard account={moveInAccount} />
            </View>
          </Flex>
        )}
      </Flex>
      <LoadingDialog isOpen={isPageLoading} />
      <DialogContainer onDismiss={() => setTypeOfDialog("")}>
        {typeOfDialog === "confirmMapping" && (
          <ConfirmFlipMappingDialog
            fromAccount={history.location.state?.fromAccount}
            toAccount={moveInAccount}
            handleNextStep={handleNextStep}
            originalSourceAccount={originalSourceAccount}
            newMapping={newMapping}
            setPageLoading={setPageLoading}
            setNewMapping={setNewMapping}
          />
        )}
        {typeOfDialog === "origAccountFlipped" && (
          <PickOrigSourceDialog
            currentMapping={newMapping}
            handleNextStep={(syncSet) => {
              setNewMapping(syncSet);
              handleNextStep();
            }}
          />
        )}
      </DialogContainer>
    </Flex>
  );
}

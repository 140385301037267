import axios from "../config/axios-config";
/* eslint-disable import/prefer-default-export */

let controller;
const updateGroup = async (groupName, data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.put(`/api/groups/${groupName}`, data);
  const responseData = await response.data;
  return responseData;
};

const createGroupRequest = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post("/api/grouprequest", data);
  const responseData = await response.data;
  return responseData;
};

const approvalGroupRequest = async (requestId, data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.put(`/api/grouprequest/${requestId}`, data);
  const responseData = await response.data;
  return responseData;
};

const fetchGroups = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get("/api/groups");
  const responseData = await response.data;
  return responseData;
};

const fetchUserOwnedGroups = async (userId, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(`/api/groups/owned/${userId}`);
  const responseData = await response.data;
  return responseData;
};

const fetchUsers = async (data, token) => {
  if (controller) controller.abort();
  controller = new AbortController();
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(`/api/users/search/${data}`, {
    signal: controller.signal,
  });
  const responseData = await response.data;
  return responseData;
};

const fetchGroupRequestsByStatus = async (
  groupRequestType,
  status,
  userId,
  token
) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(
    `/api/grouprequest/${groupRequestType}/${status}/${userId}`
  );
  const responseData = await response.data;
  return responseData;
};

const fetchUserInfo = async (userId, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(`/api/users/fetch/${userId}`);
  const responseData = await response.data;
  return responseData;
};

export const groupsApi = {
  createGroupRequest,
  fetchGroups,
  fetchUserOwnedGroups,
  updateGroup,
  fetchUsers,
  approvalGroupRequest,
  fetchGroupRequestsByStatus,
  fetchUserInfo,
};

import React from "react";
import { useHistory, withRouter } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import CustomFooter from "./components/Footer/CustomFooter";
import Header from "./components/Header";
import BodyContainer from "./pages/BodyContainer";
import config from "./config/okta-config";
import { BreadcrumbsProvider } from "./context/breadcrumbs-context";
import { UserProvider } from "./context/user-context";
import { IsocodeProvider } from "./context/isocode-context";

function Main() {
  const history = useHistory();
  const oktaAuth = new OktaAuth(config.oidc);
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <UserProvider>
        <BreadcrumbsProvider>
          <IsocodeProvider>
            <div className="main-container">
              <Header />
              <BodyContainer />
              <CustomFooter />
            </div>
          </IsocodeProvider>
        </BreadcrumbsProvider>
      </UserProvider>
    </Security>
  );
}

export default withRouter(Main);

import { Content, Heading, View, Well } from "@adobe/react-spectrum";
import React from "react";
import PropTypes from "prop-types";
import "./card.css";

export default function Card({ title, content, handleOnPress }) {
  return (
    <button type="button" className="custom-button" onClick={handleOnPress}>
      <Well
        role="region"
        aria-label={title}
        UNSAFE_className="card-body stepper-box"
      >
        <View>
          <Heading UNSAFE_style={{ padding: "1rem" }}>{title}</Heading>
          <Content UNSAFE_style={{ padding: "0 1rem" }}>{content}</Content>
        </View>
      </Well>
    </button>
  );
}

Card.defaultProps = {
  title: "",
  content: "",
  handleOnPress: undefined,
};
Card.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleOnPress: PropTypes.func,
};

import { ActionButton, Flex, View } from "@adobe/react-spectrum";
import ChevronDown from "@spectrum-icons/workflow/ChevronDown";
import ChevronUp from "@spectrum-icons/workflow/ChevronUp";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import "./collapsible.css";

export default function Collapsible({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const parentRef = useRef(null);

  return (
    <View>
      <Flex justifyContent="end">
        <ActionButton onPress={() => setIsOpen(!isOpen)}>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </ActionButton>
      </Flex>
      <View
        UNSAFE_className="parent-content"
        ref={parentRef}
        UNSAFE_style={
          isOpen
            ? {
                height: `${
                  parentRef?.current?.UNSAFE_getDOMNode()?.scrollHeight
                }px`,
              }
            : { height: "0px" }
        }
      >
        <View>{children}</View>
      </View>
    </View>
  );
}
Collapsible.defaultProps = {
  children: [],
};
Collapsible.propTypes = {
  children: PropTypes.element,
};

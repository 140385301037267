import axios from "../config/axios-config";
/* eslint-disable import/prefer-default-export */

const uploadBulkRegistrationFile = async (selectedFile, token, userId) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const formData = new FormData();
  formData.append("file", selectedFile);
  formData.append("requested_by", userId);

  const response = await axios.post("/bulk/registration/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const responseData = await response.data;
  return responseData;
};

const fetchBulkUploadDetails = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(`/api/bulk/uploadstatus`);
  const responseData = await response.data;
  return responseData;
};

export const selfServeApi = {
  uploadBulkRegistrationFile,
  fetchBulkUploadDetails,
};

import { Button, Flex, SearchField, Text, View } from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import PeopleGroup from "@spectrum-icons/workflow/PeopleGroup";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { error } from "@react/react-spectrum/Toast";
import { groupsApi } from "../../api/groupsApi";
import { CreateGroupsPath } from "../../constants/Routes";
import useUserProfile from "../../context/user-context";
import GroupsList from "../../components/GroupsList/GroupsList";
import { GroupListColumn } from "../../constants/Columns";

export default function CreateAndSubscribeGroups() {
  const [filters, setFilters] = useState({ name: "" });
  const [groups, setGroups] = useState([]);
  const columns = GroupListColumn();

  const history = useHistory();

  const { user } = useUserProfile();

  const { authState } = useOktaAuth();

  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    if (user != null) {
      groupsApi
        .fetchGroups(authState.accessToken)
        .then((data) => {
          setGroups(data);
          setLoadingState("");
        })
        .catch((e) => {
          setLoadingState("");
          if (e.toString().toLowerCase().includes("network"))
            error(`Server not reachable! Please contact CAM team!`, {
              timeout: 5000,
            });
          else if (e?.response?.data) {
            error(e?.response?.data?.message, {
              timeout: 5000,
            });
          } else {
            error(`Sorry something went wrong! Please contact CAM team!`, {
              timeout: 5000,
            });
          }
        });
    }
  }, [user]);

  return (
    <View>
      <Flex
        margin="size-300"
        gap="size-150"
        wrap
        width="70%"
        justifyContent="space-between"
      >
        <View>
          <SearchField
            label="Search by group name"
            onChange={(val) => {
              setFilters({ ...filters, name: val });
            }}
          />
        </View>
        <Button
          variant="accent"
          aria-label="Create Group"
          alignSelf="end"
          onPress={() => history.push(CreateGroupsPath)}
        >
          <PeopleGroup />
          <Text>Create Group</Text>
        </Button>
      </Flex>
      <GroupsList
        filters={filters}
        items={groups}
        columns={columns}
        loadingState={loadingState}
      />
    </View>
  );
}

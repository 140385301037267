import { useOktaAuth } from "@okta/okta-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useEffect, useState } from "react";
import { error } from "@react/react-spectrum/Toast";
import getEmbedToken from "../../api/dashBoardApi";
import "./dashboard.css";
import useUserProfile from "../../context/user-context";

const EMBED_URL = `https://app.powerbi.com/reportEmbed?reportId=${window.apcConfig.cam.EMBED_ID}&groupId=61d5e4fe-889e-4ee4-825e-86d21b1c9587`;

export default function Dashboard() {
  const [report, setReport] = useState();
  const [reportConfig, setReportConfig] = useState({
    type: "report",
    id: undefined,
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
  });

  const { authState } = useOktaAuth();
  const { user } = useUserProfile();

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    if (report && Object.keys(report).length > 0) {
      if (reportConfig.accessToken === undefined && user?.email) {
        getEmbedToken(authState.accessToken)
          .then((token) => {
            setReportConfig({
              ...reportConfig,
              accessToken: token,
              embedUrl: EMBED_URL,
              id: window.apcConfig.cam.EMBED_ID,
            });
            report.reload();
          })
          .catch((e) => handleError(e));
      }
    }
  }, [report, user]);

  return (
    <PowerBIEmbed
      embedConfig={reportConfig}
      cssClassName="Embed-dashboard"
      getEmbeddedComponent={(embeddedReport) => {
        setReport(embeddedReport);
      }}
    />
  );
}

import {
  ActionButton,
  Flex,
  Heading,
  Item,
  Picker,
  Text,
  View,
  TooltipTrigger,
  Tooltip,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { error } from "@react/react-spectrum/Toast";
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import CloseCircle from "@spectrum-icons/workflow/CloseCircle";
import Settings from "@spectrum-icons/workflow/Settings";
import Pending from "@spectrum-icons/workflow/Pending";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { lagApi } from "../../../../api/lagApi";
import {
  LAGSubmittedRejectedRequestColumns,
  LAGSubmittedApprovedRequestColumns,
  LAGSubmittedPendingRequestColumns,
} from "../../../../constants/Columns";
import { ManageLogicalAccountGroupingPath } from "../../../../constants/Routes";
import { PENDING, REJECTED, APPROVED } from "../../../../constants/Status";
import { LAGGroupStatusOptions } from "../../../../constants/PickerOptions";
import useUserProfile from "../../../../context/user-context";
import Table from "../../../../components/Common/Table";
import CamSlidingPane from "../../../../components/Common/CamSlidingPane/CamSlidingPane";
import CodePromotionForm from "../../../../components/Forms/LAG/CodePromotionForm";

export default function SentLAGRequests() {
  const [groupRequests, setGroupRequests] = useState([]);
  const [filters, setFilters] = useState({ status: PENDING });
  const history = useHistory();
  const [columns, setColumns] = useState(LAGSubmittedPendingRequestColumns());
  const [requestStatus, setRequestStatus] = useState(PENDING);
  const [loadingState, setLoadingState] = useState("loading");
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();
  const [highestEnv, setHighestEnv] = useState();
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();

  const handleError = (e) => {
    setLoadingState("");
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    lagApi
      .fetchCodePromotionEnvs(authState.accessToken)
      .then((data) => setHighestEnv(data[data.length - 1]))
      .catch((e) => handleError(e));
  });

  useEffect(() => {
    if (filters?.status?.length !== 0 && user?.userId) {
      lagApi
        .fetchGroupsByStatus(
          {
            status: requestStatus,
            utility_name: "LAG",
            user,
          },
          authState.accessToken
        )
        .then((data) => {
          setLoadingState("loading");
          setGroupRequests(data.groups);
          setLoadingState("");
        })
        .catch((e) => {
          handleError(e);
        });
    }
  }, [filters, user, isSidePaneOpen]);

  const renderStatus = (status) => {
    let statusIcon = (
      <CloseCircle aria-label="Rejected requests" color="negative" />
    );

    if (status === PENDING)
      statusIcon = <Pending aria-label="Pending requests" color="notice" />;
    else if (status === APPROVED)
      statusIcon = (
        <CheckmarkCircle aria-label="Approved requests" color="positive" />
      );

    return statusIcon;
  };

  const handlePromote = (row) => {
    setSelectedGroup(row);
    setIsSidePaneOpen(true);
  };

  const renderCell = (colKey, row) => {
    if (colKey === "status") {
      return renderStatus(row.status);
    }
    if (colKey === "promote" && row.environment !== highestEnv) {
      return (
        <TooltipTrigger>
          <ActionButton isQuiet onPress={() => handlePromote(row)}>
            <View>
              <Settings aria-label="Promote Rule" color="informative" />
            </View>
          </ActionButton>
          <Tooltip>View Group Details</Tooltip>
        </TooltipTrigger>
      );
    }
    if (colKey === "requested_by") {
      return <span>{row[colKey].email}</span>;
    }
    return <span>{row[colKey]}</span>;
  };

  return (
    <Flex direction="column" margin="size-300" gap="size-300">
      <View>
        <Heading level={3}>Submitted LAG Requests</Heading>
      </View>
      <View>
        <Picker
          items={LAGGroupStatusOptions()}
          label="Request status"
          selectedKey={filters?.status}
          onSelectionChange={(selected) => {
            setLoadingState("loading");
            setRequestStatus(selected);
            if (selected === REJECTED) {
              setColumns(LAGSubmittedRejectedRequestColumns());
            } else if (selected === PENDING) {
              setColumns(LAGSubmittedPendingRequestColumns());
            } else {
              setColumns(LAGSubmittedApprovedRequestColumns());
            }
            setFilters((prev) => ({ ...prev, status: selected }));
          }}
        >
          {(item) => <Item key={item.id}>{item.label}</Item>}
        </Picker>
      </View>
      <View>
        <Flex
          height="size-6000"
          width="90%"
          UNSAFE_style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}
          UNSAFE_className="stepper-box"
        >
          <Table
            columns={columns}
            rows={groupRequests}
            renderCell={renderCell}
            loadingState={loadingState}
          />
        </Flex>
      </View>
      <View>
        <ActionButton
          isQuiet
          onPress={() => {
            history.push(ManageLogicalAccountGroupingPath);
          }}
        >
          <ChevronLeft />
          <Text>LAG Requests</Text>
        </ActionButton>
      </View>
      {isSidePaneOpen && (
        <CamSlidingPane
          isPaneOpenFlag={isSidePaneOpen}
          handleOpenDialog={() => setIsSidePaneOpen(false)}
          paneTitle="Code Promotion"
          Component={
            <CodePromotionForm
              group={selectedGroup}
              setIsSidePaneOpen={setIsSidePaneOpen}
            />
          }
        />
      )}
    </Flex>
  );
}

import {
  AccountMappingPath,
  CompleteSyncSetPath,
  CreateAndSubscribeGroupsPath,
  CreateGroupsPath,
  DashboardPath,
  EditGroupsPath,
  GroupsEmailRequestApprovalPath,
  ExternalAccountMappingPath,
  GroupsRequestPath,
  ManageGroupsPath,
  ReceivedGroupRequestsPath,
  RemapExternalAccountPath,
  RootPath,
  SentGroupRequestsPath,
  SplitExternalAccountMappingsPath,
  CreateLogicalAccountGroupingPath,
  OverrideLogicalAccountGroupingPath,
  LookupLogicalAccountGroupingPath,
  ManageLogicalAccountGroupingPath,
  SentLogicalAccountGroupingPath,
  ReceivedLogicalAccountGroupingPath,
  LagEmailRequestApprovalPath,
  BulkRegistrationPath,
} from "./Routes";

const MainNavigationList = () => {
  const navigationList = [
    { id: 0, label: "Home", url: RootPath, rootMenu: "home" },
    {
      id: 1,
      label: "Cross System Accounts",
      url: AccountMappingPath,
      rootMenu: "crosssystemaccount",
    },
    {
      id: 2,
      label: "Multi-CRM Sync Dashboard",
      url: DashboardPath,
      rootMenu: "dashboard",
    },
    {
      id: 3,
      label: "External Account Management",
      url: ExternalAccountMappingPath,
      rootMenu: "eam",
    },
    {
      id: 4,
      label: "Logical Account Grouping",
      url: LookupLogicalAccountGroupingPath,
      rootMenu: "lag",
    },
    {
      id: 5,
      label: "Groups",
      url: GroupsRequestPath,
      rootMenu: "groups",
    },
    {
      id: 6,
      label: "Self Serve",
      url: BulkRegistrationPath,
      rootMenu: "selfserve",
    },
  ];

  return navigationList;
};

export default MainNavigationList;

export const BreadcrumbsRoutes = () => {
  const mainNavList = MainNavigationList();
  const routes = [
    ...mainNavList,
    {
      id: 7,
      label: "Group Requests",
      url: GroupsRequestPath,
      rootMenu: "groups",
      sideNav: true,
    },
    {
      id: 8,
      label: "Sent Group Requests",
      url: SentGroupRequestsPath,
      rootMenu: "groups",
    },
    {
      id: 9,
      label: "Received Group Requests",
      url: ReceivedGroupRequestsPath,
      rootMenu: "groups",
    },
    {
      id: 10,
      label: "Create and Subscribe Groups",
      url: CreateAndSubscribeGroupsPath,
      rootMenu: "groups",
      sideNav: true,
    },
    {
      id: 11,
      label: "Manage Groups",
      url: ManageGroupsPath,
      rootMenu: "groups",
      sideNav: true,
    },
    {
      id: 12,
      label: "Create Group",
      url: CreateGroupsPath,
      rootMenu: "groups",
    },
    {
      id: 13,
      label: "Edit Group",
      url: EditGroupsPath,
      rootMenu: "groups",
    },
    {
      id: 14,
      label: "Email request Approval",
      url: GroupsEmailRequestApprovalPath,
      rootMenu: "groups",
    },
    {
      id: 15,
      label: "External Account Mapping",
      url: ExternalAccountMappingPath,
      rootMenu: "eam",
      sideNav: true,
    },
    {
      id: 16,
      label: "RemapFlip Accounts",
      url: RemapExternalAccountPath,
      rootMenu: "eam",
    },
    {
      id: 17,
      label: "RemapSplit Accounts",
      url: SplitExternalAccountMappingsPath,
      rootMenu: "eam",
    },
    {
      id: 18,
      label: "Account mapping",
      url: AccountMappingPath,
      rootMenu: "crosssystemaccount",
      sideNav: true,
    },
    {
      id: 19,
      label: "Complete sync",
      url: CompleteSyncSetPath,
      rootMenu: "eam",
    },
    {
      id: 20,
      label: "LAG",
      url: LookupLogicalAccountGroupingPath,
      rootMenu: "lag",
      sideNav: true,
    },
    {
      id: 21,
      label: "Create LAG",
      url: CreateLogicalAccountGroupingPath,
      rootMenu: "lag",
    },
    {
      id: 22,
      label: "Override LAG",
      url: OverrideLogicalAccountGroupingPath,
      rootMenu: "lag",
    },
    {
      id: 23,
      label: "LAG Requests",
      url: ManageLogicalAccountGroupingPath,
      rootMenu: "lag",
      sideNav: true,
    },
    {
      id: 24,
      label: "Manage Sent LAG Requests",
      url: SentLogicalAccountGroupingPath,
      rootMenu: "lag",
    },
    {
      id: 25,
      label: "Manage Received LAG Requests",
      url: ReceivedLogicalAccountGroupingPath,
      rootMenu: "lag",
    },
    {
      id: 26,
      label: "Email request Approval",
      url: LagEmailRequestApprovalPath,
      rootMenu: "lag",
    },
    {
      id: 27,
      label: "Account Registration",
      url: BulkRegistrationPath,
      rootMenu: "selfserve",
      sideNav: true,
    },
  ];

  return routes;
};

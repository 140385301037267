import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  useDialogContainer,
  Text,
  AlertDialog,
  DialogContainer,
  TextArea,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { error } from "@react/react-spectrum/Toast";
import { LAGViewRule, LAGPreviewRule } from "../../constants/Columns";
import { lagApi } from "../../api/lagApi";
import Table from "../Common/Table";
import LoadingDialog from "./LoadingDialog";

export default function LAGDetailsDialog({
  groupKey,
  isReadOnly,
  handleApprove,
  handleReject,
  rejectionReason,
  setRejectionReason,
  isReject,
}) {
  const dialog = useDialogContainer();
  const viewRuleColumns = LAGViewRule();
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const constantPreviewRuleColumnUids = LAGPreviewRule().map((x) => x.uid);
  const [ruleInfo, setRuleInfo] = useState([]);
  const [previewRuleData, setPreviewRuleData] = useState();
  const [rejectionDialog, setRejectionDialog] = useState(false);
  const { authState } = useOktaAuth();
  const [isPageLoading, setPageLoading] = useState(false);
  const [loadingState, setLoadingState] = useState("");

  useEffect(() => {
    setRejectionDialog(isReject);
  }, []);

  const renderPreviewDataCell = (colKey, row) => {
    if (!constantPreviewRuleColumnUids.includes(colKey)) {
      return <Text>{row.attributes[colKey].toString()}</Text>;
    }
    return <Text>{row[colKey]}</Text>;
  };

  const handleError = (e) => {
    setPageLoading(false);
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    // group attribute info
    lagApi
      .fetchGroupInfo({ group_key: groupKey }, authState.accessToken)
      .then((data) => {
        setRuleInfo(data.attributes);
      })
      .catch((e) => handleError(e));
  }, []);

  useEffect(() => {
    // preview data info
    if (ruleInfo.length > 0) {
      lagApi
        .fetchPreviewGroupData(
          {
            attributes: ruleInfo,
            result_size: 100,
            from: 0,
          },
          authState.accessToken
        )
        .then((data) => {
          setLoadingState("loading");
          setPreviewRuleData(data.results);
          if (data.results.length > 0) {
            const [previewDataRecord] = data.results;
            const updatedDynamicColumns = [];
            Object.keys(previewDataRecord.attributes).forEach((attr) => {
              updatedDynamicColumns.push({
                uid: attr,
                name:
                  attr.charAt(0).toUpperCase() +
                  attr.slice(1).replace("_", " "),
              });
            });
            setDynamicColumns(updatedDynamicColumns);
          }
          setLoadingState("");
        })
        .catch((e) => handleError(e));
    }
  }, [ruleInfo]);

  return (
    <Dialog size="L" width="70rem">
      <LoadingDialog isOpen={isPageLoading} />
      <Heading>{groupKey} Details</Heading>
      <Divider />
      <Content zIndex={1}>
        <Flex direction="column">
          <Flex
            direction="row"
            gap="size-125"
            marginBottom="size-300"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={viewRuleColumns}
              rows={ruleInfo}
              selectionMode="none"
              renderCell={(colKey, row) => <span>{row[colKey]}</span>}
              density="compact"
            />
          </Flex>

          <Heading level={4}>Preview Rule (first 100 records)</Heading>
          <Flex
            direction="row"
            marginTop={0}
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
            height={ruleInfo.length > 0 ? "fit-content" : "static-size-4600"}
          >
            <Table
              columns={[...LAGPreviewRule(), ...dynamicColumns].filter(
                (v, i, a) => a.findIndex((v2) => v2.uid === v.uid) === i
              )}
              rows={previewRuleData}
              selectionMode="none"
              renderCell={renderPreviewDataCell}
              loadingState={loadingState}
              density="compact"
            />
          </Flex>
        </Flex>
      </Content>
      <ButtonGroup>
        {!isReadOnly && (
          <>
            <Button
              variant="secondary"
              UNSAFE_style={{ color: "green" }}
              onPress={() => {
                handleApprove(groupKey);
                dialog.dismiss();
              }}
            >
              Approve
            </Button>
            <Button
              variant="secondary"
              UNSAFE_style={{ color: "red" }}
              onPress={() => setRejectionDialog(true)}
            >
              Reject
            </Button>
          </>
        )}
        <Button variant="secondary" onPress={dialog.dismiss}>
          Close
        </Button>
      </ButtonGroup>
      <DialogContainer>
        {rejectionDialog && (
          <AlertDialog
            title="Reject request"
            variant="destructive"
            primaryActionLabel="Yes"
            cancelLabel="No"
            onPrimaryAction={() => {
              handleReject(groupKey);
              dialog.dismiss();
            }}
            onCancel={() => setRejectionDialog(false)}
            zIndex={2}
          >
            <Flex direction="column" gap="size-150">
              <Text>
                Are you sure you want to reject this request? Please confirm by
                providing reason for rejecting the request.
              </Text>
              <TextArea
                label="Reason"
                width="100%"
                value={rejectionReason}
                onChange={(value) => setRejectionReason(value)}
              />
            </Flex>
          </AlertDialog>
        )}
      </DialogContainer>
    </Dialog>
  );
}

LAGDetailsDialog.propTypes = {
  groupKey: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  handleApprove: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  rejectionReason: PropTypes.string.isRequired,
  setRejectionReason: PropTypes.func.isRequired,
  isReject: PropTypes.bool.isRequired,
};

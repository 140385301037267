import * as ACTIONS from "../actions/actions";

export const initialState = {
  breadcrumbsLink: [],
  isBreadcrumbsLink: false,
};

export const BreadcrumbsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.ADD_BREADCRUMBS_LINK:
      return {
        ...state,
        breadcrumbsLink: [...state.breadcrumbsLink, payload.breadcrumbsLink],
      };
    case ACTIONS.SET_IS_BREADCRUMBS_LINK:
      return {
        ...state,
        isBreadcrumbsLink: payload.isBreadcrumbsLink,
      };
    case ACTIONS.RESET_BREADCRUMBS_LINK:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

import * as ACTIONS from "../actions/actions";

export const initialState = {
  user: null,
};

export const UserReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.ADD_USER_PROFILE:
      return {
        ...state,
        user: { ...payload.user },
      };
    case ACTIONS.REMOVE_USER_PROFILE:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

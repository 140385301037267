import { Button, Flex, Link, ProgressBar, View } from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { error } from "@react/react-spectrum/Toast";
import React, { useEffect, useState } from "react";
import { FileTrigger } from "react-aria-components";
import { selfServeApi } from "../../api/selfServeApi";
import SampleFile from "../../assets/file/sample_bulk_registration.csv";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import useUserProfile from "../../context/user-context";
import { ADMIN, OPERATOR, SUPERUSER } from "../../constants/RoleType";
import useValidUserAccess from "../../hooks/useValidUserAccess";

export default function BulkRegistration() {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [uploadUserName, setUploadUserName] = useState("");
  const [uploadDateTime, setUploadDateTime] = useState("");
  const [uploadStatusMsg, setUploadStatusMsg] = useState(
    "Checking Upload Status..."
  );
  const [showUploadQueueProgress, setShowUploadQueueProgress] = useState(false);
  const [uploadQueueText, setUploadQueueText] = useState("");
  const [uploadQueuePercent, setUploadQueuePercent] = useState(0);
  const isSelfServeActionAllowed = useValidUserAccess(
    [ADMIN, SUPERUSER, OPERATOR],
    "self_serve"
  );

  const checkUploadStatus = function () {
    selfServeApi
      .fetchBulkUploadDetails(authState.accessToken)
      .then((data) => {
        if (data.disabled) {
          setIsUploadDisabled(true);
          setUploadStatusMsg("Upload Disabled by CAM Admin");
        } else {
          setIsUploadDisabled(false);
          setUploadStatusMsg("Ready for Upload");

          if (
            data.upload_details &&
            JSON.stringify(data.upload_details) !== "{}"
          ) {
            setUploadUserName(data.upload_details.requested_by);
            const uploadDateTimeObj = new Date(
              data.upload_details.created_datetime
            );
            setUploadDateTime(uploadDateTimeObj.toLocaleString());

            const totRecord = data.upload_details.total_records;
            let isInProgress = false;
            if (totRecord === 0) {
              isInProgress = true;
              setIsUploadInProgress(true);
              setUploadStatusMsg("Uploading In-Progress...");
              setTimeout(checkUploadStatus, 2000);
            }

            if (!isInProgress) {
              if (data.queue_load_details) {
                setIsUploadInProgress(true);
                setUploadStatusMsg("Pushing to Account Registration Queue...");
                const queueCount = data.queue_load_details.queue_load_count;
                setUploadQueueText(`${queueCount} of ${totRecord}`);
                setUploadQueuePercent((queueCount / totRecord) * 100);
                if (queueCount < totRecord) {
                  setShowUploadQueueProgress(true);
                  setTimeout(checkUploadStatus, 2000);
                } else {
                  setIsUploadInProgress(false);
                  setUploadStatusMsg("Ready for Upload");
                  setShowUploadQueueProgress(false);
                }
              }
            }
          } else {
            setUploadUserName("Nil");
            setUploadDateTime("Nil");
          }
        }
      })
      .catch((e) => {
        if (e.toString().toLowerCase().includes("network"))
          error(`Server not reachable! Please contact CAM team!`, {
            timeout: 5000,
          });
        else if (e?.response?.data) {
          error(e?.response?.data?.message, {
            timeout: 5000,
          });
        } else {
          error(`Sorry something went wrong! Please contact CAM team!`, {
            timeout: 5000,
          });
        }
      });
  };

  useEffect(() => {
    setIsPageLoading(false);
    if (isSelfServeActionAllowed) checkUploadStatus();
  }, [isSelfServeActionAllowed]);

  const uploadData = function (file) {
    setIsPageLoading(true);
    setIsUploadInProgress(true);
    setUploadStatusMsg("Uploading In-Progress...");
    selfServeApi
      .uploadBulkRegistrationFile(file, authState.accessToken, user?.userId)
      .then((data) => {
        setIsPageLoading(false);
        if (data.successful === true) {
          checkUploadStatus();
        } else {
          error(data.message, {
            timeout: 5000,
          });
          setIsUploadInProgress(false);
          setUploadStatusMsg("Ready for Upload");
        }
      })
      .catch((e) => {
        setIsPageLoading(false);
        if (e.toString().toLowerCase().includes("network"))
          error(`Server not reachable! Please contact CAM team!`, {
            timeout: 5000,
          });
        else if (e?.response?.data) {
          error(e?.response?.data?.message, {
            timeout: 5000,
          });
        } else {
          error(`Sorry something went wrong! Please contact CAM team!`, {
            timeout: 5000,
          });
        }
      });
  };

  return (
    <>
      <Flex
        margin="size-500"
        gap="size-150"
        width="70%"
        justifyContent="space-between"
        direction="column"
      >
        <LoadingDialog isOpen={isPageLoading} />
        {!isSelfServeActionAllowed && (
          <View>You are not authorized to access this feature</View>
        )}

        {isSelfServeActionAllowed && (
          <>
            <View>
              This module enables Bulk CRM Account (Definitive/Normal)
              Registration by uploading a CSV File.
            </View>
            <View marginTop="size-100">
              <FileTrigger
                acceptedFileTypes={["text/csv"]}
                onSelect={(e) => {
                  const files = Array.from(e);
                  uploadData(files[0]);
                }}
              >
                <Button
                  UNSAFE_style={{ marginRight: "10px" }}
                  variant="accent"
                  isDisabled={isUploadDisabled || isUploadInProgress}
                >
                  Upload Now
                </Button>
              </FileTrigger>

              <Link href={SampleFile} target="_blank">
                <Button
                  UNSAFE_style={{ marginRight: "10px" }}
                  variant="secondary"
                >
                  Sample File
                </Button>
              </Link>
            </View>

            <View marginTop="size-300">
              {isUploadInProgress ? (
                <>Active Upload by: {uploadUserName}</>
              ) : (
                <>Last Uploaded by: {uploadUserName}</>
              )}
            </View>
            <View>
              {isUploadInProgress ? (
                <>Upload Started at : {uploadDateTime}</>
              ) : (
                <>Last Uploaded Datetime: {uploadDateTime}</>
              )}
            </View>

            <View>
              Status: <b>{uploadStatusMsg}</b>
            </View>

            <View marginTop="size-300">
              {showUploadQueueProgress && (
                <ProgressBar
                  label="Pushing..."
                  valueLabel={uploadQueueText}
                  value={uploadQueuePercent}
                />
              )}
            </View>
          </>
        )}
      </Flex>
    </>
  );
}

import {
  Cell,
  Column,
  Content,
  Heading,
  IllustratedMessage,
  Row,
  TableBody,
  TableHeader,
  TableView,
} from "@adobe/react-spectrum";
import NotFound from "@spectrum-icons/illustrations/NotFound";
import PropTypes from "prop-types";
import React from "react";

export default function Table({
  columns,
  rows,
  renderCell,
  overflowMode,
  loadingState,
  selectionMode,
  onSelectionChange,
  selectedKeys,
  density,
  disallowEmptySelection = false,
  disabledKeys,
  onAction,
  onLoadMore,
}) {
  function renderEmptyState() {
    return (
      <IllustratedMessage>
        <NotFound />
        <Heading>No results</Heading>
        <Content>No results found</Content>
      </IllustratedMessage>
    );
  }
  return (
    <TableView
      flex
      aria-label="CAM table with dynamic content"
      marginTop="1rem"
      renderEmptyState={() => renderEmptyState()}
      overflowMode={overflowMode || "wrap"}
      selectionMode={selectionMode || "none"}
      onSelectionChange={onSelectionChange}
      selectedKeys={selectedKeys}
      density={density}
      disallowEmptySelection={disallowEmptySelection}
      disabledKeys={disabledKeys}
      onAction={onAction}
    >
      <TableHeader columns={columns}>
        {(column) => (
          <Column
            key={column.uid}
            minWidth={column?.minWidth}
            maxWidth={column?.maxWidth}
            allowsResizing
          >
            {column.name}
          </Column>
        )}
      </TableHeader>
      <TableBody
        items={rows}
        loadingState={loadingState}
        onLoadMore={onLoadMore}
      >
        {(item) => (
          <Row
            key={
              item.id || item.requestId || item.camid || item.relationship_id
            }
          >
            {(columnKey) => <Cell>{renderCell(columnKey, item)}</Cell>}
          </Row>
        )}
      </TableBody>
    </TableView>
  );
}

Table.defaultProps = {
  rows: [],
  overflowMode: "",
  loadingState: "",
  selectionMode: "",
  onSelectionChange: null,
  density: "",
  disallowEmptySelection: null,
  selectedKeys: [],
  disabledKeys: [],
  onAction: null,
  onLoadMore: null,
};
Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
      ])
    )
  ),
  columns: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    )
  ).isRequired,
  renderCell: PropTypes.func.isRequired,
  overflowMode: PropTypes.string,
  loadingState: PropTypes.string,
  selectionMode: PropTypes.string,
  onSelectionChange: PropTypes.func,
  density: PropTypes.string,
  disallowEmptySelection: PropTypes.bool,
  selectedKeys: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string]))
    ),
    PropTypes.any,
  ]),
  disabledKeys: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string]))
    ),
    PropTypes.any,
  ]),
  onAction: PropTypes.func,
  onLoadMore: PropTypes.func,
};

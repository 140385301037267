import axios from "../config/axios-config";
/* eslint-disable import/prefer-default-export */

const fetchExternalAccountMappings = async (uri, requestParams, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(uri, { params: requestParams });
  const responseData = await response.data;
  return responseData;
};

const remapExternalAccount = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/eam/remap`, data);
  const responseData = await response.data;
  return responseData;
};

const splitExternalAccountMapping = async (payload, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/eam/split`, payload);
  const responseData = await response.data;
  return responseData;
};

const completeSyncSet = async (payload, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/eam/sync`, payload);
  const responseData = await response.data;
  return responseData;
};

const endMap = async (payload, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/eam/endmap`, payload);
  const responseData = await response.data;
  return responseData;
};

const fetchISOCodes = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get("/api/eam/lookup/isocodes");
  const responseData = await response.data;
  return responseData;
};

const fetchValidCountriesForStateCodeValidation = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get("/api/eam/lookup/isocode/validcountries");
  const responseData = await response.data;
  return responseData;
};

const fetchSourceSystems = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get("/api/crosssystemaccounts/sourcesystems");
  const responseData = await response.data;
  return responseData;
};

export const eamApi = {
  fetchExternalAccountMappings,
  remapExternalAccount,
  splitExternalAccountMapping,
  completeSyncSet,
  endMap,
  fetchISOCodes,
  fetchValidCountriesForStateCodeValidation,
  fetchSourceSystems,
};

import React, { useState, useEffect } from "react";
import {
  Form,
  TextField,
  DatePicker,
  View,
  Button,
  Flex,
  LabeledValue,
  Picker,
  Item,
} from "@adobe/react-spectrum";
import { getLocalTimeZone, today } from "@internationalized/date";
import { useOktaAuth } from "@okta/okta-react";
import { error, success } from "@react/react-spectrum/Toast";
import PropTypes from "prop-types";
import { lagApi } from "../../../api/lagApi";
import useUserProfile from "../../../context/user-context";
import LoadingDialog from "../../Dialog/LoadingDialog";

export default function CodePromotionForm({ group, setIsSidePaneOpen }) {
  const { user } = useUserProfile();
  const [promotionRequest, setPromotionRequest] = useState({
    group_key: group.group_key,
    user,
    next_promotion_environment: null,
    change_management_request: null,
    effective_date: null,
  });
  const [envOptions, setEnvOptions] = useState([]);
  const [isPageLoading, setPageLoading] = useState(false);
  const { authState } = useOktaAuth();

  useEffect(() => {
    lagApi.fetchCodePromotionEnvs(authState.accessToken).then((data) => {
      const availEnvs = data.slice(
        data.findIndex((env) => env === group.environment) + 1,
        data.size
      );
      setEnvOptions(availEnvs.map((env) => ({ name: env, id: env })));
    });
  }, []);

  const handleSubmit = () => {
    setPageLoading(true);
    lagApi
      .promoteGroup(promotionRequest, authState.accessToken)
      .then((data) => {
        if (data?.success) {
          success(`Group has been promoted successfully!`, { timeout: 5000 });
          setPageLoading(false);
        } else {
          error(`Error with code promotion, please try again!`, {
            timeout: 5000,
          });
          setPageLoading(false);
        }
        setIsSidePaneOpen(false);
      })
      .catch((e) => {
        setPageLoading(false);
        if (e.toString().toLowerCase().includes("network"))
          error(`Server not reachable! Please contact CAM team!`, {
            timeout: 5000,
          });
        else if (e?.response?.data) {
          error(e?.response?.data?.message, {
            timeout: 5000,
          });
        } else {
          error(`Sorry something went wrong! Please contact CAM team!`, {
            timeout: 5000,
          });
        }
      });
  };

  return (
    <Form UNSAFE_style={{ backgroundColor: "#F5F5F5" }}>
      <LoadingDialog isOpen={isPageLoading} />
      <Flex direction="column" gap="size-500">
        <LabeledValue
          label="Current Environment:"
          labelPosition="side"
          value={group.environment}
        />
        <Picker
          label="Promote to Environment"
          width="250px"
          items={envOptions}
          onSelectionChange={(value) =>
            setPromotionRequest({
              ...promotionRequest,
              next_promotion_environment: value,
            })
          }
        >
          {(item) => <Item key={item.id}>{item.name}</Item>}
        </Picker>
        {promotionRequest.next_promotion_environment === "prod" && (
          <TextField
            label="Change Request Number"
            onChange={(value) =>
              setPromotionRequest({
                ...promotionRequest,
                change_management_request: value,
              })
            }
            width="250px"
          />
        )}
        <DatePicker
          autoFocus
          label="Effective Date"
          minValue={today(getLocalTimeZone())}
          dateFormat="dd/MM/YYYY"
          onChange={(date) =>
            setPromotionRequest({
              ...promotionRequest,
              effective_date: date.year
                .toString()
                .concat("-", date.month, "-", date.day),
            })
          }
          width="250px"
        />
        <View>
          <Button
            variant="accent"
            onPress={handleSubmit}
            isDisabled={
              (promotionRequest.next_promotion_environment === "prod" &&
                promotionRequest.change_management_request === null) ||
              promotionRequest.effective_date === null ||
              promotionRequest.next_promotion_environment === null
            }
          >
            Submit
          </Button>
        </View>
      </Flex>
    </Form>
  );
}

CodePromotionForm.propTypes = {
  group: PropTypes.objectOf(
    PropTypes.oneOfType(
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.object)
    )
  ).isRequired,
  setIsSidePaneOpen: PropTypes.func.isRequired,
};

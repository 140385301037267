import {
  ActionButton,
  Flex,
  Heading,
  Item,
  Menu,
  MenuTrigger,
  Picker,
  Text,
  Tooltip,
  TooltipTrigger,
  View,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { error, success } from "@react/react-spectrum/Toast";
import ApproveReject from "@spectrum-icons/workflow/ApproveReject";
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import CloseCircle from "@spectrum-icons/workflow/CloseCircle";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { groupsApi } from "../../../api/groupsApi";
import {
  RecievedApprovedRequestColumns,
  RecievedPendingRequestColumns,
  RecievedRejectedRequestColumns,
} from "../../../constants/Columns";
import {
  GroupsRequestPath,
  ReceivedGroupRequestsPath,
} from "../../../constants/Routes";
import { APPROVED, PENDING, REJECTED } from "../../../constants/Status";
import useUserProfile from "../../../context/user-context";
import Table from "../../../components/Common/Table";
import ApproveDialog from "../../../components/Dialog/ApproveDialog";
import LoadingDialog from "../../../components/Dialog/LoadingDialog";

const tabs = [
  {
    id: "Pending",
    label: "Pending",
  },
  {
    id: "Approved",
    label: "Approved",
  },
  {
    id: "Rejected",
    label: "Rejected",
  },
];

export default function ReceivedGroupRequests() {
  const [groupRequests, setGroupRequests] = useState([]);
  const [filters, setFilters] = useState({ status: "" });
  const [isOpen, setOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({
    requestId: "",
    status: "",
    requestType: "",
    approverEmail: "",
    approverName: "",
  });
  const [columns, setColumns] = useState(RecievedPendingRequestColumns());
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();
  const [loadingState, setLoadingState] = useState("loading");
  const history = useHistory();
  const [isPageLoading, setPageLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    if (user) {
      setSelectedRequest((prev) => ({
        ...prev,
        approverEmail: user?.email,
        approverName: user?.name,
      }));
      setFilters((prev) => ({ ...prev, status: PENDING }));
    }
  }, [user]);

  useEffect(() => {
    if (filters?.status?.length !== 0) {
      groupsApi
        .fetchGroupRequestsByStatus(
          "Received",
          filters.status,
          user?.userId,
          authState.accessToken
        )
        .then((data) => {
          setGroupRequests([...data]);
          setLoadingState("");
        })
        .catch((e) => {
          setLoadingState("");
          if (e.toString().toLowerCase().includes("network"))
            error(`Server not reachable! Please contact CAM team!`, {
              timeout: 5000,
            });
          else if (e?.response?.data) {
            error(e?.response?.data?.message, {
              timeout: 5000,
            });
          } else {
            error(`Sorry something went wrong! Please contact CAM team!`, {
              timeout: 5000,
            });
          }
        });
    }
  }, [filters]);

  const filterTableData = () =>
    groupRequests.filter(
      (groupRequest) => groupRequest?.status === filters?.status
    );

  const handleApproval = (requestId, payload) => {
    setPageLoading(true);
    groupsApi
      .approvalGroupRequest(requestId, payload, authState.accessToken)
      .then((data) => {
        if (data?.acknowledged) {
          success(`Request ${data?.requestId} has been ${data?.status}!`, {
            timeout: 5000,
          });
          setFilters((prev) => ({ ...prev, status: PENDING }));
          setPageLoading(false);
        }
      })
      .catch((e) => {
        setPageLoading(false);
        if (e.toString().toLowerCase().includes("network"))
          error(`Server not reachable! Please contact CAM team!`, {
            timeout: 5000,
          });
        else if (e?.response?.data) {
          error(e?.response?.data?.message, {
            timeout: 5000,
          });
        } else {
          error(`Sorry something went wrong! Please contact CAM team!`, {
            timeout: 5000,
          });
        }
      })
      .finally(() => history.push(ReceivedGroupRequestsPath));
  };

  const handleOnAction = (requestId, requestType, status) => {
    if (status === APPROVED) {
      handleApproval(requestId, {
        status,
        approverEmail: selectedRequest?.approverEmail,
        approverName: selectedRequest?.approverName,
      });
    } else if (status === REJECTED) {
      setSelectedRequest((prev) => ({
        ...prev,
        requestId,
        status,
        requestType,
      }));
      setOpen(!isOpen);
    } else {
      history.push(ReceivedGroupRequestsPath);
    }
  };

  useEffect(() => {
    if (selectedRequest?.approverEmail && params?.requestId) {
      handleOnAction(params?.requestId, params?.requestType, params?.status);
    }
  }, [selectedRequest.approverEmail]);

  const renderAction = (requestId, requestType) => (
    <MenuTrigger>
      <TooltipTrigger>
        <ActionButton isQuiet>
          <View>
            <ApproveReject color="informative" />
          </View>
        </ActionButton>
        <Tooltip>Approve or Reject</Tooltip>
      </TooltipTrigger>

      <Menu onAction={(key) => handleOnAction(requestId, requestType, key)}>
        <Item key="Approved" textValue="Approve">
          <CheckmarkCircle color="positive" />
          <Text>Approve</Text>
        </Item>
        <Item key="Rejected" textValue="Reject">
          <CloseCircle color="negative" />
          <Text>Reject</Text>
        </Item>
      </Menu>
    </MenuTrigger>
  );

  const renderCell = (colKey, row) => {
    if (colKey === "actions") {
      return renderAction(row.requestId, row.tyepOfRequest);
    }
    return <span>{row[colKey]}</span>;
  };

  return (
    <Flex direction="column" margin="size-300" gap="size-300">
      <LoadingDialog isOpen={isPageLoading} />
      <View>
        <Heading level={3}>Received Group Requests</Heading>
      </View>
      <View>
        <Picker
          items={tabs}
          label="Request status"
          selectedKey={filters?.status}
          onSelectionChange={(selected) => {
            setLoadingState("loading");
            if (selected === APPROVED) {
              setColumns(RecievedApprovedRequestColumns());
            } else if (selected === REJECTED) {
              setColumns(RecievedRejectedRequestColumns());
            } else {
              setColumns(RecievedPendingRequestColumns());
            }
            setFilters((prev) => ({ ...prev, status: selected }));
          }}
        >
          {(item) => <Item key={item.id}>{item.label}</Item>}
        </Picker>
      </View>
      <View>
        <Flex
          height="size-6000"
          width="90%"
          UNSAFE_style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}
          UNSAFE_className="stepper-box"
        >
          <Table
            columns={columns}
            rows={filterTableData()}
            renderCell={renderCell}
            loadingState={loadingState}
          />
        </Flex>
      </View>
      <View>
        <ActionButton
          isQuiet
          onPress={() => {
            history.push(GroupsRequestPath);
          }}
        >
          <ChevronLeft />
          <Text>Group Requests</Text>
        </ActionButton>
      </View>
      <ApproveDialog
        openDialog={isOpen}
        setOpen={() => setOpen(!isOpen)}
        selectedRequest={selectedRequest}
        handleApproval={handleApproval}
      />
    </Flex>
  );
}

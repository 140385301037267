import { Flex, View, Heading, Form, TextField } from "@adobe/react-spectrum";
import React from "react";
import PropTypes from "prop-types";

export default function Step3({
  activeStep,
  confirmationMessage,
  createGroupPayload,
}) {
  return (
    <View
      UNSAFE_className={`card ${activeStep === 3 ? "active" : "hide"}`}
      key="step3"
      overflow="auto"
    >
      <Flex direction="column" justifyContent="center">
        <Heading
          level={4}
          marginTop="size-300"
          alignSelf="center"
          UNSAFE_style={{ color: "#1da973" }}
        >
          {confirmationMessage}
        </Heading>
        <Flex
          direction="row"
          gap="size-125"
          width="100%"
          justifyContent="center"
          marginTop="size-300"
        >
          <Form labelPosition="side" width="40%">
            <TextField
              autoFocus
              label="Create New Group"
              labelPosition="side"
              labelAlign="end"
              value={createGroupPayload.group_key}
              width="size-100"
              isReadOnly
              isQuiet
            />
            <TextField
              autoFocus
              label="Group Description"
              labelPosition="side"
              labelAlign="end"
              value={createGroupPayload.group_description}
              isReadOnly
              isQuiet
            />
            <TextField
              autoFocus
              label="Requestor"
              labelPosition="side"
              labelAlign="end"
              value={createGroupPayload?.requested_by?.name}
              isReadOnly
              isQuiet
            />
            <TextField
              autoFocus
              label="Approver(s)"
              labelPosition="side"
              labelAlign="end"
              value={
                createGroupPayload?.approvers_list
                  ? createGroupPayload.approvers_list
                      .map((admin) => admin.name)
                      .join(", ")
                  : ""
              }
              isReadOnly
              isQuiet
            />
            <TextField
              autoFocus
              label="Effective Date in Stage"
              labelPosition="side"
              labelAlign="end"
              value={createGroupPayload.effective_date}
              isReadOnly
              isQuiet
            />
            <TextField
              autoFocus
              label="Status"
              labelPosition="side"
              labelAlign="end"
              value="Submitted"
              isReadOnly
              isQuiet
            />
          </Form>
        </Flex>
      </Flex>
    </View>
  );
}

Step3.propTypes = {
  activeStep: PropTypes.number.isRequired,
  confirmationMessage: PropTypes.string.isRequired,
  createGroupPayload: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.string,
          PropTypes.bool,
          PropTypes.arrayOf(PropTypes.string)
        )
      ),
    ])
  ).isRequired,
};

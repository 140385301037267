import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Text,
  useDialogContainer,
} from "@adobe/react-spectrum";
import Table from "../Common/Table";
import { EAMColumns } from "../../constants/Columns";

export default function PickOrigSourceDialog({
  currentMapping,
  handleNextStep,
}) {
  const [newMapping, setNewMapping] = useState(currentMapping);
  const [selectedKeys, setSelectedKeys] = useState(new Set([]));
  const eamColumns = EAMColumns();
  const dialog = useDialogContainer();

  useEffect(() => {
    setNewMapping((prev) =>
      prev.map((prevItem) => {
        if (prevItem?.id === [...selectedKeys.values()][0])
          return { ...prevItem, syncSource: true };

        return { ...prevItem, syncSource: false };
      })
    );
  }, [selectedKeys]);

  const renderCell = (colKey, row) => {
    let cellStyle = {};

    if (row?.status && row?.status === "added") {
      cellStyle = { color: "green" };
    }

    if (colKey === "address_key") {
      const address = `${row.address[0].street ? row.address[0].street : ""} ${
        row.address[0].street_sup ? row.address[0].street_sup : ""
      } ${row.address[0].city ? row.address[0].city : ""}, ${
        row.address[0].state ? row.address[0].state : ""
      } ${row.address[0].country ? row.address[0].country : ""} ${
        row.address[0].postalcode ? row.address[0].postalcode : ""
      }`;
      return <Text UNSAFE_style={cellStyle}>{address}</Text>;
    }

    if (colKey === "account_id" && row?.syncSource)
      return (
        <Flex gap="size-125">
          <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
          <Badge
            variant="seafoam"
            alignSelf="center"
            UNSAFE_style={{ fontSize: "x-small" }}
          >
            Original source
          </Badge>
        </Flex>
      );

    return <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };

  return (
    <Dialog size="L" width="70rem">
      <Heading>Pick original source account</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-500">
          <Text>
            You are re-mapping an original source account, kindly pick a new
            source account from below members:
          </Text>
          <Flex direction="column" justifyContent="center" gap="size-125">
            <Flex justifyContent="center">
              <Heading level={4}>Current mapping</Heading>
            </Flex>
            <Flex
              direction="row"
              gap="size-125"
              UNSAFE_style={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
              }}
              UNSAFE_className="stepper-box"
            >
              <Table
                columns={eamColumns}
                rows={newMapping}
                renderCell={renderCell}
                selectionMode="single"
                density="compact"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                disallowEmptySelection
              />
            </Flex>
          </Flex>
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Cancel
        </Button>
        <Button
          variant="accent"
          onPress={() => {
            handleNextStep(newMapping);
            dialog.dismiss();
          }}
          isDisabled={selectedKeys?.size < 1}
        >
          Confirm
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

PickOrigSourceDialog.propTypes = {
  currentMapping: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  handleNextStep: PropTypes.func.isRequired,
};

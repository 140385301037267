import {
  Content,
  Heading,
  IllustratedMessage,
  Link as V3Link,
  Text,
} from "@adobe/react-spectrum";
import IllustratedUnauthorized from "@spectrum-icons/illustrations/Unauthorized";
import React from "react";
import { useHistory } from "react-router-dom";
import { CreateAndSubscribeGroupsPath } from "../../constants/Routes";

const Unauthorized = () => {
  const history = useHistory();
  return (
    <IllustratedMessage>
      <IllustratedUnauthorized />
      <Heading>Access not allowed</Heading>
      <Content>
        <Text>You do not have permission to access this page.&nbsp;</Text>
        <V3Link
          isQuiet
          variant="primary"
          onPress={() => history.push(CreateAndSubscribeGroupsPath)}
        >
          Click here
        </V3Link>
        <Text>&nbsp; to request access to a group</Text>
      </Content>
    </IllustratedMessage>
  );
};

export default Unauthorized;

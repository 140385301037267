import {
  ActionButton,
  Flex,
  Heading,
  Item,
  Picker,
  Text,
  View,
  DialogContainer,
  TooltipTrigger,
  Tooltip,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { success, error } from "@react/react-spectrum/Toast";
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import CloseCircle from "@spectrum-icons/workflow/CloseCircle";
import Pending from "@spectrum-icons/workflow/Pending";
import ViewDetail from "@spectrum-icons/workflow/ViewDetail";
import ApproveReject from "@spectrum-icons/workflow/ApproveReject";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { lagApi } from "../../../../api/lagApi";
import {
  LAGRecievedRejectedRequestColumns,
  LAGRecievedApprovedRequestColumns,
  LAGRecievedPendingRequestColumns,
} from "../../../../constants/Columns";
import {
  ManageLogicalAccountGroupingPath,
  ReceivedLogicalAccountGroupingPath,
} from "../../../../constants/Routes";
import { APPROVED, PENDING, REJECTED } from "../../../../constants/Status";
import { LAGGroupStatusOptions } from "../../../../constants/PickerOptions";
import useUserProfile from "../../../../context/user-context";
import Table from "../../../../components/Common/Table";
import LAGDetailsDialog from "../../../../components/Dialog/LAGDetailsDialog";

export default function SentLAGRequests() {
  const [groupRequests, setGroupRequests] = useState([]);
  const [filters, setFilters] = useState({ status: PENDING });
  const history = useHistory();
  const [columns, setColumns] = useState(LAGRecievedPendingRequestColumns());
  const [selectedGroup, setSelectedGroup] = useState();
  const [isReadOnly, setIsReadOnly] = useState();
  const [viewLAGDetails, setViewLAGDetails] = useState(false);
  const [requestStatus, setRequestStatus] = useState(PENDING);
  const [loadingState, setLoadingState] = useState("loading");
  const [rejectionReason, setRejectionReason] = useState("");
  const [isReject, setIsReject] = useState(false);
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();

  const params = useParams();

  const handleError = (e) => {
    setLoadingState("");
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    if (filters?.status?.length !== 0 && user?.userId) {
      lagApi
        .fetchGroupsByStatus(
          {
            status: requestStatus,
            utility_name: "LAG",
            user,
            admin_panel: true,
          },
          authState.accessToken
        )
        .then((data) => {
          setLoadingState("loading");
          setGroupRequests(data.groups);
          setLoadingState("");
        })
        .catch((e) => handleError(e));
    }
  }, [filters, user, viewLAGDetails]);

  const handleApprove = (groupKey) => {
    setLoadingState("loading");
    lagApi
      .approveLAGRequest(
        {
          group_key: groupKey,
          user,
        },
        authState.accessToken
      )
      .then(() => {
        setLoadingState("");
        success(`Group ${groupKey} has been approved!`, {
          timeout: 5000,
        });
      })
      .catch((e) => handleError(e));
  };

  const handleReject = (groupKey) => {
    setLoadingState("loading");
    lagApi
      .rejectLAGRequest(
        {
          group_key: groupKey,
          user,
          rejection_reason: rejectionReason,
        },
        authState.accessToken
      )
      .then(() => {
        setLoadingState("");
        success(`Group ${groupKey} has been rejected!`, {
          timeout: 5000,
        });
      })
      .catch((e) => handleError(e));
  };

  const handleOnAction = (groupKey, status) => {
    if (status === APPROVED) {
      handleApprove(groupKey);
    } else if (status === REJECTED) {
      setIsReject(true);
      setViewLAGDetails(!viewLAGDetails);
    } else {
      history.push(ReceivedLogicalAccountGroupingPath);
    }
  };

  useEffect(() => {
    if (params?.groupKey && params?.status && user !== null) {
      handleOnAction(params?.groupKey, params?.status);
    }
  }, [user]);

  const handleViewGroupDetails = (group, isViewOnly) => {
    setSelectedGroup(group);
    setIsReadOnly(isViewOnly);
    setViewLAGDetails(true);
  };

  const renderStatus = (status) => {
    let statusIcon = (
      <CloseCircle aria-label="Rejected requests" color="negative" />
    );

    if (status === PENDING)
      statusIcon = <Pending aria-label="Pending requests" color="notice" />;
    else if (status === APPROVED)
      statusIcon = (
        <CheckmarkCircle aria-label="Approved requests" color="positive" />
      );

    return statusIcon;
  };

  const renderCell = (colKey, row) => {
    if (colKey === "status") {
      return renderStatus(row.status);
    }
    if (colKey === "view") {
      return (
        <TooltipTrigger>
          <ActionButton
            isQuiet
            onPress={() => handleViewGroupDetails(row, true)}
          >
            <View>
              <ViewDetail color="informative" />
            </View>
          </ActionButton>
          <Tooltip>View Group Details</Tooltip>
        </TooltipTrigger>
      );
    }
    if (colKey === "approve_reject") {
      return (
        <TooltipTrigger>
          <ActionButton
            isQuiet
            onPress={() => handleViewGroupDetails(row, false)}
          >
            <View>
              <ApproveReject color="informative" />
            </View>
          </ActionButton>
          <Tooltip>Approve/Reject Group</Tooltip>
        </TooltipTrigger>
      );
    }
    if (colKey === "requested_by") {
      return <span>{row[colKey].email}</span>;
    }
    return <span>{row[colKey]}</span>;
  };

  return (
    <Flex direction="column" margin="size-300" gap="size-300">
      <View>
        <Heading level={3}>Received LAG Requests</Heading>
      </View>
      <View>
        <Picker
          items={LAGGroupStatusOptions()}
          label="Request status"
          selectedKey={filters?.status}
          onSelectionChange={(selected) => {
            setLoadingState("loading");
            setRequestStatus(selected);
            if (selected === REJECTED) {
              setColumns(LAGRecievedRejectedRequestColumns());
            } else if (selected === PENDING) {
              setColumns(LAGRecievedPendingRequestColumns());
            } else {
              setColumns(LAGRecievedApprovedRequestColumns());
            }
            setFilters((prev) => ({ ...prev, status: selected }));
          }}
        >
          {(item) => <Item key={item.id}>{item.label}</Item>}
        </Picker>
      </View>
      <View>
        <Flex
          height="size-6000"
          width="90%"
          UNSAFE_style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}
          UNSAFE_className="stepper-box"
        >
          <Table
            columns={columns}
            rows={groupRequests}
            renderCell={renderCell}
            loadingState={loadingState}
          />
        </Flex>
      </View>
      <View>
        <ActionButton
          isQuiet
          onPress={() => {
            history.push(ManageLogicalAccountGroupingPath);
          }}
        >
          <ChevronLeft />
          <Text>LAG Requests</Text>
        </ActionButton>
      </View>

      <DialogContainer onDismiss={() => setViewLAGDetails(false)}>
        {viewLAGDetails && (
          <LAGDetailsDialog
            groupKey={params?.groupKey || selectedGroup.group_key}
            isReadOnly={isReadOnly || false}
            handleApprove={handleApprove}
            handleReject={handleReject}
            rejectionReason={rejectionReason}
            setRejectionReason={setRejectionReason}
            isReject={isReject}
          />
        )}
      </DialogContainer>
    </Flex>
  );
}

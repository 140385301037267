import { Flex, SearchField, View } from "@adobe/react-spectrum";
import { error } from "@react/react-spectrum/Toast";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { groupsApi } from "../../api/groupsApi";
import { ManageGroupsColumn } from "../../constants/Columns";
import useUserProfile from "../../context/user-context";
import GroupsList from "../../components/GroupsList/GroupsList";

export default function ManageGroups() {
  const [filters, setFilters] = useState({ name: "" });
  const [groups, setGroups] = useState([]);
  const columns = ManageGroupsColumn();

  const { user } = useUserProfile();

  const { authState } = useOktaAuth();

  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    if (user != null) {
      groupsApi
        .fetchUserOwnedGroups(user?.userId, authState.accessToken)
        .then((data) => {
          setGroups(data);
          setLoadingState("");
        })
        .catch((e) => {
          setLoadingState("");
          if (e.toString().toLowerCase().includes("network"))
            error(`Server not reachable! Please contact CAM team!`, {
              timeout: 5000,
            });
          else if (e?.response?.data) {
            error(e?.response?.data?.message, {
              timeout: 5000,
            });
          } else {
            error(`Sorry something went wrong! Please contact CAM team!`, {
              timeout: 5000,
            });
          }
        });
    }
  }, [user]);

  return (
    <View>
      <Flex
        margin="size-300"
        gap="size-150"
        width="70%"
        justifyContent="space-between"
        direction="column"
      >
        <View>
          <SearchField
            label="Search by group name"
            onChange={(val) => {
              setFilters({ ...filters, name: val });
            }}
          />
        </View>
      </Flex>
      <GroupsList
        filters={filters}
        items={groups}
        columns={columns}
        loadingState={loadingState}
      />
    </View>
  );
}

import { Flex, View, Form, TextField, DatePicker } from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getLocalTimeZone, today } from "@internationalized/date";
import { useOktaAuth } from "@okta/okta-react";
import { error } from "@react/react-spectrum/Toast";
import { lagApi } from "../../../../api/lagApi";
import { groupsApi } from "../../../../api/groupsApi";
import useUserProfile from "../../../../context/user-context";
import { ADMIN } from "../../../../constants/RoleType";

export default function Step2({
  activeStep,
  newLagRule,
  createGroupPayload,
  setCreateGroupPayload,
}) {
  const [groupKey, setGroupKey] = useState();
  const { user } = useUserProfile();
  const { authState } = useOktaAuth();

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    groupsApi
      .fetchGroups(authState.accessToken)
      .then((data) => {
        setCreateGroupPayload({
          ...createGroupPayload,
          approvers_list: data
            .find((grp) => grp.groupName === "GRP-LAG")
            .groupMembers.filter((member) => member.role === ADMIN),
          requested_by: user,
          group_description: newLagRule
            .map((attr) => {
              let description = attr.field_name;
              if (
                (attr.include && attr.include.length > 0) ||
                (attr.exclude && attr.exclude.length > 0) ||
                attr.partition_value
              ) {
                description = description.concat("[");
                if (attr.include && attr.include.length > 0)
                  description = description.concat("inc(", attr.include, ")");
                if (attr.exclude && attr.exclude.length > 0)
                  description = description.concat("exc(", attr.exclude, ")");
                if (attr.partition_value)
                  description = description.concat(
                    "partition(",
                    attr.partition_value,
                    ")"
                  );
                description = description.concat("]");
              }
              return description;
            })
            .join(" + "),
          group_key: groupKey,
          attributes: newLagRule,
        });
      })
      .catch((e) => handleError(e));
  }, [newLagRule, user, groupKey]);

  useEffect(() => {
    lagApi
      .fetchGroupKey(authState.accessToken)
      .then((data) => setGroupKey(data));
  }, []);

  return (
    <View
      UNSAFE_className={`card ${activeStep === 2 ? "active" : "hide"}`}
      key="step2"
      overflow="auto"
    >
      <Flex
        direction="row"
        gap="size-125"
        width="100%"
        justifyContent="center"
        marginTop="size-300"
      >
        <Form labelPosition="side" width="40%">
          <TextField
            autoFocus
            label="Create New Group"
            labelPosition="side"
            labelAlign="end"
            value={createGroupPayload.group_key}
            width="size-100"
            isReadOnly
            isQuiet
          />
          <TextField
            autoFocus
            label="Group Description"
            labelPosition="side"
            labelAlign="end"
            value={createGroupPayload.group_description}
            isReadOnly
            isQuiet
          />
          <TextField
            autoFocus
            label="Requestor"
            labelPosition="side"
            labelAlign="end"
            value={createGroupPayload?.requested_by?.name}
            isReadOnly
            isQuiet
          />
          <TextField
            autoFocus
            label="Approver(s)"
            labelPosition="side"
            labelAlign="end"
            value={
              createGroupPayload?.approvers_list
                ? createGroupPayload.approvers_list
                    .map((admin) => admin.name)
                    .join(", ")
                : ""
            }
            isReadOnly
            isQuiet
          />
          <DatePicker
            autoFocus
            label="Effective Date (Stage)"
            labelPosition="side"
            labelAlign="end"
            minValue={today(getLocalTimeZone())}
            dateFormat="dd/MM/YYYY"
            onChange={(date) =>
              setCreateGroupPayload({
                ...createGroupPayload,
                effective_date: date.year
                  .toString()
                  .concat("-", date.month, "-", date.day),
              })
            }
          />
        </Form>
      </Flex>
    </View>
  );
}

Step2.propTypes = {
  activeStep: PropTypes.number.isRequired,
  newLagRule: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string]))
    ),
    PropTypes.any,
  ]).isRequired,
  createGroupPayload: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.string,
          PropTypes.bool,
          PropTypes.arrayOf(PropTypes.string)
        )
      ),
    ])
  ).isRequired,
  setCreateGroupPayload: PropTypes.func.isRequired,
};

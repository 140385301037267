import React from "react";

import { Badge, Flex, Heading, Text, View } from "@adobe/react-spectrum";
import Unmerge from "@spectrum-icons/workflow/Unmerge";
import PropTypes from "prop-types";
import Table from "../../../../../components/Common/Table";
import { EAMConfirmChangesColumns } from "../../../../../constants/Columns";

export default function SplitFinishStep({
  currentSyncSet,
  survivingSyncSet,
  newSyncSet,
}) {
  const eamColumns = EAMConfirmChangesColumns();

  const renderCell = (colKey, row) => {
    let cellStyle = {};

    if (row?.is_modified) {
      cellStyle = { color: "green" };
    }

    if (colKey === "account_id") {
      return row?.syncSource ? (
        <Flex gap="size-125">
          <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
          <Badge
            variant="seafoam"
            alignSelf="center"
            UNSAFE_style={{ fontSize: "x-small" }}
          >
            Original source
          </Badge>
        </Flex>
      ) : (
        <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
      );
    }

    return <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };

  return (
    <Flex direction="row" gap="size-125">
      <Flex
        direction="column"
        justifyContent="center"
        gap="size-125"
        width="50%"
      >
        <Flex justifyContent="center">
          <Heading level={4}>Current sync set</Heading>
        </Flex>
        <Flex
          direction="row"
          gap="size-125"
          UNSAFE_style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}
          UNSAFE_className="stepper-box"
        >
          <Table
            columns={eamColumns}
            rows={currentSyncSet}
            renderCell={renderCell}
            selectionMode="none"
            density="compact"
          />
        </Flex>
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <View>
          <Unmerge color="informative" aria-label="Split" />
        </View>
      </Flex>
      <Flex direction="column" gap="size-125" width="50%">
        <Flex justifyContent="center">
          <Heading level={4}>Surving sync set</Heading>
        </Flex>

        <Flex
          direction="row"
          gap="size-125"
          UNSAFE_style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}
          UNSAFE_className="stepper-box"
        >
          <Table
            columns={eamColumns}
            rows={survivingSyncSet}
            renderCell={renderCell}
            density="compact"
          />
        </Flex>
        <Flex justifyContent="center">
          <Heading level={4}>New sync set</Heading>
        </Flex>

        <Flex
          direction="row"
          gap="size-125"
          UNSAFE_style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}
          UNSAFE_className="stepper-box"
        >
          <Table
            columns={eamColumns}
            rows={newSyncSet}
            renderCell={renderCell}
            density="compact"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

SplitFinishStep.propTypes = {
  currentSyncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  survivingSyncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  newSyncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
};

import { Flex, Heading, LabeledValue, Text, View } from "@adobe/react-spectrum";
import React from "react";
import PropTypes from "prop-types";
import Collapsible from "../../Collapsible/Collapsible";
import "./step-card.css";

export default function StepCard({ account }) {
  return (
    <View
      key={`${account?.account_id}:${account?.source_system}`}
      aria-labelledby={`${account?.source_system} source account id ${account?.account_id}`}
    >
      <Flex direction="column" gap="size-125">
        <Heading level={3}>{account?.account_name}</Heading>
        <Flex direction="column">
          <Text>{`${account?.account_id} (${account?.source_system})`}</Text>
          <Text>{`${
            account?.address[0]?.street ? account?.address[0]?.street : ""
          } ${account?.address[0]?.city ? account?.address[0]?.city : ""} ${
            account?.address[0]?.state ? account?.address[0]?.state : ""
          } ${
            account?.address[0]?.country ? account?.address[0]?.country : ""
          } ${
            account?.address[0]?.postalcode
              ? `- `.concat(account?.address[0]?.postalcode)
              : ""
          }`}</Text>
        </Flex>
      </Flex>

      <View marginTop="size-125">
        <Collapsible>
          <Flex
            direction="column"
            key={account?.account_id}
            gap="size-200"
            marginTop="size-125"
          >
            <View
              backgroundColor="static-white"
              borderRadius="medium"
              borderWidth="thin"
              borderColor="gray-300"
              padding="size-125"
            >
              <LabeledValue
                label="Account name"
                value={account?.account_name}
              />
            </View>
            <View
              backgroundColor="static-white"
              borderRadius="medium"
              borderWidth="thin"
              borderColor="gray-300"
              padding="size-125"
            >
              <LabeledValue label="Account id" value={account?.account_id} />
            </View>
            <View
              backgroundColor="static-white"
              borderRadius="medium"
              borderWidth="thin"
              borderColor="gray-300"
              padding="size-125"
            >
              <LabeledValue
                label="Source system"
                value={account?.source_system}
              />
            </View>

            {account?.cam_id && (
              <View
                backgroundColor="static-white"
                borderRadius="medium"
                borderWidth="thin"
                borderColor="gray-300"
                padding="size-125"
              >
                <LabeledValue label="CAM id" value={account?.cam_id} />
              </View>
            )}
          </Flex>
        </Collapsible>
      </View>
    </View>
  );
}

StepCard.propTypes = {
  account: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
    ])
  ).isRequired,
};

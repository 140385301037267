import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import * as ACTIONS from "../store/actions/actions";
import { initialState, GroupsReducer } from "../store/reducers/groups-reducer";

export const GroupsContext = createContext();

export const GroupsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GroupsReducer, initialState);

  const createGroupDetails = (groupDetails) => {
    dispatch({
      type: ACTIONS.CREATE_GROUP_DETAILS,
      payload: {
        groupDetails,
      },
    });
  };

  const resetGroupDetails = () => {
    dispatch({
      type: ACTIONS.RESET_GROUP_DETAILS,
    });
  };

  const createGroupValidState = (groupValidState) => {
    dispatch({
      type: ACTIONS.CREATE_GROUP_VALID_STATE,
      payload: {
        groupValidState,
      },
    });
  };

  const resetGroupValidState = () => {
    dispatch({
      type: ACTIONS.RESET_GROUP_VALID_STATE,
    });
  };

  const value = {
    groupDetails: state.groupDetails,
    groupValidState: state.groupValidState,
    createGroupDetails,
    resetGroupDetails,
    createGroupValidState,
    resetGroupValidState,
  };

  return (
    <GroupsContext.Provider value={value}>{children}</GroupsContext.Provider>
  );
};

GroupsProvider.defaultProps = {
  children: [],
};
GroupsProvider.propTypes = {
  children: PropTypes.node,
};

const useGroups = () => {
  const context = useContext(GroupsContext);

  if (context === undefined) {
    throw new Error("useGroups must be used with GroupsContext");
  }

  return context;
};

export default useGroups;

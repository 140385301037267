import React, { useEffect, useState } from "react";
import { Tab, TabList } from "@react/react-spectrum/TabList";
import { useHistory, withRouter } from "react-router-dom";
import MainNavigationList from "../../../constants/MainNavigationList";
import { RootPath } from "../../../constants/Routes";
import useBreadcrumbs from "../../../context/breadcrumbs-context";
import "./main-navigation.css";

const MainNavigation = () => {
  const history = useHistory();
  const [activeMenuIndex, setActiveMenuIndex] = useState(0);
  const { resetBreadcrumbsLink, setIsBreadcrumbsLink } = useBreadcrumbs();

  const updateNavigationChange = (url) => {
    let rootMenu = ["home"];
    let navItem = { id: 0 };

    if (url === "/cam") history.push(RootPath);
    else {
      // If the url is not the root url
      if (
        url !== RootPath &&
        !url.includes("callback") &&
        !url.includes("logout")
      ) {
        rootMenu = url.split("/").filter((section) => section !== "");
      }

      if (rootMenu.length > 0) {
        navItem = MainNavigationList().find((nav) =>
          rootMenu.includes(nav.rootMenu)
        );
        setActiveMenuIndex(navItem.id);
      }
    }
  };

  useEffect(() => {
    resetBreadcrumbsLink();
    updateNavigationChange(history.location.pathname);
    setIsBreadcrumbsLink(true);
  }, [history.location]);

  const navigationChangeHandler = (navIndex) => {
    const navigationList = MainNavigationList();
    const { url } = navigationList[navIndex];
    history.push(url);
    setActiveMenuIndex(navIndex);
  };

  const renderNavigationItems = () =>
    MainNavigationList().map((nav) => (
      <Tab onClick={navigationChangeHandler} key={nav.id}>
        {nav.label}
      </Tab>
    ));

  return (
    <TabList
      aria-label="Main Navigation"
      className="main-navigation"
      selectedIndex={activeMenuIndex}
      onChange={navigationChangeHandler}
      quiet
    >
      {renderNavigationItems()}
    </TabList>
  );
};

export default withRouter(MainNavigation);

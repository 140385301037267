import { Button, Flex, Text } from "@adobe/react-spectrum";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function Logout() {
  const location = useLocation();
  const history = useHistory();
  const login = () => {
    history.push("/");
  };

  useEffect(() => {
    if (location.state === undefined) {
      history.push("/");
    }
  }, [history, location.state]);

  return (
    <div className="cam-font">
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginTop="size-400"
        gap="size-400"
      >
        <Text UNSAFE_style={{ color: "#4b4b4b", fontWeight: "bold" }}>
          You are logged out of the application.
        </Text>
        <Button variant="accent" onPress={() => login()}>
          Login
        </Button>
      </Flex>
    </div>
  );
}

import {
  Badge,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Text,
  useDialogContainer,
  View,
} from "@adobe/react-spectrum";
import ArrowRight from "@spectrum-icons/workflow/ArrowRight";
import PropTypes from "prop-types";
import React from "react";
import { EAMConfirmChangesColumns } from "../../constants/Columns";
import Table from "../Common/Table";

export default function ConfirmCompleteSyncDialog({
  currentMapping,
  newMapping,
  type,
  handleNext,
}) {
  const eamColumns = EAMConfirmChangesColumns();
  const dialog = useDialogContainer();

  const renderCell = (colKey, row) => {
    let cellStyle = {};

    if (row?.isNewAccount) {
      cellStyle = { color: "green" };
    }

    if (row?.status === "removed") {
      cellStyle = { color: "red" };
    }

    if (colKey === "address_key") {
      const address = `${row.address[0].street ? row.address[0].street : ""} ${
        row.address[0].street_sup ? row.address[0].street_sup : ""
      } ${row.address[0].city ? row.address[0].city : ""}, ${
        row.address[0].state ? row.address[0].state : ""
      } ${row.address[0].country ? row.address[0].country : ""} ${
        row.address[0].postalcode ? row.address[0].postalcode : ""
      }`;
      return <Text UNSAFE_style={cellStyle}>{address}</Text>;
    }

    if (colKey === "account_id" && row?.syncSource) {
      return (
        <Flex gap="size-125">
          <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
          <Badge
            variant="seafoam"
            alignSelf="center"
            UNSAFE_style={{ fontSize: "x-small" }}
          >
            Original source
          </Badge>
        </Flex>
      );
    }

    return <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };

  return (
    <Dialog size="L" width="75rem">
      <Heading>{`Confirm ${type} changes`}</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-500">
          <Text>Are you sure you want to change mapping as shown below?</Text>
          <Flex direction="row" gap="size-125">
            <Flex
              direction="column"
              justifyContent="center"
              gap="size-125"
              width="50%"
            >
              <Flex justifyContent="center">
                <Heading level={4}>Current mapping</Heading>
              </Flex>
              <Flex
                direction="row"
                gap="size-125"
                UNSAFE_style={{
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                }}
                UNSAFE_className="stepper-box"
              >
                <Table
                  columns={eamColumns}
                  rows={currentMapping}
                  renderCell={renderCell}
                  density="compact"
                />
              </Flex>
            </Flex>
            <Flex justifyContent="center" alignItems="center">
              <View>
                <ArrowRight color="informative" />
              </View>
            </Flex>
            <Flex
              direction="column"
              justifyContent="center"
              gap="size-125"
              width="50%"
            >
              <Flex justifyContent="center">
                <Heading level={4}>New mapping</Heading>
              </Flex>
              <Flex
                direction="row"
                gap="size-125"
                UNSAFE_style={{
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                }}
                UNSAFE_className="stepper-box"
              >
                <Table
                  columns={eamColumns}
                  rows={newMapping}
                  renderCell={renderCell}
                  density="compact"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Cancel
        </Button>
        <Button
          variant="accent"
          onPress={() => {
            dialog.dismiss();
            handleNext();
          }}
        >
          Confirm
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

ConfirmCompleteSyncDialog.propTypes = {
  type: PropTypes.string.isRequired,
  currentMapping: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  newMapping: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  handleNext: PropTypes.func.isRequired,
};

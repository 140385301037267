import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import * as ACTIONS from "../store/actions/actions";
import { initialState, UserReducer } from "../store/reducers/user-reducer";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const addUserProfile = (user) => {
    dispatch({
      type: ACTIONS.ADD_USER_PROFILE,
      payload: {
        user,
      },
    });
  };

  const removeUserProfile = () => {
    dispatch({
      type: ACTIONS.REMOVE_USER_PROFILE,
    });
  };

  const value = {
    user: state.user,
    addUserProfile,
    removeUserProfile,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.defaultProps = {
  children: {},
};
UserProvider.propTypes = {
  children: PropTypes.node,
};

const useUserProfile = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUserProfile must be used with UserContext");
  }

  return context;
};

export default useUserProfile;

import {
  Badge,
  Button,
  Flex,
  Heading,
  Item,
  Link as V3Link,
  Picker,
  SearchField,
  Text,
  View,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { error } from "@react/react-spectrum/Toast";
import Channel from "@spectrum-icons/workflow/Channel";
import PlatformDataMapping from "@spectrum-icons/workflow/PlatformDataMapping";
import Unmerge from "@spectrum-icons/workflow/Unmerge";
// import _ from "lodash";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { eamApi } from "../../../api/eamApi";
import { crossSystemAccountApi } from "../../../api/lookupApi";
// import { groupsApi } from "../../../api/groupsApi";
import CamSlidingPane from "../../../components/Common/CamSlidingPane/CamSlidingPane";
import StepCard from "../../../components/Common/Card/StepCard/StepCard";
import Table from "../../../components/Common/Table";
import LoadingDialog from "../../../components/Dialog/LoadingDialog";
import AccountMappingForm from "../../../components/Forms/AccountMappingForm/AccountMappingForm";
import { EAMColumns } from "../../../constants/Columns";
import {
  EAMAccountTypeOptions,
  EAMSourceSystemOptions,
} from "../../../constants/PickerOptions";
import { ADMIN, OPERATOR, SUPERUSER, USER } from "../../../constants/RoleType";
import {
  CompleteSyncSetPath,
  RemapExternalAccountPath,
  SplitExternalAccountMappingsPath,
} from "../../../constants/Routes";
import { EAMSourceSystemAccountLabel } from "../../../constants/SlidingPaneLabels";
// import useUserProfile from "../../../context/user-context";
// import useInterval from "../../../hooks/useInterval";
import useValidUserAccess from "../../../hooks/useValidUserAccess";
import { EAM } from "../../../constants/CamFunctions";
import useValidCountriesStateLookup from "../../../context/isocode-context";

export default function ExternalAccountMapping() {
  const [filters, setFilters] = useState({
    typeOfAccount: "crm_account_id",
    id: "",
    sourceSystem: "sfdc-corp",
  });
  const [currentSyncSet, setCurrentSyncSet] = useState();
  const [originalSourceAccount, setOriginalSourceAccount] = useState();
  const [searchedAccount, setSearchedAccount] = useState();
  const [selectedAccountIds, setSelectedAccountIds] = useState(new Set([]));
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [sidePaneData, setSidePaneData] = useState([]);
  const [sidePaneTitle, setSidePaneTitle] = useState("");
  const [sidePaneLabels, setSidePaneLabels] = useState([]);
  const [isPageLoading, setPageLoading] = useState(false);
  const { authState } = useOktaAuth();
  const history = useHistory();
  const eamColumns = EAMColumns();
  const isEamActionAllowed = useValidUserAccess(
    [ADMIN, SUPERUSER, USER, OPERATOR],
    EAM
  );
  const [isIncomplete, setIncomplete] = useState(false);
  const [subscriberSourceSystems, setSubscriberSourceSystems] = useState([]);
  const accountTypeOptions = EAMAccountTypeOptions();
  const { validCountryStateCheck, addValidCountryState } =
    useValidCountriesStateLookup();

  const [sourceSystems, setSourceSystems] = useState([
    ...EAMSourceSystemOptions(),
  ]);

  // const { user, addUserProfile } = useUserProfile();

  // useInterval(async () => {
  //   if (user) {
  //     groupsApi
  //       .fetchUserInfo(user?.userId, authState.accessToken)
  //       .then((userInfo) => {
  //         if (
  //           !_.isEqual(user?.camFunctions, userInfo?.camFunctions) ||
  //           !_.isEqual(user?.userGroups, userInfo?.groups)
  //         ) {
  //           addUserProfile({
  //             name: user?.name,
  //             email: user?.email,
  //             userId: user?.userId,
  //             camFunctions: userInfo?.camFunctions,
  //             userGroups: userInfo?.groups,
  //           });
  //         }
  //       });
  //   }
  // }, window.apcConfig.cam.USERINFO_REFRESH_INTERVAL);

  useEffect(() => {
    if (validCountryStateCheck?.countries?.length === 0) {
      eamApi
        .fetchValidCountriesForStateCodeValidation(authState.accessToken)
        .then((data) => {
          if (data) {
            addValidCountryState({
              countries: Object.keys(data),
              countryStateMap: data,
            });
          }
        })
        .catch((e) => console.log(e));
    }
    eamApi
      .fetchSourceSystems(authState.accessToken)
      .then((data) => setSourceSystems(data))
      .catch((e) => console.log(e));
  }, []);

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
    setPageLoading(false);
  };

  function fetchMissingSourceSystem(syncSet, origSourceSystem) {
    if (syncSet) {
      crossSystemAccountApi
        .fetchMissingSourceSystems(origSourceSystem, authState.accessToken)
        .then((data) => {
          setIncomplete(
            syncSet?.length !== data?.length + 1 &&
              !data?.every((sourceSystem) =>
                syncSet?.some((acc) => acc?.source_system === sourceSystem)
              )
          );
          setSubscriberSourceSystems(data);
          setPageLoading(false);
        })
        .catch((e) => handleError(e));
    }
  }

  const fetchAccounts = (value, srcSystem) => {
    if (value) {
      setPageLoading(true);
      const searchedValue = value || filters?.id;
      const sourceSystem = srcSystem || filters?.sourceSystem;

      setSelectedAccountIds(new Set([]));

      eamApi
        .fetchExternalAccountMappings(
          `/api/eam/mcsmappings/${searchedValue}`,
          { sourceSystem },
          authState.accessToken
        )
        .then((data) => {
          const results = data?.results || [];
          setCurrentSyncSet(results);
          const searchAcc = results?.find(
            (acc) =>
              acc.account_id?.toLowerCase() === searchedValue?.toLowerCase()
          );
          setSearchedAccount(searchAcc);
          history.replace({
            ...history.location,
            state: { ...history.location.state, searchedAcc: searchAcc },
          });
          if (searchAcc?.id) {
            setSelectedAccountIds(new Set([searchAcc?.id]));
          }
          const origSourceAcc = results?.find((acc) => acc?.syncSource);
          if (origSourceAcc) {
            setPageLoading(true);
            setOriginalSourceAccount(origSourceAcc);
            fetchMissingSourceSystem(results, origSourceAcc?.source_system);
          } else {
            setPageLoading(false);
          }
        })
        .catch((e) => handleError(e));
    }
  };

  useEffect(() => {
    if (history?.location?.state?.searchedAcc) {
      fetchAccounts(
        history?.location?.state?.searchedAcc?.account_id,
        history?.location?.state?.searchedAcc?.source_system
      );
      setFilters((prev) => ({
        ...prev,
        id: history?.location?.state?.searchedAcc?.account_id,
        sourceSystem: history?.location?.state?.searchedAcc?.source_system,
      }));
    }
  }, []);

  useEffect(() => {
    if (selectedAccountIds !== "all" && selectedAccountIds?.length === 1) {
      const searchAcc = currentSyncSet?.find(
        (acc) =>
          acc.account_id?.toLowerCase() ===
          [...selectedAccountIds.values()][0].toLowerCase()
      );
      setSearchedAccount(searchAcc);
    }
  }, [selectedAccountIds]);

  const setSidePaneDetails = (data, title, labels) => {
    setSidePaneLabels(labels);
    setSidePaneData(data);
    setSidePaneTitle(title);
    setIsSidePaneOpen(!isSidePaneOpen);
  };

  const renderCell = (colKey, row) => {
    if (colKey === "account_id")
      return (
        <Flex gap="size-125">
          <V3Link
            isQuiet
            onPress={() => {
              setSidePaneDetails(
                row,
                row.account_name,
                EAMSourceSystemAccountLabel()
              );
            }}
            alignSelf={row?.syncSource ? "center" : "normal"}
          >
            {row[colKey]}
          </V3Link>
          {row?.syncSource && (
            <Badge
              variant="seafoam"
              alignSelf="center"
              UNSAFE_style={{ fontSize: "x-small" }}
            >
              Original source
            </Badge>
          )}
        </Flex>
      );

    if (colKey === "address_key") {
      const address = `${row.address[0].street ? row.address[0].street : ""} ${
        row.address[0].street_sup ? row.address[0].street_sup : ""
      } ${row.address[0].city ? row.address[0].city : ""}, ${
        row.address[0].state ? row.address[0].state : ""
      } ${row.address[0].country ? row.address[0].country : ""} ${
        row.address[0].postalcode ? row.address[0].postalcode : ""
      }`;
      return <Text>{address}</Text>;
    }

    return <Text>{row[colKey]}</Text>;
  };

  const handleRemapExternalAccount = () => {
    const selectedAccountForRemap = currentSyncSet.find(
      (acc) => acc.id === [...selectedAccountIds.values()][0]
    );
    history.push(RemapExternalAccountPath, {
      fromAccount: selectedAccountForRemap,
      searchedAcc: searchedAccount,
      currentSyncSet,
      originalSourceAccount,
    });
  };

  const handleSplitExternalAccountMapping = () => {
    const newSyncSet = currentSyncSet.filter((acc) =>
      [...selectedAccountIds.values()].includes(acc.id)
    );
    const survivingSyncSet = currentSyncSet.filter(
      (acc) => ![...selectedAccountIds.values()].includes(acc.id)
    );
    history.push(SplitExternalAccountMappingsPath, {
      newSyncSet,
      survivingSyncSet,
      currentSyncSet,
      searchedAcc: searchedAccount,
    });
  };

  const handleCompleteSync = () => {
    history.push(
      generatePath(CompleteSyncSetPath, {
        originalSourceAccountId: originalSourceAccount?.account_id,
        originalSourceSystem: originalSourceAccount?.source_system,
      }),
      {
        originalSourceAccount,
        syncSet: currentSyncSet,
        searchedAcc: searchedAccount,
        subscriberSourceSystems,
      }
    );
  };

  return (
    <Flex direction="row" gap="size-250">
      <LoadingDialog isOpen={isPageLoading} />
      <Flex direction="column" gap="size-125" width="70%">
        <Flex justifyContent="space-between" margin="size-300">
          <Flex gap="size-150" wrap>
            <Picker
              label="Choose type of account"
              items={accountTypeOptions}
              selectedKey={filters.typeOfAccount}
              onSelectionChange={(selected) => {
                setFilters((prev) => ({ ...prev, typeOfAccount: selected }));
              }}
            >
              {(item) => <Item key={item.id}>{item.name}</Item>}
            </Picker>
            <SearchField
              value={filters.id}
              onChange={(value) => {
                setFilters((prev) => ({ ...prev, id: value }));
              }}
              label="Search by account id"
              onSubmit={fetchAccounts}
            />
            <Picker
              label="Choose source system"
              items={sourceSystems}
              selectedKey={filters.sourceSystem}
              onSelectionChange={(selected) => {
                setFilters((prev) => ({ ...prev, sourceSystem: selected }));
              }}
            >
              {(item) => <Item key={item.id}>{item.name}</Item>}
            </Picker>
          </Flex>
          {originalSourceAccount && (
            <Flex alignItems="end">
              <View>
                <Badge
                  variant="seafoam"
                  UNSAFE_style={{ fontSize: "small" }}
                >{`CAM Id: ${originalSourceAccount?.cam_id}`}</Badge>
              </View>
            </Flex>
          )}
        </Flex>
        {currentSyncSet && (
          <Flex direction="column" gap="size-150" margin="size-300">
            {isEamActionAllowed && (
              <Flex justifyContent="right" gap="size-125">
                {isIncomplete && currentSyncSet.length > 0 ? (
                  <Button variant="accent" onPress={handleCompleteSync}>
                    <Channel aria-label="Complete sync" />
                    <Text>Complete sync set</Text>
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="accent"
                      onPress={handleSplitExternalAccountMapping}
                      isDisabled={
                        selectedAccountIds?.size <= 1 ||
                        selectedAccountIds?.size > currentSyncSet.length - 1 ||
                        selectedAccountIds === "all"
                      }
                    >
                      <Unmerge aria-label="Split" />
                      <Text>Split external account mapping</Text>
                    </Button>
                    <Button
                      variant="accent"
                      onPress={handleRemapExternalAccount}
                      isDisabled={
                        selectedAccountIds?.size > 1 ||
                        selectedAccountIds?.size < 1 ||
                        selectedAccountIds === "all"
                      }
                    >
                      <PlatformDataMapping aria-label="Re-map" />
                      <Text>Re-map external account</Text>
                    </Button>
                  </>
                )}
              </Flex>
            )}
            <Flex
              UNSAFE_style={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
              }}
              UNSAFE_className="stepper-box"
              height={
                currentSyncSet?.length > 0 ? "fit-content" : "static-size-4600"
              }
            >
              <Table
                columns={eamColumns}
                rows={currentSyncSet}
                renderCell={renderCell}
                selectionMode={
                  isEamActionAllowed && !isIncomplete ? "multiple" : "none"
                }
                onSelectionChange={setSelectedAccountIds}
                selectedKeys={selectedAccountIds}
              />
            </Flex>
          </Flex>
        )}
        {isSidePaneOpen && (
          <CamSlidingPane
            isPaneOpenFlag={isSidePaneOpen}
            handleOpenDialog={() => setIsSidePaneOpen(false)}
            paneTitle={sidePaneTitle}
            Component={
              <AccountMappingForm data={sidePaneData} labels={sidePaneLabels} />
            }
          />
        )}
      </Flex>
      {searchedAccount && (
        <Flex
          direction="column"
          gap="size-125"
          marginTop="size-300"
          width="20%"
        >
          <Flex gap="size-125" direction="column">
            <View>
              <Heading>Searched Account</Heading>
            </View>
            <View
              height="fit-content"
              UNSAFE_className="remap-card-item stepper-box"
              borderRadius="medium"
              borderWidth="thin"
              borderColor="gray-300"
              padding="size-250"
            >
              <StepCard account={searchedAccount} />
            </View>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

import React from "react";
import { Header, View } from "@adobe/react-spectrum";
import PropTypes from "prop-types";

const DEFAULT_NAME = "";

const DisplayName = ({ name }) => {
  const fullname = name.split(" ");
  return (
    <View>
      <Header>{`Welcome, ${fullname[0] || DEFAULT_NAME}`}</Header>
    </View>
  );
};

DisplayName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DisplayName;

import {
  Button,
  Checkbox,
  CheckboxGroup,
  ComboBox,
  Content,
  ContextualHelp,
  Flex,
  Heading,
  Icon,
  Item,
  LabeledValue,
  Text,
  TextArea,
  TextField,
  useAsyncList,
  View,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { Item as TagGroupItem, TagGroup } from "@react-spectrum/tag";
import React, { useEffect, useState } from "react";
import Avatar from "@react/react-spectrum/Avatar";
import { error, success } from "@react/react-spectrum/Toast";
import { useHistory } from "react-router-dom";
import { groupsApi } from "../../../api/groupsApi";
import { CamFunctions } from "../../../constants/CamFunctions";
import { CREATE } from "../../../constants/GroupRequestType";
import { ADMIN } from "../../../constants/RoleType";
import { CreateAndSubscribeGroupsPath } from "../../../constants/Routes";
import useGroups from "../../../context/groups-context";
import useUserProfile from "../../../context/user-context";
import LoadingDialog from "../../Dialog/LoadingDialog";

export default function CreateGroupForm() {
  const history = useHistory();
  const { user } = useUserProfile();
  const camFunctionsList = CamFunctions();
  const {
    groupDetails,
    groupValidState,
    createGroupDetails,
    resetGroupDetails,
    createGroupValidState,
    resetGroupValidState,
  } = useGroups();

  const { authState } = useOktaAuth();
  const [isPageLoading, setPageLoading] = useState(false);
  const [tagMembers, setTagMembers] = useState([]);

  const list = useAsyncList({
    initialFilterText: "",
    async load({ filterText }) {
      const json = await groupsApi.fetchUsers(
        filterText.length !== 0 ? filterText : "a",
        authState.accessToken
      );
      return {
        items: json,
      };
    },
  });

  useEffect(() => {
    if (user) {
      createGroupDetails({
        ...groupDetails,
        requestedBy: user?.email,
        requesterName: user?.name,
        members: [
          { name: user?.name, email: user?.email, userId: user?.userId },
        ],
      });
      if (groupDetails?.members?.length > 0) {
        setTagMembers((prev) => [...prev, ...groupDetails?.members]);
      } else {
        setTagMembers((prev) => [
          ...prev,
          { name: user?.name, email: user?.email, userId: user?.userId },
        ]);
      }
    }
  }, [user]);

  useEffect(() => {
    if (groupDetails?.members?.length > 0)
      createGroupDetails({
        ...groupDetails,
        members: [...groupDetails?.members],
      });
  }, []);

  const validateGroupName = (value) => {
    let validatedName = { state: "valid", message: "" };
    if (value.length === 0) {
      validatedName = { state: "invalid", message: "Empty input not allowed." };
    } else if (!/^(?!.*[-]{2})(?!.*-$)grp-+[a-z\d-]*$/i.test(value)) {
      validatedName = {
        state: "invalid",
        message:
          "Give a name starting with ' GRP- ' and name should only have ' - ' as non ending special character.",
      };
    }
    createGroupValidState({
      ...groupValidState,
      name: validatedName,
    });
  };

  const validateBusinessReason = (value) => {
    let validatedReason = { state: "valid", message: "" };
    if (value?.length === 0) {
      validatedReason = {
        state: "invalid",
        message: "Business reason must not be empty.",
      };
    }
    createGroupValidState({
      ...groupValidState,
      reason: validatedReason,
    });
  };

  const validateCamFunctions = (values) => {
    let validatedFunctions = { state: "valid", message: "" };
    if (values?.length === 0) {
      validatedFunctions = {
        state: "invalid",
        message: "Select atleast one CAM function to access",
      };
    }
    createGroupValidState({
      ...groupValidState,
      functions: validatedFunctions,
    });
  };

  const validateMembers = (values) => {
    let validatedMembers = { state: "valid", message: "" };
    if (values?.length < 2) {
      validatedMembers = {
        state: "invalid",
        message: "Atleast 2 admin should be in a group.",
      };
    }
    createGroupValidState({
      ...groupValidState,
      members: validatedMembers,
    });
  };

  useEffect(() => {
    if (tagMembers.length > 1) {
      validateMembers(tagMembers);
    }
    createGroupDetails({
      ...groupDetails,
      requestedBy: user?.email,
      requesterName: user?.name,
      members: [...tagMembers],
    });
  }, [tagMembers]);

  const validateCreateGroupForm = () => {
    const formInputs = Object.keys(groupValidState);
    let isValid = true;
    formInputs.forEach((input) => {
      if (groupValidState[input]?.state?.length === 0) {
        isValid = false;
      } else if (groupValidState[input]?.state === "invalid") {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleSave = () => {
    setPageLoading(true);
    const {
      groupName,
      members,
      camFunctions,
      reason,
      requestedBy,
      requesterName,
    } = groupDetails;

    const newMembers = members?.map((member) => ({ ...member, role: ADMIN }));

    const groupRequest = {
      requestedBy,
      requesterName,
      typeOfRequest: CREATE,
      requestedGroup: groupName,
      requestGroupPayload: {
        groupName,
        groupMembers: newMembers,
        camFunctions,
        businessReason: reason,
        requestedBy,
      },
      requestedBusinessReason: reason,
    };

    groupsApi
      .createGroupRequest(groupRequest, authState.accessToken)
      .then((data) => {
        if (data?.acknowledged) {
          resetGroupDetails();
          resetGroupValidState();
          success(
            `${groupDetails?.groupName} create request has been submitted successfully!`,
            {
              timeout: 5000,
            }
          );
          setPageLoading(false);
          history.push(CreateAndSubscribeGroupsPath);
        }
      })
      .catch((e) => {
        setPageLoading(false);
        if (e.toString().toLowerCase().includes("network"))
          error(`Server not reachable! Please contact CAM team!`, {
            timeout: 5000,
          });
        else if (e?.response?.data) {
          error(e?.response?.data?.message, {
            timeout: 5000,
          });
        } else {
          error(`Sorry something went wrong! Please contact CAM team!`, {
            timeout: 5000,
          });
        }
      });
  };

  const handleBack = () => {
    history.push(CreateAndSubscribeGroupsPath);
  };

  return (
    <Flex direction="column" gap="size-150" margin="size-300" width="60%">
      <LoadingDialog isOpen={isPageLoading} />
      <Heading>Create Group</Heading>
      <View
        borderWidth="thin"
        borderColor="dark"
        borderRadius="medium"
        padding="size-250"
        UNSAFE_className="stepper-box"
      >
        <Flex direction="column" margin="size-300" gap="size-500">
          <Flex>
            <View flex="70%">
              <LabeledValue
                label="Requester"
                value={`${user?.name} (${user?.email})`}
              />
            </View>
            <View flex="30%">
              <LabeledValue label="Members role" value="Admin" />
            </View>
          </Flex>
          <Flex>
            <View flex="70%">
              <TextField
                validationState={groupValidState?.name?.state}
                description="Name should start with 'GRP-' and can only have '-' as special charcter"
                errorMessage={groupValidState?.name?.message}
                isRequired
                label="Group name"
                value={groupDetails?.groupName}
                onChange={(value) => {
                  value = value.toUpperCase(); // eslint-disable-line no-param-reassign
                  validateGroupName(value);
                  createGroupDetails({ ...groupDetails, groupName: value });
                }}
                onFocus={() => {
                  if (groupDetails.groupName.length > 0)
                    validateGroupName(groupDetails?.groupName);
                }}
              />
            </View>
            <View flex="30%">
              <TextArea
                validationState={groupValidState?.reason?.state}
                errorMessage={groupValidState?.reason?.message}
                isRequired
                label="Business reason"
                value={groupDetails?.reason}
                onChange={(value) => {
                  validateBusinessReason(value);
                  createGroupDetails({ ...groupDetails, reason: value });
                }}
                contextualHelp={
                  <ContextualHelp variant="info">
                    <Heading>Tips</Heading>
                    <Content>
                      Kindly provide business justification for creating this
                      group
                    </Content>
                  </ContextualHelp>
                }
              />
            </View>
          </Flex>
          <Flex>
            <View flex="70%">
              <Flex direction="column">
                <ComboBox
                  label="Members"
                  isRequired
                  necessityIndicator="icon"
                  description="Add admins to the group. Atleast 2 admin members required"
                  errorMessage={groupValidState?.members?.message}
                  validationState={groupValidState?.members?.state}
                  items={list.items}
                  inputValue={list.filterText}
                  onInputChange={list.setFilterText}
                  loadingState={list.loadingState}
                  onSelectionChange={(key) => {
                    setTagMembers((prev) => {
                      if (prev.find((prevItem) => prevItem?.email === key))
                        return [...prev];

                      return [
                        ...prev,
                        list.items.find((item) => item?.email === key),
                      ];
                    });
                  }}
                >
                  {(item) => (
                    <Item key={item.email}>
                      <Icon
                        UNSAFE_style={{
                          width: "2rem",
                          height: "2rem",
                          // borderRadius: "50%",
                        }}
                        justifySelf="center"
                        alignSelf="center"
                      >
                        <Avatar
                          src={`https://s7d2.scene7.com/is/image/IMGDIR/${item?.userId}`}
                          alt="User"
                        />
                      </Icon>
                      <Text>{item.name}</Text>
                      <Text slot="description">{item?.email}</Text>
                    </Item>
                  )}
                </ComboBox>
                {tagMembers?.length > 0 && (
                  <View
                    width="15rem"
                    marginTop="size-150"
                    borderWidth="thin"
                    borderColor="dark"
                    borderRadius="medium"
                    padding="size-250"
                    backgroundColor="static-white"
                  >
                    <Flex direction="column">
                      <TagGroup
                        items={tagMembers}
                        aria-label="Dynamic TagGroup items example"
                        allowsRemoving
                        onRemove={(key) =>
                          setTagMembers((prev) => {
                            validateMembers(
                              prev.filter((prevItem) => prevItem?.email !== key)
                            );
                            if (prev.length === 1) {
                              return [...prev];
                            }
                            return [
                              ...prev.filter(
                                (prevItem) => prevItem?.email !== key
                              ),
                            ];
                          })
                        }
                      >
                        {(tagMember) => (
                          <TagGroupItem key={tagMember?.email}>
                            <Icon justifySelf="center" alignSelf="center">
                              <Avatar
                                style={{ width: "2rem", height: "2rem" }}
                                src={`https://s7d2.scene7.com/is/image/IMGDIR/${tagMember?.userId}`}
                                alt="User"
                              />
                            </Icon>
                            <Text>{tagMember?.name}</Text>
                          </TagGroupItem>
                        )}
                      </TagGroup>
                    </Flex>
                  </View>
                )}
              </Flex>
            </View>
            <View flex="30%">
              <CheckboxGroup
                label="CAM functions to access"
                validationState={groupValidState?.functions?.state}
                errorMessage={groupValidState?.functions?.message}
                value={groupDetails?.camFunctions}
                onChange={(values) => {
                  validateCamFunctions(values);
                  createGroupDetails({ ...groupDetails, camFunctions: values });
                }}
                isRequired
              >
                {camFunctionsList.map((functionItem) => (
                  <Checkbox key={functionItem?.id} value={functionItem?.id}>
                    {functionItem?.label}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </View>
          </Flex>
          <Flex justifyContent="space-between">
            <Flex gap="size-200">
              <Button variant="primary" onPress={handleBack}>
                Cancel
              </Button>
              <Button
                variant="accent"
                onPress={handleSave}
                isDisabled={!validateCreateGroupForm()}
                UNSAFE_style={
                  !validateCreateGroupForm()
                    ? { backgroundColor: "#E2E2E2" }
                    : {}
                }
              >
                Submit
              </Button>
            </Flex>
            {groupDetails?.groupName && (
              <Flex>
                <Button
                  variant="primary"
                  onPress={() => {
                    resetGroupDetails();
                    setTagMembers([
                      {
                        name: user?.name,
                        email: user?.email,
                        userId: user?.userId,
                      },
                    ]);
                    resetGroupValidState();
                  }}
                >
                  Reset
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
      </View>
    </Flex>
  );
}

import { useState, useEffect } from "react";
import useUserProfile from "../context/user-context";

/**
 * returns true or false depending on if the user is a member of a group with reqFunction and is of role reqRoles in that function
 */
const useValidUserAccess = (reqRoles, reqFunction) => {
  const [isValidUserAccess, setValidUserAccess] = useState(false);
  const { user } = useUserProfile();

  useEffect(() => {
    let isValid = false;
    user?.userGroups.forEach((group) => {
      if (
        reqRoles.includes(group.role) &&
        group.functions.includes(reqFunction)
      ) {
        isValid = true;
      }
    });
    setValidUserAccess(isValid);
  }, [user]);

  return isValidUserAccess;
};

export default useValidUserAccess;

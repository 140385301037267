import { Badge, Flex, Link as V3Link, Text, View } from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Table from "../../../../../components/Common/Table";
import { EAMColumns } from "../../../../../constants/Columns";
import { EAMSourceSystemAccountLabel } from "../../../../../constants/SlidingPaneLabels";

export default function Step1({
  activeStep,
  syncSet,
  originalSourceAccount,
  setSidePaneDetails,
  selectedKeys,
  setSelectedKeys,
}) {
  const [disabledKeys, setDisabledKeys] = useState();
  const eamColumns = EAMColumns();

  useEffect(() => {
    if (originalSourceAccount) {
      setDisabledKeys(
        new Set([
          ...syncSet
            .filter(
              (acc) =>
                acc?.id === originalSourceAccount?.id || acc?.isNewAccount
            )
            .map((acc) => acc?.id),
        ])
      );
    }
  }, [originalSourceAccount, syncSet]);

  useEffect(() => {
    if (disabledKeys?.size > 0) {
      syncSet
        .filter(
          (acc) => acc?.id === originalSourceAccount?.id || acc?.isNewAccount
        )
        .map((acc) => acc?.id);
    }
  }, [disabledKeys]);

  const renderCell = (colKey, row) => {
    let cellStyle = {};
    if (colKey === "account_id" && !row?.isNewAccount)
      return (
        <Flex gap="size-125">
          <V3Link
            isQuiet
            onPress={() => {
              setSidePaneDetails(
                row,
                row.account_name,
                EAMSourceSystemAccountLabel()
              );
            }}
            alignSelf={row?.syncSource ? "center" : "normal"}
          >
            {row[colKey]}
          </V3Link>
          {row?.syncSource && (
            <Badge
              variant="seafoam"
              alignSelf="center"
              UNSAFE_style={{ fontSize: "x-small" }}
            >
              Original source
            </Badge>
          )}
        </Flex>
      );

    if (row?.isNewAccount) {
      cellStyle = { color: "green" };
    }

    if (colKey === "address_key") {
      const address = `${row.address[0].street ? row.address[0].street : ""} ${
        row.address[0].street_sup ? row.address[0].street_sup : ""
      } ${row.address[0].city ? row.address[0].city : ""}, ${
        row.address[0].state ? row.address[0].state : ""
      } ${row.address[0].country ? row.address[0].country : ""} ${
        row.address[0].postalcode ? row.address[0].postalcode : ""
      }`;
      return <Text UNSAFE_style={cellStyle}>{address}</Text>;
    }

    return <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };

  return (
    <View
      UNSAFE_className={`card ${activeStep === 1 ? "active" : "hide"}`}
      key="step1"
      overflow="auto"
    >
      <Flex
        direction="row"
        gap="size-125"
        marginTop="size-300"
        height={syncSet?.length === 0 && "size-3600"}
        UNSAFE_style={{
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
        UNSAFE_className="stepper-box"
      >
        <Table
          columns={eamColumns}
          rows={syncSet}
          renderCell={renderCell}
          selectionMode="multiple"
          selectedKeys={selectedKeys}
          onSelectionChange={setSelectedKeys}
          disabledKeys={disabledKeys}
        />
      </Flex>
    </View>
  );
}

Step1.propTypes = {
  activeStep: PropTypes.number.isRequired,
  syncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  originalSourceAccount: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
    ])
  ).isRequired,
  setSidePaneDetails: PropTypes.func.isRequired,
  selectedKeys: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string]))
    ),
    PropTypes.any,
  ]).isRequired,
  setSelectedKeys: PropTypes.func.isRequired,
};

import { Flex, View, Heading, Text } from "@adobe/react-spectrum";
import React from "react";
import PropTypes from "prop-types";
import Table from "../../../../components/Common/Table";

export default function Step2({
  activeStep,
  overrideAttributes,
  changedAttributes,
  overrideGroup,
  overrideGroupColumns,
  groupDescription,
}) {
  const origAttributes = { ...overrideAttributes, isNewView: false };
  const overridenAttributes = {
    ...overrideAttributes,
    ...changedAttributes,
    isNewView: true,
  };

  const renderCell = (colKey, row) => {
    if (Object.keys(overrideAttributes).includes(colKey)) {
      if (changedAttributes && Object.keys(changedAttributes).includes(colKey))
        return (
          <Flex direction="row" alignItems="center">
            <Text UNSAFE_style={{ color: row.isNewView ? "green" : "red" }}>
              {row[colKey]}
            </Text>
          </Flex>
        );
      return <Text>{row[colKey]}</Text>;
    }
    if (colKey === "group_description")
      return (
        <Text UNSAFE_style={{ color: "darkgrey" }}>{groupDescription}</Text>
      );
    return <Text UNSAFE_style={{ color: "darkgrey" }}>{row[colKey]}</Text>;
  };

  return (
    <View
      UNSAFE_className={`card ${activeStep === 2 ? "active" : "hide"}`}
      key="step3"
      overflow="auto"
    >
      <Flex direction="column" gap="size-250">
        <Heading
          level={4}
          marginTop="size-300"
          alignSelf="center"
          UNSAFE_style={{ color: "#1da973" }}
        >
          Override for {overrideGroup.group_key} Successful
        </Heading>
        <Flex direction="column" width="100%">
          <Heading level={4}>Original Group Values</Heading>
          <Flex
            direction="row"
            gap="size-125"
            marginBottom="size-300"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={overrideGroupColumns.filter(
                (item) => item.uid !== "lag_id"
              )}
              rows={[{ ...overrideGroup, ...origAttributes }]}
              selectionMode="none"
              renderCell={renderCell}
              density="compact"
            />
          </Flex>
          <Heading level={4}>New Group Values</Heading>
          <Flex
            direction="row"
            gap="size-125"
            marginBottom="size-300"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={overrideGroupColumns.filter(
                (item) => item.uid !== "lag_id"
              )}
              rows={[{ ...overrideGroup, ...overridenAttributes }]}
              selectionMode="none"
              renderCell={renderCell}
              density="compact"
            />
          </Flex>
        </Flex>
      </Flex>
    </View>
  );
}

Step2.propTypes = {
  activeStep: PropTypes.number.isRequired,
  overrideGroup: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    ])
  ).isRequired,
  changedAttributes: PropTypes.objectOf(PropTypes.string).isRequired,
  overrideAttributes: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    ])
  ).isRequired,
  overrideGroupColumns: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ).isRequired,
  groupDescription: PropTypes.string.isRequired,
};

import { ActionButton, Flex, Text, View } from "@adobe/react-spectrum";
import Bell from "@spectrum-icons/workflow/Bell";
import React, { useState } from "react";
import useUserProfile from "../../../context/user-context";
import DisplayName from "./components/DisplayName";
import InfoDropDown from "./components/InfoDropDown";
import "./user-info.css";

const UserInfo = () => {
  const { user } = useUserProfile();

  const [notificationCount, setNotificationCount] = useState(0);

  return (
    <View>
      {user && (
        <Flex justifyContent="space-between" alignItems="center" gap="1rem">
          <ActionButton
            isQuiet
            staticColor="white"
            UNSAFE_className="icon-button"
            onPress={() => setNotificationCount(0)}
          >
            <View>
              <Bell size="S" />
              {notificationCount !== 0 && (
                <Text UNSAFE_className="icon-button__badge">
                  {notificationCount}
                </Text>
              )}
            </View>
          </ActionButton>
          <Flex alignItems="center" gap="size-100">
            <DisplayName name={user.name} />
            <InfoDropDown />
          </Flex>
        </Flex>
      )}
    </View>
  );
};

export default UserInfo;

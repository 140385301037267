import {
  AlertDialog,
  DialogContainer,
  Flex,
  Text,
  TextArea,
} from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { ReceivedGroupRequestsPath } from "../../constants/Routes";

export default function ApproveDialog({
  openDialog,
  setOpen,
  selectedRequest,
  handleApproval,
}) {
  const [requestPayload, setRequestPayload] = useState({
    status: "",
    statusReason: "",
    approverEmail: "",
    approverName: "",
  });

  const history = useHistory();

  useEffect(() => {
    setRequestPayload((prev) => ({
      ...prev,
      status: selectedRequest?.status,
      approverEmail: selectedRequest?.approverEmail,
      approverName: selectedRequest?.approverName,
    }));
  }, [selectedRequest]);

  return (
    <DialogContainer onDismiss={setOpen}>
      {openDialog && (
        <AlertDialog
          title="Reject request"
          variant="destructive"
          primaryActionLabel="Yes"
          cancelLabel="No"
          onPrimaryAction={() =>
            handleApproval(selectedRequest?.requestId, requestPayload)
          }
          onCancel={() => {
            history.push(ReceivedGroupRequestsPath);
          }}
        >
          <Flex direction="column" gap="size-150">
            <Text>
              Are you sure you want to reject this request? Please confirm by
              providing reason for rejecting the request.
            </Text>
            <TextArea
              label="Reason"
              value={requestPayload?.statusReason}
              onChange={(value) =>
                setRequestPayload((prev) => ({ ...prev, statusReason: value }))
              }
            />
          </Flex>
        </AlertDialog>
      )}
    </DialogContainer>
  );
}

ApproveDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedRequest: PropTypes.objectOf(PropTypes.oneOfType(PropTypes.string))
    .isRequired,
  handleApproval: PropTypes.func.isRequired,
};

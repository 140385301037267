export const ADD_USER_PROFILE = "ADD_USER_PROFILE";
export const REMOVE_USER_PROFILE = "REMOVE_USER_PROFILE";
export const ADD_BREADCRUMBS_LINK = "ADD_BREADCRUMBS_LINK";
export const RESET_BREADCRUMBS_LINK = "RESET_BREADCRUMBS_LINK";
export const SET_IS_BREADCRUMBS_LINK = "SET_IS_BREADCRUMBS_LINK";
export const CREATE_GROUP_DETAILS = "CREATE_GROUP_DETAILS";
export const RESET_GROUP_DETAILS = "RESET_GROUP_DETAILS";
export const CREATE_GROUP_VALID_STATE = "CREATE_GROUP_VALID_STATE";
export const RESET_GROUP_VALID_STATE = "RESET_GROUP_VALID_STAT";
export const ADD_VALID_COUNTRY_STATE_ISOCODE =
  "ADD_VALID_COUNTRY_STATE_ISOCODE";
export const RESET_VALID_COUNTRY_STATE_ISOCODE =
  "RESET_VALID_COUNTRY_STATE_ISOCODE";

import { Badge, Flex, Heading, Text, View } from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import React from "react";
import Table from "../../../../../components/Common/Table";
import { EAMConfirmChangesColumns } from "../../../../../constants/Columns";

export default function Step2({ activeStep, currentMapping, newMapping }) {
  const confirmChangesColumns = EAMConfirmChangesColumns();

  const renderCell = (colKey, row) => {
    let cellStyle = {};

    if (row?.status && row?.status === "removed") {
      cellStyle = { color: "red" };
    }

    if (row?.status && row?.status === "added") {
      cellStyle = { color: "green" };
    }

    if (colKey === "address_key") {
      const address = `${row.address[0].street ? row.address[0].street : ""} ${
        row.address[0].street_sup ? row.address[0].street_sup : ""
      } ${row.address[0].city ? row.address[0].city : ""}, ${
        row.address[0].state ? row.address[0].state : ""
      } ${row.address[0].country ? row.address[0].country : ""} ${
        row.address[0].postalcode ? row.address[0].postalcode : ""
      }`;
      return <Text UNSAFE_style={cellStyle}>{address}</Text>;
    }

    if (colKey === "account_id" && row?.syncSource)
      return (
        <Flex gap="size-125">
          <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
          <Badge
            variant="seafoam"
            alignSelf="center"
            UNSAFE_style={{ fontSize: "x-small" }}
          >
            Original source
          </Badge>
        </Flex>
      );

    return <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };

  return (
    <View
      UNSAFE_className={`card ${activeStep === 2 ? "active" : "hide"}`}
      key="step2"
    >
      <Flex marginTop="size-300" justifyContent="space-between" gap="size-125">
        <View width="50%">
          <Flex justifyContent="center">
            <View
              backgroundColor="static-white"
              borderRadius="medium"
              borderWidth="thin"
              borderColor="gray-300"
              padding="size-125"
              width="size-2000"
              UNSAFE_className="stepper-box"
            >
              <Flex justifyContent="center">
                <Heading>Current Mapping</Heading>
              </Flex>
            </View>
          </Flex>

          <Flex
            direction="row"
            gap="size-125"
            marginTop="size-300"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={confirmChangesColumns}
              rows={currentMapping}
              renderCell={renderCell}
              density="compact"
            />
          </Flex>
        </View>
        <View width="50%">
          <Flex justifyContent="center">
            <View
              backgroundColor="static-white"
              borderRadius="medium"
              borderWidth="thin"
              borderColor="gray-300"
              padding="size-125"
              width="size-2000"
              UNSAFE_className="stepper-box"
            >
              <Flex justifyContent="center">
                <Heading>New Mapping</Heading>
              </Flex>
            </View>
          </Flex>

          <Flex
            direction="row"
            gap="size-125"
            marginTop="size-300"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={confirmChangesColumns}
              rows={newMapping}
              renderCell={renderCell}
              density="compact"
            />
          </Flex>
        </View>
      </Flex>
    </View>
  );
}

Step2.propTypes = {
  activeStep: PropTypes.number.isRequired,
  currentMapping: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  newMapping: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
};

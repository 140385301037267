import {
  Flex,
  View,
  Text,
  TooltipTrigger,
  Tooltip,
  ActionButton,
  Heading,
} from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Edit from "@spectrum-icons/workflow/Edit";
import { error } from "@react/react-spectrum/Toast";
import { useOktaAuth } from "@okta/okta-react";
import Table from "../../../../components/Common/Table";
import CamSlidingPane from "../../../../components/Common/CamSlidingPane/CamSlidingPane";
import EditGroupFieldForm from "../../../../components/Forms/LAG/EditGroupFieldForm";
import { LAGCreateRule, LAGPreviewRule } from "../../../../constants/Columns";
import { lagApi } from "../../../../api/lagApi";

export default function Step1({
  activeStep,
  extendGroupId,
  searchFilter,
  setNewLagRule,
}) {
  const createRuleColumns = LAGCreateRule();
  const constantPreviewRuleColumnUids = LAGPreviewRule().map((x) => x.uid);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [groupableFields, setGroupableFields] = useState([]);
  const [ruleInfo, setRuleInfo] = useState([]);
  const [previewRuleData, setPreviewRuleData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [disabledKeys, setDisabledKeys] = useState([]);
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [sidePaneData, setSidePaneData] = useState([]);
  const [extendGroupInfo, setExtendGroupInfo] = useState();
  const [loadingState, setLoadingState] = useState("");
  const { authState } = useOktaAuth();

  const handleError = (e) => {
    setLoadingState("");
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  const handleExtendGroup = () => {
    lagApi
      .fetchGroupInfo({ group_key: extendGroupId }, authState.accessToken)
      .then((data) => {
        setExtendGroupInfo(data);
      })
      .catch((e) => handleError(e));
  };

  // handles groupable fields and pre-selected keys based on user input in lookup
  useEffect(() => {
    const preSelectedKeys = [];
    lagApi.fetchGroupableFields(authState.accessToken).then((data) => {
      const groupableFieldsWithSearchValue = data.attributes.map((field) => {
        if (searchFilter.domain !== "" && field.field_name === "domain") {
          preSelectedKeys.push(
            data.attributes.find((attr) => attr.field_name === "domain").id
          );
          return { ...field, value: searchFilter.domain };
        }
        if (searchFilter.country !== "" && field.field_name === "country") {
          preSelectedKeys.push(
            data.attributes.find((attr) => attr.field_name === "country").id
          );
          return { ...field, value: searchFilter.country };
        }
        return field;
      });
      setSelectedKeys(preSelectedKeys);
      setGroupableFields(groupableFieldsWithSearchValue);
      if (!extendGroupId) {
        setRuleInfo(
          groupableFieldsWithSearchValue.map((attr) =>
            Object.assign(attr, {
              ...attr,
              selected: preSelectedKeys.includes(attr.id),
            })
          )
        );
        setNewLagRule(
          groupableFieldsWithSearchValue.filter((attr) => attr.value)
        );
      }
    });
    if (extendGroupId) handleExtendGroup();
  }, []);

  // handles preview rule data table
  useEffect(() => {
    lagApi
      .fetchPreviewGroupData(
        {
          attributes: ruleInfo.map((attr) =>
            Object.assign(attr, { ...attr, search_keyword: attr.value })
          ),
          result_size: 100,
          from: 0,
        },
        authState.accessToken
      )
      .then((data) => {
        setLoadingState("loading");
        setPreviewRuleData(data.results);
        if (data.results.length > 0) {
          const [previewDataRecord] = data.results;
          const updatedDynamicColumns = [];
          Object.keys(previewDataRecord?.attributes).forEach((attr) => {
            updatedDynamicColumns.push({
              uid: attr,
              name:
                attr.charAt(0).toUpperCase() + attr.slice(1).replace("_", " "),
            });
          });
          setDynamicColumns(updatedDynamicColumns);
        }
        setLoadingState("");
      })
      .catch((e) => handleError(e));
  }, [ruleInfo]);

  const getExtendedGroupAttributes = () =>
    groupableFields
      .filter((field) =>
        extendGroupInfo.attributes.some(
          (groupField) => field.field_name === groupField.field_name
        )
      )
      .map((extendedField) => extendedField.id);

  useEffect(() => {
    if (extendGroupId) {
      setDisabledKeys(getExtendedGroupAttributes());
      setSelectedKeys(
        new Set([...selectedKeys, ...getExtendedGroupAttributes()])
      );
      groupableFields.map((attr) =>
        Object.assign(attr, {
          ...attr,
          ...extendGroupInfo?.attributes.find(
            (extAttr) => extAttr.field_name === attr.field_name
          ),
          id: attr.id,
        })
      );
      setRuleInfo(groupableFields);
    }
  }, [extendGroupInfo]);

  const handleOnManage = (row) => {
    setSidePaneData(row);
    setIsSidePaneOpen(true);
  };

  const renderManageMenu = (row) => (
    <TooltipTrigger>
      <ActionButton isQuiet onPress={() => handleOnManage(row)}>
        <View>
          <Edit color="informative" />
        </View>
      </ActionButton>
      <Tooltip>Modify</Tooltip>
    </TooltipTrigger>
  );

  const renderRuleCell = (colKey, row) => {
    if (colKey === "modify") {
      return renderManageMenu(row);
    }
    if (colKey === "include" || colKey === "exclude") {
      if (row[colKey]) return <Text>{row[colKey].join(", ")}</Text>;
    }
    return <Text>{row[colKey]}</Text>;
  };

  const renderPreviewDataCell = (colKey, row) => {
    if (
      !constantPreviewRuleColumnUids.includes(colKey) &&
      Object.keys(row).includes("attributes")
    ) {
      return <Text>{row.attributes[colKey].toString()}</Text>;
    }
    return <Text>{row[colKey]}</Text>;
  };

  const handleSelection = (selected) => {
    setSelectedKeys(selected);
    setRuleInfo(
      ruleInfo.map((attr) =>
        Object.assign(attr, { ...attr, selected: selected.has(attr.id) })
      )
    );
    setNewLagRule(ruleInfo.filter((attr) => attr.selected));
  };

  return (
    <View
      UNSAFE_className={`card ${activeStep === 1 ? "active" : "hide"}`}
      key="step1"
      overflow="auto"
    >
      <Flex direction="row" gap="size-250">
        <Flex direction="column" gap="size-125" width="100%">
          <Flex
            direction="row"
            gap="size-125"
            height="size-3600"
            marginTop="size-300"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={createRuleColumns}
              rows={ruleInfo}
              selectionMode="multiple"
              renderCell={renderRuleCell}
              density="compact"
              selectedKeys={selectedKeys}
              onSelectionChange={handleSelection}
              disabledKeys={disabledKeys}
            />
          </Flex>

          <Heading level={4} marginTop="size-300">
            Preview Rule (first 100 records)
          </Heading>
          <Flex
            direction="row"
            gap="size-125"
            height="size-4600"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={[...LAGPreviewRule(), ...dynamicColumns].filter(
                (v, i, a) => a.findIndex((v2) => v2.uid === v.uid) === i
              )}
              rows={previewRuleData}
              selectionMode="none"
              renderCell={renderPreviewDataCell}
              loadingState={loadingState}
              density="compact"
            />
          </Flex>
        </Flex>
        {isSidePaneOpen && (
          <CamSlidingPane
            isPaneOpenFlag={isSidePaneOpen}
            handleOpenDialog={() => setIsSidePaneOpen(false)}
            paneTitle="Edit Field Details"
            Component={
              <EditGroupFieldForm
                fieldInfo={sidePaneData}
                setRuleInfo={setRuleInfo}
                ruleInfo={ruleInfo}
                setNewLagRule={setNewLagRule}
                setIsSidePaneOpen={setIsSidePaneOpen}
                isDisabled={disabledKeys.includes(sidePaneData.id)}
              />
            }
          />
        )}
      </Flex>
    </View>
  );
}

Step1.defaultProps = {
  extendGroupId: null,
};

Step1.propTypes = {
  activeStep: PropTypes.number.isRequired,
  extendGroupId: PropTypes.string,
  searchFilter: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ).isRequired,
  setNewLagRule: PropTypes.func.isRequired,
};

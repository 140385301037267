export const EAMAccountTypeOptions = () => [
  {
    id: "crm_account_id",
    name: "CRM Source Account",
  },
  {
    id: "orig_source_system_account_id",
    name: "Original Source Account",
  },
];

export const EAMSourceSystemOptions = () => [
  {
    id: "sfdc-corp",
    name: "sfdc-corp",
  },
  {
    id: "sfdc-wkft",
    name: "sfdc-wkft",
  },
  {
    id: "d365",
    name: "d365",
  },
  {
    id: "sfdc-mkto",
    name: "sfdc-mkto",
  },
  {
    id: "sfdc-mgto",
    name: "sfdc-mgto",
  },
];

export const EAMSourceSystemAccountSearchTypeOptions = () => [
  {
    id: "account_id",
    name: "CRM Account Id",
  },
  {
    id: "account_name",
    name: "CRM Account Name",
  },
];

export const LAGSearchIdOptions = () => [
  {
    id: "lagId",
    name: "LAG ID",
  },
  {
    id: "eccId",
    name: "ECC ID",
  },
  {
    id: "camId",
    name: "CAM ID",
  },
];

export const LAGGroupStatusOptions = () => [
  {
    id: "Pending",
    label: "Pending",
  },
  {
    id: "Approved",
    label: "Approved",
  },
  {
    id: "Rejected",
    label: "Rejected",
  },
];

import { Badge, Flex, Text, View } from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import React from "react";
import Table from "../../../../../components/Common/Table";
import { EAMColumns } from "../../../../../constants/Columns";

export default function Step3({ activeStep, newMapping }) {
  const eamColumns = EAMColumns();

  const renderCell = (colKey, row) => {
    let cellStyle = {};

    if (row?.is_modified) {
      cellStyle = { color: "green" };
    }

    if (colKey === "address_key") {
      const address = `${row.address[0].street ? row.address[0].street : ""} ${
        row.address[0].street_sup ? row.address[0].street_sup : ""
      } ${row.address[0].city ? row.address[0].city : ""}, ${
        row.address[0].state ? row.address[0].state : ""
      } ${row.address[0].country ? row.address[0].country : ""} ${
        row.address[0].postalcode ? row.address[0].postalcode : ""
      }`;
      return <Text UNSAFE_style={cellStyle}>{address}</Text>;
    }

    if (colKey === "account_id") {
      return row?.syncSource ? (
        <Flex gap="size-125">
          <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
          <Badge
            variant="seafoam"
            alignSelf="center"
            UNSAFE_style={{ fontSize: "x-small" }}
          >
            Original source
          </Badge>
        </Flex>
      ) : (
        <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
      );
    }

    return <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };
  return (
    <View
      UNSAFE_className={`card ${activeStep === 3 ? "active" : "hide"}`}
      key="step3"
    >
      <Flex
        direction="row"
        gap="size-125"
        marginTop="size-300"
        UNSAFE_style={{
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
        UNSAFE_className="stepper-box"
      >
        <Table
          columns={eamColumns}
          rows={newMapping}
          renderCell={renderCell}
          density="compact"
        />
      </Flex>
    </View>
  );
}

Step3.propTypes = {
  activeStep: PropTypes.number.isRequired,
  newMapping: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
};

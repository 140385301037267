import { Flex } from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { error } from "@react/react-spectrum/Toast";
import React, { useEffect, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { groupsApi } from "../api/groupsApi";
import CustomSideNav from "../components/CustomSideNav/CustomSideNav";
import LoadingDialog from "../components/Dialog/LoadingDialog";
import { BreadcrumbsRoutes } from "../constants/MainNavigationList";
import { AppLogoutPath, RootPath } from "../constants/Routes";
import useUserProfile from "../context/user-context";
import InactivityTimer from "../utility/InactivityTimer";
import RoutePages from "./RoutePages";

export default function BodyContainer() {
  const [sideNav, setSideNav] = useState([]);

  const location = useLocation();
  const breadcrumbRoutes = BreadcrumbsRoutes();

  const { user, addUserProfile, removeUserProfile } = useUserProfile();
  const { authState, oktaAuth } = useOktaAuth();
  const history = useHistory();

  const [isPageLoading, setPageLoading] = useState(true);

  const logout = () => {
    oktaAuth.tokenManager.clear();
    sessionStorage.removeItem("ims-token-storage");
    history.push(AppLogoutPath, { isLoggedOut: true });
  };

  useEffect(() => {
    if (user?.email || history.location.pathname === AppLogoutPath) {
      setPageLoading(false);
    }

    if (authState.isAuthenticated) {
      const timer = new InactivityTimer({
        timeout: window.apcConfig.cam.REACT_APP_IDLE_TIME,
        onTimeout: () => {
          logout();
        },
        onExpired: () => {
          logout();
        },
      });

      return () => {
        timer.cleanUp();
      };
    }
    return undefined;
  }, [user]);

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 0,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 0,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 0,
      });
    }
  };

  useEffect(() => {
    if (!authState.isAuthenticated) {
      removeUserProfile();
    } else {
      oktaAuth.getUser().then((info) => {
        groupsApi
          .fetchUserInfo(info.userid, authState.accessToken)
          .then((userInfo) => {
            addUserProfile({
              name: info.name,
              email: info.email,
              userId: info.userid,
              camFunctions: userInfo?.camFunctions,
              userGroups: userInfo?.groups,
            });
          })
          .catch((e) => handleError(e));
      });
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    setSideNav([]);
    if (
      !location.pathname.includes("login") &&
      location.pathname !== RootPath &&
      location.pathname !== AppLogoutPath
    ) {
      const currentNav = breadcrumbRoutes.find((breadcrumbRoute) => {
        const match = matchPath(history.location.pathname, {
          path: breadcrumbRoute.url,
        });
        return match?.isExact;
      });
      breadcrumbRoutes
        .filter(
          (breadcrumbRoute) =>
            breadcrumbRoute?.sideNav &&
            breadcrumbRoute?.rootMenu === currentNav?.rootMenu
        )
        .forEach((route) => {
          setSideNav((prev) => [...prev, route]);
        });
    }
  }, [location]);

  return (
    <>
      <LoadingDialog isOpen={isPageLoading} />
      {sideNav?.length > 0 ? (
        <Flex direction="row" gap="size-100" UNSAFE_className="body-content">
          <CustomSideNav items={sideNav} />
          <RoutePages />
        </Flex>
      ) : (
        <RoutePages />
      )}
    </>
  );
}

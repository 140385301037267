import {
  Button,
  ButtonGroup,
  ComboBox,
  Content,
  Dialog,
  Divider,
  Form,
  Heading,
  Item,
  TextField,
  useDialogContainer,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { eamApi } from "../../api/eamApi";
import useValidCountriesStateLookup from "../../context/isocode-context";

export default function CreateAccountDialog({
  setToAccount,
  sourceSystem,
  originalSourceAccount,
}) {
  const [newAccount, setNewAccount] = useState({
    account_name: originalSourceAccount?.account_name,
    source_system: sourceSystem,
    global_parent_id: originalSourceAccount?.global_parent_id,
    global_parent_name: originalSourceAccount?.global_parent_name,
    global_sub_id: originalSourceAccount?.global_sub_id,
    global_sub_name: originalSourceAccount?.global_sub_name,
    crm_industry: originalSourceAccount?.crm_industry,
    dx_sales_major_segment: originalSourceAccount?.dx_sales_major_segment,
    dx_sales_minor_segment: originalSourceAccount?.dx_sales_minor_segment,
    prm_acc_type: originalSourceAccount?.prm_acc_type,
    cam_id: originalSourceAccount?.cam_id,
    dme_sales_major_segment: originalSourceAccount?.dme_sales_major_segment,
    approval_status: originalSourceAccount?.approval_status,
  });
  const [primaryAddress, setPrimaryAddress] = useState({
    street: originalSourceAccount?.address[0]?.street,
    street_sup: originalSourceAccount?.address[0]?.street_sup,
    city: originalSourceAccount?.address[0]?.city,
    state: originalSourceAccount?.address[0]?.state,
    country: originalSourceAccount?.address[0]?.country,
    postalcode: originalSourceAccount?.address[0]?.postalcode,
  });
  const dialog = useDialogContainer();
  const [editAddress, setEditAddress] = useState({
    state: false,
    country: false,
  });
  const [validationState, setValidationState] = useState({
    country: {
      valid: true,
      message: "",
    },
    state: {
      valid: true,
      message: "",
    },
  });
  const [isoCodes, setIsoCodes] = useState([]);
  const { authState } = useOktaAuth();
  const { validCountryStateCheck } = useValidCountriesStateLookup();

  useEffect(() => {
    if (
      primaryAddress?.country?.length !== 2 ||
      (validCountryStateCheck.countries.includes(primaryAddress?.country) &&
        !validCountryStateCheck.countryStateMap[
          primaryAddress?.country
        ].includes(primaryAddress?.state))
    ) {
      eamApi.fetchISOCodes(authState.accessToken).then((data) => {
        setIsoCodes(data);
        setEditAddress((prev) => ({ ...prev, country: true }));
        if (
          validCountryStateCheck.countries.includes(
            originalSourceAccount?.address[0]?.country
          )
        )
          setEditAddress((prev) => ({ ...prev, state: true }));
      });
    }
  }, []);

  function createUUID() {
    let dt = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      (c) => {
        const r = (dt + Math.random() * 16) % 16 || 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r && 0x3) || 0x8).toString(16);
      }
    );
    return uuid;
  }

  const isCountryStateValid = () => {
    if (primaryAddress?.country?.length !== 2) {
      setValidationState((prev) => ({
        ...prev,
        country: {
          valid: false,
          message: "Country is not in 2 letter format. Please select a country",
        },
      }));
      return false;
    }

    if (
      validCountryStateCheck.countries.includes(primaryAddress?.country) &&
      !validCountryStateCheck.countryStateMap[primaryAddress?.country].includes(
        primaryAddress?.state
      )
    ) {
      setValidationState((prev) => ({
        ...prev,
        state: {
          valid: false,
          message: "State is not in 2 letter format. Please select a state",
        },
      }));
      return false;
    }
    return true;
  };

  return (
    <Dialog>
      <Heading>Create account</Heading>
      <Divider />
      <Content>
        <Form labelPosition="side" width="100%">
          <TextField
            autoFocus
            label="Account Name"
            value={originalSourceAccount?.account_name}
            isRequired
            isReadOnly
          />
          <TextField
            label="Street"
            value={primaryAddress?.street}
            isRequired
            isReadOnly
          />
          <TextField
            label="Street sup"
            value={primaryAddress?.street_sup}
            isReadOnly
          />
          <TextField
            label="City"
            value={primaryAddress?.city}
            isRequired
            isReadOnly
          />
          {editAddress?.state ? (
            <ComboBox
              label="Choose state"
              defaultItems={
                isoCodes.find(
                  (isocode) => isocode?.code === primaryAddress?.country
                )?.children
              }
              onSelectionChange={(value) => {
                setPrimaryAddress((prev) => ({ ...prev, state: value }));
                setValidationState((prev) => ({
                  ...prev,
                  state: { valid: true, message: "" },
                }));
              }}
              isRequired
              errorMessage={
                !validationState?.state?.valid
                  ? validationState?.state?.message
                  : ""
              }
              validationState={!validationState?.state?.valid ? "invalid" : ""}
            >
              {(item) => (
                <Item key={item?.code}>{`${item?.label} (${item?.code})`}</Item>
              )}
            </ComboBox>
          ) : (
            <TextField
              label="State"
              value={primaryAddress?.state}
              onChange={(value) =>
                setPrimaryAddress((prev) => ({ ...prev, state: value }))
              }
              isReadOnly
            />
          )}
          {editAddress?.country ? (
            <ComboBox
              label="Choose country"
              defaultItems={isoCodes}
              defaultSelectedKey={primaryAddress?.country}
              onSelectionChange={(value) => {
                setPrimaryAddress((prev) => ({ ...prev, country: value }));
                if (validCountryStateCheck.countries.includes(value)) {
                  setEditAddress((prev) => ({ ...prev, state: true }));
                } else {
                  setEditAddress((prev) => ({ ...prev, state: false }));
                }
                setValidationState((prev) => ({
                  ...prev,
                  country: { valid: true, message: "" },
                }));
                setPrimaryAddress((prev) => ({
                  ...prev,
                  state: originalSourceAccount?.address[0]?.state,
                }));
              }}
              isRequired
              errorMessage={
                !validationState?.country?.valid
                  ? validationState?.country?.message
                  : ""
              }
              validationState={
                !validationState?.country?.valid ? "invalid" : ""
              }
            >
              {(item) => (
                <Item key={item?.code}>{`${item?.label} (${item?.code})`}</Item>
              )}
            </ComboBox>
          ) : (
            <TextField
              label="Country"
              value={primaryAddress?.country}
              onChange={(value) =>
                setPrimaryAddress((prev) => ({ ...prev, country: value }))
              }
              isRequired
              isReadOnly
            />
          )}
          <TextField
            label="Postal code"
            value={primaryAddress?.postalcode}
            isReadOnly
          />
          <TextField
            label="Source system"
            value={newAccount?.source_system}
            isReadOnly
          />
          <TextField
            label="Global parent id"
            value={
              newAccount?.global_parent_id ? newAccount?.global_parent_id : ""
            }
            onChange={(value) =>
              setNewAccount((prev) => ({ ...prev, global_parent_id: value }))
            }
            isReadOnly
          />
          <TextField
            label="Global parent name"
            value={
              newAccount?.global_parent_name
                ? newAccount?.global_parent_name
                : ""
            }
            onChange={(value) =>
              setNewAccount((prev) => ({ ...prev, global_parent_name: value }))
            }
            isReadOnly
          />
          <TextField
            label="Global sub id"
            value={newAccount?.global_sub_id ? newAccount?.global_sub_id : ""}
            onChange={(value) =>
              setNewAccount((prev) => ({ ...prev, global_sub_id: value }))
            }
            isReadOnly
          />
          <TextField
            label="Global sub name"
            value={
              newAccount?.global_sub_name ? newAccount?.global_sub_name : ""
            }
            onChange={(value) =>
              setNewAccount((prev) => ({ ...prev, global_sub_name: value }))
            }
            isReadOnly
          />
          <TextField
            label="CRM Industry"
            value={newAccount?.crm_industry ? newAccount?.crm_industry : ""}
            onChange={(value) =>
              setNewAccount((prev) => ({ ...prev, crm_industry: value }))
            }
            isReadOnly
          />
          <TextField
            label="Approval status"
            value={
              newAccount?.approval_status ? newAccount?.approval_status : ""
            }
            onChange={(value) =>
              setNewAccount((prev) => ({ ...prev, approval_status: value }))
            }
            isReadOnly
          />
          <TextField
            label="DX sales major segment"
            value={
              newAccount?.dx_sales_major_segment
                ? newAccount?.dx_sales_major_segment
                : ""
            }
            onChange={(value) =>
              setNewAccount((prev) => ({
                ...prev,
                dx_sales_major_segment: value,
              }))
            }
            isReadOnly
          />
          <TextField
            label="DX sales minor segment"
            value={
              newAccount?.dx_sales_minor_segment
                ? newAccount?.dx_sales_minor_segment
                : ""
            }
            onChange={(value) =>
              setNewAccount((prev) => ({
                ...prev,
                dx_sales_minor_segment: value,
              }))
            }
            isReadOnly
          />
          <TextField
            label="DME sales major segment"
            value={
              newAccount?.dme_sales_major_segment
                ? newAccount?.dme_sales_major_segment
                : ""
            }
            onChange={(value) =>
              setNewAccount((prev) => ({
                ...prev,
                dme_sales_major_segment: value,
              }))
            }
            isReadOnly
          />
        </Form>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Cancel
        </Button>
        <Button
          variant="accent"
          onPress={() => {
            if (isCountryStateValid()) {
              const uuid = createUUID();
              setToAccount((prev) => ({
                ...prev,
                ...newAccount,
                address: [{ ...primaryAddress }],
                account_id: "new",
                id: uuid,
                syncSource: false,
              }));
              dialog.dismiss();
            }
          }}
        >
          Save
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

CreateAccountDialog.propTypes = {
  sourceSystem: PropTypes.string.isRequired,
  originalSourceAccount: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
    ])
  ).isRequired,
  setToAccount: PropTypes.func.isRequired,
};

export const RootPath = "/cam/";
export const OktaLoginCallbackPath = `${RootPath}login/callback`;
export const AccountMappingPath = `${RootPath}crosssystemaccount`;
export const AppLogoutPath = `${RootPath}logout`;
export const DashboardPath = `${RootPath}dashboard`;
export const GroupsPath = `${RootPath}groups`;

export const LogicalAccountGroupingPath = `${RootPath}lag`;
export const ExternalAccountMappingPath = `${RootPath}eam`;

export const GroupsRequestPath = `${GroupsPath}/requests`;
export const SentGroupRequestsPath = `${GroupsRequestPath}/sent`;
export const ReceivedGroupRequestsPath = `${GroupsRequestPath}/received`;
export const CreateAndSubscribeGroupsPath = `${GroupsPath}/createandsubscribe`;
export const ManageGroupsPath = `${GroupsPath}/manage`;
export const CreateGroupsPath = `${CreateAndSubscribeGroupsPath}/create`;
export const EditGroupsPath = `${ManageGroupsPath}/edit`;
export const GroupsEmailRequestApprovalPath = `${ReceivedGroupRequestsPath}/:requestId/:requestType/:status`;

export const RemapExternalAccountPath = `${ExternalAccountMappingPath}/remap`;
export const SplitExternalAccountMappingsPath = `${ExternalAccountMappingPath}/split`;
export const CompleteSyncSetPath = `${ExternalAccountMappingPath}/completesync/:originalSourceAccountId`;

export const LookupLogicalAccountGroupingPath = `${LogicalAccountGroupingPath}/lookup`;
export const CreateLogicalAccountGroupingPath = `${LookupLogicalAccountGroupingPath}/create`;
export const OverrideLogicalAccountGroupingPath = `${LookupLogicalAccountGroupingPath}/override`;
export const ManageLogicalAccountGroupingPath = `${LogicalAccountGroupingPath}/manage`;
export const SentLogicalAccountGroupingPath = `${ManageLogicalAccountGroupingPath}/sent`;
export const ReceivedLogicalAccountGroupingPath = `${ManageLogicalAccountGroupingPath}/received`;
export const LagEmailRequestApprovalPath = `${ReceivedLogicalAccountGroupingPath}/:groupKey/:status`;

export const BulkRegistrationPath = `${RootPath}selfserve/registration`;

import {
  ActionButton,
  Flex,
  Heading,
  Item,
  Picker,
  Text,
  View,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { error } from "@react/react-spectrum/Toast";
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import CloseCircle from "@spectrum-icons/workflow/CloseCircle";
import Pending from "@spectrum-icons/workflow/Pending";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { groupsApi } from "../../../api/groupsApi";
import {
  SubmittedApprovedRequestColumns,
  SubmittedRejectedRequestColumns,
} from "../../../constants/Columns";
import { GroupsRequestPath } from "../../../constants/Routes";
import { PENDING, REJECTED } from "../../../constants/Status";
import useUserProfile from "../../../context/user-context";
import Table from "../../../components/Common/Table";

const tabs = [
  {
    id: "Pending",
    label: "Pending",
  },
  {
    id: "Approved",
    label: "Approved",
  },
  {
    id: "Rejected",
    label: "Rejected",
  },
];

export default function SentGroupRequests() {
  const [groupRequests, setGroupRequests] = useState([]);
  const [filters, setFilters] = useState({ status: PENDING });
  const history = useHistory();
  const [columns, setColumns] = useState(SubmittedApprovedRequestColumns());
  const [loadingState, setLoadingState] = useState("loading");
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();

  useEffect(() => {
    if (filters?.status?.length !== 0 && user?.userId) {
      groupsApi
        .fetchGroupRequestsByStatus(
          "Submitted",
          filters.status,
          user?.userId,
          authState.accessToken
        )
        .then((data) => {
          setGroupRequests([...data]);
          setLoadingState("");
        })
        .catch((e) => {
          setLoadingState("");
          if (e.toString().toLowerCase().includes("network"))
            error(`Server not reachable! Please contact CAM team!`, {
              timeout: 5000,
            });
          else if (e?.response?.data) {
            error(e?.response?.data?.message, {
              timeout: 5000,
            });
          } else {
            error(`Sorry something went wrong! Please contact CAM team!`, {
              timeout: 5000,
            });
          }
        });
    }
  }, [filters, user]);

  const filterTableData = () =>
    groupRequests.filter(
      (groupRequest) => groupRequest?.status === filters?.status
    );

  const renderStatus = (status) => {
    let statusIcon = (
      <CloseCircle aria-label="Rejected requests" color="negative" />
    );

    if (status === "Pending")
      statusIcon = <Pending aria-label="Pending requests" color="notice" />;
    else if (status === "Approved")
      statusIcon = (
        <CheckmarkCircle aria-label="Approved requests" color="positive" />
      );

    return statusIcon;
  };

  const renderCell = (colKey, row) => {
    if (colKey === "status") {
      return renderStatus(row.status);
    }
    return <span>{row[colKey]}</span>;
  };

  return (
    <Flex direction="column" margin="size-300" gap="size-300">
      <View>
        <Heading level={3}>Submitted Group Requests</Heading>
      </View>
      <View>
        <Picker
          items={tabs}
          label="Request status"
          selectedKey={filters?.status}
          onSelectionChange={(selected) => {
            setLoadingState("loading");
            if (selected === REJECTED) {
              setColumns(SubmittedRejectedRequestColumns());
            } else {
              setColumns(SubmittedApprovedRequestColumns());
            }
            setFilters((prev) => ({ ...prev, status: selected }));
          }}
        >
          {(item) => <Item key={item.id}>{item.label}</Item>}
        </Picker>
      </View>
      <View>
        <Flex
          height="size-6000"
          width="90%"
          UNSAFE_style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}
          UNSAFE_className="stepper-box"
        >
          <Table
            columns={columns}
            rows={filterTableData()}
            renderCell={renderCell}
            loadingState={loadingState}
          />
        </Flex>
      </View>
      <View>
        <ActionButton
          isQuiet
          onPress={() => {
            history.push(GroupsRequestPath);
          }}
        >
          <ChevronLeft />
          <Text>Group Requests</Text>
        </ActionButton>
      </View>
    </Flex>
  );
}

import axios from "axios";

const instance = axios.create({
  baseURL: `${window.apcConfig.cam.REACT_APP_API_URL}`,
  headers: {
    "x-api-key": "ess-cbs-cam",
  },
});

export default instance;

import React, { useEffect, useState } from "react";
import {
  Button,
  Content,
  ContextualHelp,
  Flex,
  Form,
  Heading,
  Item,
  LabeledValue,
  Picker,
  TextArea,
  View,
} from "@adobe/react-spectrum";
import { success, error } from "@react/react-spectrum/Toast";
import PropTypes from "prop-types";
import { useOktaAuth } from "@okta/okta-react";
import useUserProfile from "../../../context/user-context";
import Roles from "../../../constants/Roles";
import { USER } from "../../../constants/RoleType";
import { SUBSCRIBE } from "../../../constants/GroupRequestType";
import { groupsApi } from "../../../api/groupsApi";

export default function SubscribeGroupForm({
  groupDetails,
  handleOpenDialog,
  setLoadingDialog,
}) {
  const [subscribeGroup, setSubscribeGroup] = useState({});
  const { user, addUserProfile } = useUserProfile();
  const [subscribeValidState, setSubscribeValidState] = useState({
    reason: { state: "", message: "" },
  });

  const roles = Roles();
  const { authState } = useOktaAuth();
  const [isRequestSent, setRequestSent] = useState(false);

  useEffect(() => {
    setSubscribeGroup({
      requester: user?.email,
      requesterName: user?.name,
      groupName: groupDetails?.name,
      role: groupDetails?.role || USER,
      reason: "",
    });
  }, []);

  const validateBusinessReason = (value) => {
    let validatedReason = { state: "valid", message: "" };
    if (value?.length === 0) {
      validatedReason = {
        state: "invalid",
        message: "Business reason must not be empty.",
      };
    }
    setSubscribeValidState({
      ...subscribeValidState,
      reason: validatedReason,
    });
  };

  const handleOnSubmit = () => {
    const { groupName, requester, role, reason, requesterName } =
      subscribeGroup;

    const groupRequest = {
      requestedBy: requester,
      requesterName,
      typeOfRequest: SUBSCRIBE,
      requestedGroup: groupName,
      userRequest: {
        name: user?.name,
        email: user?.email,
        userId: user?.userId,
        role,
      },
      requestedBusinessReason: reason,
    };

    setRequestSent(true);
    setLoadingDialog(true);

    groupsApi
      .createGroupRequest(groupRequest, authState.accessToken)
      .then((data) => {
        if (data?.acknowledged) {
          handleOpenDialog();
          addUserProfile({ ...user, role: subscribeGroup?.role });
          success(
            `Request to access group ${subscribeGroup?.groupName} for role ${subscribeGroup?.role} has been submitted successfully!`,
            {
              timeout: 5000,
            }
          );
          setRequestSent(false);
          setLoadingDialog(false);
        }
      })
      .catch((e) => {
        setRequestSent(false);
        setLoadingDialog(false);
        if (e.toString().toLowerCase().includes("network"))
          error(`Server not reachable! Please contact CAM team!`, {
            timeout: 5000,
          });
        else if (e?.response?.data) {
          error(e?.response?.data?.message, {
            timeout: 5000,
          });
        } else {
          error(`Sorry something went wrong! Please contact CAM team!`, {
            timeout: 5000,
          });
        }
      });
  };

  return (
    <Form
      UNSAFE_style={{ backgroundColor: "#F5F5F5" }}
      aria-labelledby="Group Subscribe Form"
    >
      <Flex direction="column" gap="size-500">
        <LabeledValue
          label="Requester"
          value={`${user?.name} (${user?.email})`}
        />
        <LabeledValue label="Group Name" value={subscribeGroup?.groupName} />
        <Picker
          label="Choose Role"
          selectedKey={subscribeGroup?.role}
          onSelectionChange={(key) =>
            setSubscribeGroup((prev) => ({ ...prev, role: key }))
          }
          items={roles}
        >
          {(role) => <Item key={role.id}>{role.label}</Item>}
        </Picker>

        <TextArea
          validationState={subscribeValidState?.reason?.state}
          errorMessage={subscribeValidState?.reason?.message}
          isRequired
          label="Business Reason"
          value={subscribeGroup?.reason}
          onChange={(value) => {
            validateBusinessReason(value);
            setSubscribeGroup((prev) => ({ ...prev, reason: value }));
          }}
          contextualHelp={
            <ContextualHelp variant="info">
              <Heading>Tips</Heading>
              <Content>
                Kindly provide business reason for accessing this group
              </Content>
            </ContextualHelp>
          }
        />
        <View>
          <Button
            variant="accent"
            onPress={handleOnSubmit}
            isDisabled={subscribeGroup?.reason?.length === 0 || isRequestSent}
            UNSAFE_style={
              subscribeGroup?.reason?.length === 0
                ? { backgroundColor: "#E2E2E2" }
                : {}
            }
          >
            Submit
          </Button>
        </View>
      </Flex>
    </Form>
  );
}

SubscribeGroupForm.propTypes = {
  groupDetails: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ).isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  setLoadingDialog: PropTypes.func.isRequired,
};

import { Flex } from "@adobe/react-spectrum";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  SentLogicalAccountGroupingPath,
  ReceivedLogicalAccountGroupingPath,
} from "../../../constants/Routes";
import Card from "../../../components/Common/Card/Card";
import { ADMIN, SUPERUSER } from "../../../constants/RoleType";
import useValidUserAccess from "../../../hooks/useValidUserAccess";
import { LAG } from "../../../constants/CamFunctions";

export default function ManageLAG() {
  const history = useHistory();
  const isApproveLagActionAllowed = useValidUserAccess([ADMIN, SUPERUSER], LAG);

  const handleOnPress = (path) => {
    history.push(path);
  };

  return (
    <Flex gap="size-200" margin="1rem">
      <Card
        title="Submitted LAG Requests"
        content="List all LAG requests submitted by me."
        handleOnPress={() => handleOnPress(SentLogicalAccountGroupingPath)}
      />
      {isApproveLagActionAllowed ? (
        <Card
          title="Pending LAG Request Approvals"
          content="List all LAG requests pending for approvals."
          handleOnPress={() =>
            handleOnPress(ReceivedLogicalAccountGroupingPath)
          }
        />
      ) : (
        <></>
      )}
    </Flex>
  );
}

import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  useDialogContainer,
} from "@adobe/react-spectrum";
import Table from "../Common/Table";

export default function AccountRelDialog({
  accountRelationshipColumn,
  accountRelData,
  renderAccountRelCell,
  renderEmptyCell,
}) {
  const dialog = useDialogContainer();

  return (
    <Dialog size="L" width="70rem">
      <Heading>Account Relationship</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-500">
          <Table
            columns={accountRelationshipColumn}
            rows={accountRelData}
            renderCell={renderAccountRelCell}
            selectionMode="none"
            density="compact"
            renderEmptyView={renderEmptyCell}
          />
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Close
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

AccountRelDialog.propTypes = {
  accountRelData: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.object),
      ])
    )
  ).isRequired,
  accountRelationshipColumn: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    )
  ).isRequired,
  renderAccountRelCell: PropTypes.func.isRequired,
  renderEmptyCell: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from "react";
import {
  Button,
  Content,
  ContextualHelp,
  Flex,
  Form,
  Heading,
  Item,
  LabeledValue,
  ComboBox,
  TextField,
  View,
  useAsyncList,
  NumberField,
} from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import { useOktaAuth } from "@okta/okta-react";
import { lagApi } from "../../../api/lagApi";

export default function EditGroupFieldForm({
  fieldInfo,
  setRuleInfo,
  ruleInfo,
  setIsSidePaneOpen,
  isDisabled,
  setNewLagRule,
}) {
  const [editFieldInfo, setEditFieldInfo] = useState({
    field: fieldInfo.field_name,
    include: fieldInfo.include,
    exclude: fieldInfo.exclude,
  });
  const [isNumeric, setIsNumeric] = useState(false);
  const { authState } = useOktaAuth();

  useEffect(() => {
    setIsNumeric(
      ruleInfo.find((item) => item.field_name === editFieldInfo.field)?.numeric
    );
  }, [editFieldInfo]);

  const handleOnSubmit = () => {
    const modifiedRuleInfo = ruleInfo.map((rulefield) => {
      if (rulefield.field_name === fieldInfo.field_name) {
        return {
          ...rulefield,
          field: editFieldInfo.field,
          partition_value: editFieldInfo.partition_value,
          include: editFieldInfo?.include
            ? editFieldInfo?.include.map((x) => x.trim())
            : [],
          exclude: editFieldInfo?.exclude
            ? editFieldInfo?.exclude.map((x) => x.trim())
            : [],
          value: editFieldInfo.value,
        };
      }
      return rulefield;
    });
    setRuleInfo(modifiedRuleInfo);
    setNewLagRule(modifiedRuleInfo.filter((attr) => attr.selected));
    setIsSidePaneOpen(false);
  };

  const valueComboBoxList = useAsyncList({
    initialFilterText: "",
    async load({ filterText }) {
      let json = [];
      if (filterText !== "") {
        json = await lagApi.fetchTypeaheadResults(
          {
            field_name: editFieldInfo.field,
            value: filterText,
          },
          authState.accessToken
        );
        json = json.map((name, index) => ({ id: index, name }));
      }

      return {
        items: json,
      };
    },
  });

  return (
    <Form
      UNSAFE_style={{ backgroundColor: "#F5F5F5" }}
      aria-labelledby="LAG Field Edit Form"
    >
      <Flex direction="column" gap="size-500">
        <LabeledValue label="Field" value={fieldInfo.field_name} />

        {isNumeric ? (
          <NumberField
            label="Partition Value"
            description="Value which the group will be split by"
            value={editFieldInfo.partition_value}
            contextualHelp={
              <ContextualHelp variant="info">
                <Heading>Example</Heading>
                <Content>
                  {
                    "Partition Value of ARR 100 means one group will be all ARR <= 100, and the other one will be ARR > 100"
                  }
                </Content>
              </ContextualHelp>
            }
            onChange={(inputValue) =>
              setEditFieldInfo((prev) => ({
                ...prev,
                partition_value: inputValue,
              }))
            }
            width="200px"
          />
        ) : (
          <ComboBox
            label="Value"
            items={valueComboBoxList.items}
            inputValue={
              valueComboBoxList.filterText.length > 0
                ? valueComboBoxList.filterText
                : fieldInfo.value
            }
            onInputChange={valueComboBoxList.setFilterText}
            loadingState={valueComboBoxList.loadingState}
            onSelectionChange={(inputValue) => {
              setEditFieldInfo((prev) => ({
                ...prev,
                value: inputValue,
              }));
            }}
            width="200px"
          >
            {(item) => <Item key={item?.name}>{item?.name}</Item>}
          </ComboBox>
        )}

        <TextField
          label="Include Condition"
          description="Comma Delimited List (ex. us, in)"
          value={editFieldInfo.include}
          contextualHelp={
            <ContextualHelp variant="info">
              <Heading>Tips</Heading>
              <Content>Values to include in the rule</Content>
            </ContextualHelp>
          }
          onChange={(inputValue) =>
            setEditFieldInfo((prev) => ({
              ...prev,
              include: inputValue.split(","),
            }))
          }
          isDisabled={isDisabled || isNumeric}
          width="200px"
        />
        <TextField
          label="Exclude Condition"
          description="Comma Delimited List (ex. us, in)"
          value={editFieldInfo.exclude}
          contextualHelp={
            <ContextualHelp variant="info">
              <Heading>Tips</Heading>
              <Content>Values to exclude in the rule</Content>
            </ContextualHelp>
          }
          onChange={(inputValue) =>
            setEditFieldInfo((prev) => ({
              ...prev,
              exclude: inputValue.split(", "),
            }))
          }
          isDisabled={isDisabled || isNumeric}
          width="200px"
        />
        <View>
          <Button
            variant="accent"
            onPress={handleOnSubmit}
            isDisabled={
              !(
                typeof editFieldInfo?.value === "string" &&
                editFieldInfo?.value.length > 0
              ) &&
              !(
                editFieldInfo?.partition_value !== undefined &&
                typeof editFieldInfo?.partition_value === "number" &&
                !Number.isNaN(editFieldInfo?.partition_value)
              ) &&
              editFieldInfo?.include === undefined &&
              !(isDisabled && isNumeric) &&
              editFieldInfo?.exclude === undefined &&
              !(isDisabled && isNumeric)
            }
          >
            Submit
          </Button>
        </View>
      </Flex>
    </Form>
  );
}

EditGroupFieldForm.propTypes = {
  fieldInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.string),
    ])
  ).isRequired,
  setRuleInfo: PropTypes.func.isRequired,
  ruleInfo: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.string),
      ])
    )
  ).isRequired,
  setIsSidePaneOpen: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  setNewLagRule: PropTypes.func.isRequired,
};

import React, { useContext, useReducer, createContext } from "react";
import PropTypes from "prop-types";
import * as ACTIONS from "../store/actions/actions";
import {
  BreadcrumbsReducer,
  initialState,
} from "../store/reducers/breadcrumbs-reducer";

export const BreadcrumbsContext = createContext();

export const BreadcrumbsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(BreadcrumbsReducer, initialState);

  const addBreadcrumbsLink = (breadcrumbsLink) => {
    dispatch({
      type: ACTIONS.ADD_BREADCRUMBS_LINK,
      payload: {
        breadcrumbsLink,
      },
    });
  };

  const setIsBreadcrumbsLink = (isBreadcrumbsLink) => {
    dispatch({
      type: ACTIONS.SET_IS_BREADCRUMBS_LINK,
      payload: {
        isBreadcrumbsLink,
      },
    });
  };

  const resetBreadcrumbsLink = () => {
    dispatch({
      type: ACTIONS.RESET_BREADCRUMBS_LINK,
    });
  };

  const value = {
    breadcrumbsLink: state.breadcrumbsLink,
    isBreadcrumbsLink: state.isBreadcrumbsLink,
    addBreadcrumbsLink,
    resetBreadcrumbsLink,
    setIsBreadcrumbsLink,
  };

  return (
    <BreadcrumbsContext.Provider value={value}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

BreadcrumbsProvider.defaultProps = {
  children: {},
};
BreadcrumbsProvider.propTypes = {
  children: PropTypes.node,
};

const useBreadcrumbs = () => {
  const context = useContext(BreadcrumbsContext);

  if (context === undefined) {
    throw new Error("useBreadcrumbs must be used with BreadcrumbsContext");
  }

  return context;
};

export default useBreadcrumbs;

import React from "react";
import "./app-logo.css";
import AdobeLogo from "@react/react-spectrum/Icon/AdobeLogo";
import { Flex, View } from "@adobe/react-spectrum";

function AppLogo() {
  return (
    <Flex
      justifySelf="start"
      alignItems="center"
      UNSAFE_className="logo-section"
    >
      <AdobeLogo size="M" />
      <View
        width="fit-content"
        marginStart="10px"
        marginTop="3px"
        marginEnd="25px"
      >
        {process.env.REACT_APP_APP_TITLE || "Centralized Account Management"}
      </View>
    </Flex>
  );
}

export default AppLogo;

import {
  ActionButton,
  ActionGroup,
  Badge,
  Button,
  Checkbox,
  CheckboxGroup,
  ComboBox,
  Content,
  ContextualHelp,
  Flex,
  Heading,
  Icon,
  IllustratedMessage,
  Image,
  Item,
  LabeledValue,
  ListView,
  Text,
  Tooltip,
  TooltipTrigger,
  View,
  useAsyncList,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import Avatar from "@react/react-spectrum/Avatar";
import { error, success } from "@react/react-spectrum/Toast";
import NotFound from "@spectrum-icons/illustrations/NotFound";
import RemoveCircle from "@spectrum-icons/workflow/RemoveCircle";
import Undo from "@spectrum-icons/workflow/Undo";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { groupsApi } from "../../../api/groupsApi";
import { ADD, MODIFY, REMOVE } from "../../../constants/Actions";
import { CamFunctions } from "../../../constants/CamFunctions";
import Roles from "../../../constants/Roles";
import { ManageGroupsPath } from "../../../constants/Routes";
import useUserProfile from "../../../context/user-context";
import LoadingDialog from "../../Dialog/LoadingDialog";

const picUrl = "https://s7d2.scene7.com/is/image/IMGDIR/";
export default function EditGroupForm() {
  const [modifiedListItems, setModifiedListItems] = useState([]);
  const roles = Roles();
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();
  const camFunctionsList = CamFunctions();

  const [updatedGroup, setUpdatedGroup] = useState({
    id: "",
    name: "",
    modifiedBy: "",
    members: [],
    camFunctions: [],
  });

  const [groupValidState, setGroupValidState] = useState([]);
  const [isPageLoading, setPageLoading] = useState(false);

  const history = useHistory();

  const { group } = history.location.state?.group
    ? history.location.state
    : { group: undefined };

  useEffect(() => {
    if (!group) history.push(ManageGroupsPath);

    setUpdatedGroup((prev) => ({
      ...prev,
      id: group?.id,
      name: group?.groupName,
      members: [...prev.members, ...group?.groupMembers],
      camFunctions: [...group?.camFunctions],
    }));
  }, []);

  useEffect(() => {
    setUpdatedGroup((prev) => ({
      ...prev,
      modifiedBy: user?.email,
    }));
  }, [user]);

  const list = useAsyncList({
    initialFilterText: "",
    async load({ filterText }) {
      const json = await groupsApi.fetchUsers(
        filterText.length !== 0 ? filterText : "a",
        authState.accessToken
      );
      return {
        items: json,
      };
    },
  });

  useEffect(() => {
    setModifiedListItems(() => [
      ...updatedGroup.members?.filter((item) => item?.action),
    ]);
  }, [updatedGroup]);

  const renderMemberModifiedEmptyState = () => (
    <IllustratedMessage>
      <NotFound />
      <Heading>No members modfied</Heading>
      <Content>Add/remove/update members</Content>
    </IllustratedMessage>
  );
  const renderMemberListEmptyState = () => (
    <IllustratedMessage>
      <NotFound />
      <Heading>No members found</Heading>
      <Content>Try with another name</Content>
    </IllustratedMessage>
  );

  const handleOnActionList = (action, listItem) => {
    if (action === REMOVE) {
      setUpdatedGroup((prev) => {
        if (
          prev.members.filter(
            (member) => member.role === "admin" && member.action !== REMOVE
          ).length === 2 &&
          listItem?.role === "admin"
        ) {
          error(
            "Member cannot be removed from group. Atleast 2 admin members must be in a group",
            {
              timeout: 5000,
            }
          );
          return { ...prev };
        }
        return {
          ...prev,
          members:
            listItem?.action === ADD
              ? prev.members.filter(
                  (prevItem) => prevItem.email !== listItem?.email
                )
              : prev.members.map((prevItem) => {
                  const { prevRole, ...rest } = prevItem;
                  if (prevItem.email === listItem?.email) {
                    if (prevItem?.action === MODIFY)
                      return { ...rest, role: prevRole, action: REMOVE };
                    return { ...prevItem, action };
                  }
                  return prevItem;
                }),
        };
      });
    }
  };

  const handleRoleChange = (action, value, listItem) => {
    if (!listItem?.new) {
      setUpdatedGroup((prev) => {
        if (
          prev.members.filter(
            (member) => member.role === "admin" && member.action !== REMOVE
          ).length === 2 &&
          listItem?.role === "admin"
        ) {
          error(
            "Member role cannot be modified. Atleast 2 admin members must be in a group",
            {
              timeout: 5000,
            }
          );
          return { ...prev };
        }
        return {
          ...prev,
          members: prev.members.map((prevItem) => {
            if (prevItem.email === listItem.email) {
              if (prevItem?.action === ADD) {
                return {
                  ...prevItem,
                  role: value,
                };
              }
              if (prevItem?.prevRole === value)
                return {
                  name: prevItem.name,
                  email: prevItem.email,
                  role: value,
                  userId: prevItem.userId,
                };
              return {
                ...prevItem,
                prevRole: prevItem?.action
                  ? prevItem?.prevRole
                  : prevItem?.role,
                role: value,
                action,
              };
            }
            return prevItem;
          }),
        };
      });
    }
  };

  const handleUndo = (item) => {
    const { prevRole, action, ...rest } = item;
    if (item.action === ADD) {
      setUpdatedGroup((prev) => {
        if (
          prev.members.filter(
            (member) => member.role === "admin" && member.action !== REMOVE
          ).length === 2 &&
          rest?.role === "admin"
        ) {
          error(
            "Undo can not be performed, member cannot be removed from group. Atleast 2 admin members must be in a group",
            {
              timeout: 5000,
            }
          );
          return { ...prev };
        }
        return {
          ...prev,
          members: prev.members.filter(
            (prevItem) => prevItem.email !== item.email
          ),
        };
      });
    }

    if (action === REMOVE) {
      setUpdatedGroup((prev) => ({
        ...prev,
        members: prev.members.map((prevItem) => {
          if (prevItem.email === rest.email) return { ...rest };

          return prevItem;
        }),
      }));
    }

    if (action === MODIFY) {
      setUpdatedGroup((prev) => {
        if (
          prev.members.filter(
            (member) => member.role === "admin" && member.action !== REMOVE
          ).length === 2 &&
          rest?.role === "admin"
        ) {
          error(
            "Undo can not be performed, member role cannot be modified. Atleast 2 admin members must be in a group",
            {
              timeout: 5000,
            }
          );
          return { ...prev };
        }
        return {
          ...prev,
          members: prev.members.map((prevItem) => {
            if (prevItem.email === rest.email)
              return {
                ...rest,
                role: prevRole || rest.role,
              };

            return prevItem;
          }),
        };
      });
    }
  };

  const validateCamFunctions = (values) => {
    let validatedFunctions = { state: "valid", message: "" };
    if (values?.length === 0) {
      validatedFunctions = {
        state: "invalid",
        message: "Select atleast one CAM function to access",
      };
    }
    setGroupValidState({
      ...groupValidState,
      functions: validatedFunctions,
    });
  };

  const validateCreateGroupForm = () => {
    const formInputs = Object.keys(groupValidState);
    let isValid = true;
    formInputs.forEach((input) => {
      if (groupValidState[input]?.state?.length === 0) {
        isValid = false;
      } else if (groupValidState[input]?.state === "invalid") {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleBack = () => {
    history.push(ManageGroupsPath);
  };

  const handleSubmit = () => {
    setPageLoading(true);
    groupsApi
      .updateGroup(
        updatedGroup?.name,
        {
          groupName: updatedGroup?.name,
          groupMembers: updatedGroup?.members,
          camFunctions: updatedGroup?.camFunctions,
          requestedBy: updatedGroup?.modifiedBy,
        },
        authState.accessToken
      )
      .then((data) => {
        if (data?.acknowledged) {
          success(`Group has been updated successfully!`, { timeout: 5000 });
          setPageLoading(false);
          history.push(ManageGroupsPath);
        }
      })
      .catch((e) => {
        setPageLoading(false);
        if (e.toString().toLowerCase().includes("network"))
          error(`Server not reachable! Please contact CAM team!`, {
            timeout: 5000,
          });
        else if (e?.response?.data) {
          error(e?.response?.data?.message, {
            timeout: 5000,
          });
        } else {
          error(`Sorry something went wrong! Please contact CAM team!`, {
            timeout: 5000,
          });
        }
      });
  };

  return (
    <Flex direction="column" gap="size-150" margin="size-300" width="60%">
      <LoadingDialog isOpen={isPageLoading} />
      <Heading>Edit Group</Heading>
      <View
        borderWidth="thin"
        borderColor="dark"
        borderRadius="medium"
        padding="size-250"
        UNSAFE_className="stepper-box"
      >
        <Flex direction="column" margin="size-300" gap="size-150">
          <Flex>
            <View flex="60%">
              <LabeledValue label="Group name" value={updatedGroup?.name} />
            </View>
            <View flex="40%">
              <CheckboxGroup
                label="CAM functions to access"
                validationState={groupValidState?.functions?.state}
                errorMessage={groupValidState?.functions?.message}
                isRequired
                value={updatedGroup.camFunctions}
                onChange={(values) => {
                  validateCamFunctions(values);
                  setUpdatedGroup((prev) => ({
                    ...prev,
                    camFunctions: [...values],
                  }));
                }}
              >
                {camFunctionsList.map((functionItem) => (
                  <Checkbox value={functionItem?.id}>
                    {functionItem?.label}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </View>
          </Flex>
          <Flex direction="column" gap="size-130">
            <Heading level={4}>Modify members</Heading>
            <Flex justifyContent="space-between">
              <View>
                <ComboBox
                  label="Members"
                  items={list.items}
                  inputValue={list.filterText}
                  onInputChange={list.setFilterText}
                  loadingState={list.loadingState}
                  onSelectionChange={(key) => {
                    if (
                      !updatedGroup.members?.some(
                        (member) => member?.email === key
                      )
                    ) {
                      const searchedUser = list.items?.find(
                        (item) => item?.email === key
                      );
                      if (searchedUser)
                        setUpdatedGroup((prev) => ({
                          ...prev,
                          members: [
                            ...prev.members,
                            {
                              name: searchedUser?.name,
                              email: searchedUser?.email,
                              role: searchedUser?.role,
                              userId: searchedUser?.userId,
                              action: ADD,
                            },
                          ],
                        }));
                    }
                  }}
                >
                  {(item) => (
                    <Item key={item.email}>
                      <Icon
                        UNSAFE_style={{
                          width: "2rem",
                          height: "2rem",
                        }}
                        justifySelf="center"
                        alignSelf="center"
                      >
                        <Avatar
                          src={`https://s7d2.scene7.com/is/image/IMGDIR/${item?.userId}`}
                          alt="User"
                        />
                      </Icon>
                      <Text>{item.name}</Text>
                      <Text slot="description">{item?.email}</Text>
                    </Item>
                  )}
                </ComboBox>
              </View>
              <View alignSelf="end">
                <ContextualHelp variant="info">
                  <Heading>Badge description</Heading>
                  <Content>
                    <Flex direction="column" gap="size-100">
                      <View>
                        <Flex gap="size-100">
                          <Badge variant="seafoam">A</Badge>
                          <Text>Member added</Text>
                        </Flex>
                      </View>
                      <View>
                        <Flex gap="size-100">
                          <Badge variant="negative">R</Badge>
                          <Text>Member removed</Text>
                        </Flex>
                      </View>
                      <View>
                        <Flex gap="size-100">
                          <Badge variant="yellow">M</Badge>
                          <Text>Member role modified</Text>
                        </Flex>
                      </View>
                    </Flex>
                  </Content>
                </ContextualHelp>
              </View>
            </Flex>
            <Flex direction="row" gap="size-130">
              <View flex="60%">
                <ListView
                  selectionMode="none"
                  aria-label="Current members"
                  maxWidth="size-6000"
                  height="17rem"
                  items={updatedGroup?.members}
                  renderEmptyState={renderMemberListEmptyState}
                >
                  {(item) =>
                    item?.action !== REMOVE && (
                      <Item
                        key={item.email}
                        textValue={`CurrentMembers${item.email}`}
                      >
                        <Image src={`${picUrl}${item?.userId}`} />
                        <Text>{item.name}</Text>
                        <Text slot="description">{item.email}</Text>
                        <ActionGroup
                          buttonLabelBehavior="hide"
                          onAction={(action) =>
                            handleOnActionList(action, item)
                          }
                        >
                          <Item key="role" textValue="Role">
                            <ActionGroup
                              aria-label="Select role"
                              overflowMode="collapse"
                              selectionMode="single"
                              selectedKeys={new Set([item.role])}
                              items={roles}
                              disallowEmptySelection
                              onSelectionChange={(keys) =>
                                handleRoleChange(
                                  MODIFY,
                                  keys.values().next().value,
                                  item
                                )
                              }
                              maxWidth={100}
                              isEmphasized
                            >
                              {(role) => (
                                <Item key={role.id} textValue={role.label}>
                                  <Text>{role.label}</Text>
                                </Item>
                              )}
                            </ActionGroup>
                          </Item>
                          <Item key={REMOVE} textValue="Remove">
                            <View>
                              <RemoveCircle color="negative" />
                            </View>
                            <Text>Remove</Text>
                          </Item>
                        </ActionGroup>
                      </Item>
                    )
                  }
                </ListView>
              </View>
              <View flex="40%">
                <ListView
                  selectionMode="none"
                  aria-label="Changed members"
                  maxWidth="size-6000"
                  height="17rem"
                  items={modifiedListItems}
                  renderEmptyState={renderMemberModifiedEmptyState}
                >
                  {(item) => (
                    <Item
                      key={`changedmembers${item.email}`}
                      textValue={`modifiedListItem${item.email}`}
                    >
                      <Flex
                        justifyContent="center"
                        gridArea="thumbnail"
                        alignItems="center"
                      >
                        <View paddingEnd="size-100">
                          {item?.action === ADD && (
                            <Badge variant="seafoam">A</Badge>
                          )}
                          {item?.action === REMOVE && (
                            <Badge variant="negative">R</Badge>
                          )}
                          {item?.action === MODIFY && (
                            <Badge variant="yellow">M</Badge>
                          )}
                        </View>
                      </Flex>
                      <Text>{item.name}</Text>
                      <Text slot="description">{item.email}</Text>
                      <TooltipTrigger>
                        <ActionButton onPress={() => handleUndo(item)}>
                          <View>
                            <Undo color="notice" />
                          </View>
                        </ActionButton>
                        <Tooltip>Undo</Tooltip>
                      </TooltipTrigger>
                    </Item>
                  )}
                </ListView>
              </View>
            </Flex>
          </Flex>
          <Flex gap="size-200" marginTop="1rem">
            <View>
              <Button variant="primary" onPress={handleBack}>
                Cancel
              </Button>
            </View>
            <View>
              <Button
                variant="accent"
                onPress={handleSubmit}
                isDisabled={
                  !validateCreateGroupForm() ||
                  (modifiedListItems.length === 0 &&
                    group?.camFunctions?.length ===
                      updatedGroup?.camFunctions.length &&
                    group?.camFunctions?.every((camFunc) =>
                      updatedGroup?.camFunctions?.includes(camFunc)
                    ))
                }
                UNSAFE_style={
                  !validateCreateGroupForm()
                    ? { backgroundColor: "#E2E2E2" }
                    : {}
                }
              >
                Update
              </Button>
            </View>
          </Flex>
        </Flex>
      </View>
    </Flex>
  );
}

import * as ACTIONS from "../actions/actions";

export const initialState = {
  validCountryStateCheck: {
    countryStateMap: {},
    countries: [],
  },
};

export const IsocodeReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.ADD_VALID_COUNTRY_STATE_ISOCODE:
      return {
        ...state,
        validCountryStateCheck: { ...payload.validCountryStateCheck },
      };
    case ACTIONS.RESET_VALID_COUNTRY_STATE_ISOCODE:
      return {
        ...state,
        validCountryStateCheck: initialState.validCountryStateCheck,
      };
    default:
      return state;
  }
};

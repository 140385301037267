import { Flex, Text, Well } from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import Collapsible from "../../../Common/Collapsible/Collapsible";
import { AddressLabel } from "../../../../constants/SlidingPaneLabels";

export default function AddressCard(props) {
  const { addresses } = props;
  const [addressList, setAddressList] = useState([]);
  const addressLabelsColumn = AddressLabel();

  useEffect(() => {
    setAddressList(addresses);
  }, [addresses]);

  return addressList.map((address, i) => (
    <Well
      key={address.address_id}
      aria-labelledby={`address${i}`}
      minHeight="fit-content"
    >
      <h3 id={`address${i}Label`}>
        {i === 0 ? "Primary" : "Associated"} Address {i >= 2 ? i : ""}
      </h3>
      <p>
        {address.street}
        {address.street_sup && <br />}
        {address.street_sup}
        <br /> {address.city}, {address.state} {address.country}
        {address.postalcode ? " - ".concat(address.postalcode) : ""}
      </p>
      <Collapsible>
        {addressLabelsColumn.map((label) => (
          <Flex key={label.key} gap="size-100">
            <Text UNSAFE_style={{ color: "#707070", fontSize: "0.82rem" }}>
              {label.title}:
            </Text>
            <Text>{address[label.key]}</Text>
          </Flex>
        ))}
      </Collapsible>
    </Well>
  ));
}

import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  useDialogContainer,
  Text,
} from "@adobe/react-spectrum";
import Table from "../Common/Table";

export default function LAGOverrideDetailsDialog({
  overrideDetailsColumn,
  overrideDetailsData,
  groupKey,
}) {
  const dialog = useDialogContainer();

  const renderCell = (colKey, row) => {
    if (colKey === "group_key") return <Text>{groupKey}</Text>;
    return <Text>{row[colKey]}</Text>;
  };

  return (
    <Dialog size="L" width="70rem">
      <Heading>Override Details</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-500">
          <Table
            columns={overrideDetailsColumn}
            rows={overrideDetailsData}
            renderCell={renderCell}
            selectionMode="none"
            density="compact"
          />
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Close
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

LAGOverrideDetailsDialog.propTypes = {
  overrideDetailsData: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.objectOf(PropTypes.any),
      ])
    )
  ).isRequired,
  overrideDetailsColumn: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    )
  ).isRequired,
  groupKey: PropTypes.string.isRequired,
};

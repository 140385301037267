import axios from "../config/axios-config";
/* eslint-disable import/prefer-default-export */

let controller;
const fetchAccountMappings = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(
    "/api/crosssystemaccounts/accountmapping",
    data
  );
  const responseData = await response.data;
  return responseData;
};

const fetchMissingSourceSystems = async (sourceSystem, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(
    `/api/crosssystemaccounts/subscribers/${sourceSystem}`
  );
  const responseData = await response.data;
  return responseData;
};

const fetchExternalSourceAccounts = async (uri, token) => {
  if (controller) controller.abort();
  controller = new AbortController();
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(uri, {
    signal: controller.signal,
  });
  const responseData = await response.data;
  return responseData;
};

const fetchTypeaheadAccountNames = async (uri, token) => {
  if (controller) controller.abort();
  controller = new AbortController();
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(uri, {
    signal: controller.signal,
  });
  const responseData = await response.data;
  return responseData;
};

export const crossSystemAccountApi = {
  fetchAccountMappings,
  fetchExternalSourceAccounts,
  fetchMissingSourceSystems,
  fetchTypeaheadAccountNames,
};

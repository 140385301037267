import {
  Badge,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Text,
  useDialogContainer,
  View,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { error, success } from "@react/react-spectrum/Toast";
import ArrowRight from "@spectrum-icons/workflow/ArrowRight";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { eamApi } from "../../api/eamApi";
import useUserProfile from "../../context/user-context";
import useValidCountriesStateLookup from "../../context/isocode-context";

export default function ConfirmFlipMappingDialog({
  handleNextStep,
  fromAccount,
  toAccount,
  originalSourceAccount,
  newMapping,
  setPageLoading,
  setNewMapping,
}) {
  const [newOrigSource, setNewOrigSource] = useState();
  const [remapPayload, setRemapPayload] = useState({
    requested_by: "",
    orig_source_system_account_id: originalSourceAccount?.account_id,
    orig_source_system_name: originalSourceAccount?.source_system,
    sync_account: {
      crm_account_id: fromAccount?.account_id,
      crm_sourcesystem_name: fromAccount?.source_system,
    },
    target_sync_account: {},
  });
  const dialog = useDialogContainer();
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();
  const { validCountryStateCheck } = useValidCountriesStateLookup();

  useEffect(() => {
    const moveInAccount = newMapping?.find((acc) => acc?.status);
    setNewOrigSource(newMapping?.find((acc) => acc?.syncSource));
    setRemapPayload((prev) => ({
      ...prev,
      requested_by: user?.userId,
      target_sync_account: {
        crm_sourcesystem_name: moveInAccount?.source_system,
        copy_orig_crm: moveInAccount?.account_id?.toLowerCase() === "new",
        crm_account_id:
          moveInAccount?.account_id?.toLowerCase() === "new"
            ? null
            : moveInAccount?.account_id,
      },
    }));
  }, [newMapping]);

  useEffect(() => {
    if (
      newOrigSource &&
      `${newOrigSource?.account_id}:${newOrigSource?.source_system}` !==
        `${remapPayload?.orig_source_system_account_id}:${remapPayload?.orig_source_system_name}`
    ) {
      setRemapPayload((prev) => ({
        ...prev,
        set_orig_source: {
          orig_source_system_account_id: newOrigSource?.account_id,
          orig_source_system_name: newOrigSource?.source_system,
        },
      }));
    }
  }, [newOrigSource]);

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
    setPageLoading(false);
  };

  const handleRemapConfirm = () => {
    dialog.dismiss();
    setPageLoading(true);
    const moveInAccount = newMapping?.find((acc) => acc?.status);
    let syncOrigSourceData = null;
    if (
      originalSourceAccount?.address[0]?.country?.length !== 2 ||
      (validCountryStateCheck.countries.includes(
        originalSourceAccount?.address[0]?.country
      ) &&
        !validCountryStateCheck.countryStateMap[
          originalSourceAccount?.address[0]?.country
        ].includes(originalSourceAccount?.address[0]?.state))
    ) {
      syncOrigSourceData = {
        address: {
          country: moveInAccount?.address[0]?.country,
          state: moveInAccount?.address[0]?.state,
        },
      };
    }
    eamApi
      .remapExternalAccount(
        { ...remapPayload, orig_source_data: syncOrigSourceData },
        authState.accessToken
      )
      .then((data) => {
        setNewMapping(data?.mcs_synced_accounts);
        success(`Remap has been performed successfully!`, {
          timeout: 5000,
        });
        setPageLoading(false);
        handleNextStep();
      })
      .catch((e) => handleError(e));
  };

  return (
    <Dialog size="L" width="fit-content">
      <Heading>{`Confirm remap changes : ${originalSourceAccount?.account_id} [${originalSourceAccount?.source_system}]`}</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-500">
          <Text>Are you sure you want to change mapping as shown below?</Text>
          <Flex direction="row" gap="size-125" justifyContent="space-between">
            <Flex direction="column" justifyContent="center" gap="size-125">
              <Flex justifyContent="center">
                <Heading level={4}>Move-out account</Heading>
              </Flex>
              <View
                key={`${fromAccount?.account_id}:${fromAccount?.source_system}`}
                aria-labelledby={`${fromAccount?.source_system} source account id ${fromAccount?.account_id}`}
                width="fit-content"
                height="fit-content"
                UNSAFE_className="stepper-box"
                borderRadius="medium"
                borderWidth="thin"
                borderColor="gray-300"
                padding="size-250"
              >
                <Flex direction="column" gap="size-125">
                  <Heading
                    level={3}
                  >{`${fromAccount?.account_id} (${fromAccount?.source_system})`}</Heading>
                  <Flex direction="column">
                    <Text>{fromAccount?.account_name}</Text>
                    <Text>{`${fromAccount?.address[0]?.street}, ${
                      fromAccount?.address[0]?.city
                    }, ${fromAccount?.address[0]?.state}, ${
                      fromAccount?.address[0]?.country
                    } ${
                      fromAccount?.address[0]?.postalcode
                        ? `- `.concat(fromAccount?.address[0]?.postalcode)
                        : ""
                    }  `}</Text>
                  </Flex>
                </Flex>
              </View>
            </Flex>
            <Flex justifyContent="center" alignItems="center">
              <View>
                <ArrowRight color="informative" />
              </View>
            </Flex>
            <Flex direction="column" justifyContent="center" gap="size-125">
              <Flex justifyContent="center">
                <Heading level={4}>Move-in account</Heading>
              </Flex>

              <View
                key={`${toAccount?.account_id}:${toAccount?.source_system}`}
                aria-labelledby={`${toAccount?.source_system} source account id ${toAccount?.account_id}`}
                width="fit-content"
                height="fit-content"
                UNSAFE_className="stepper-box"
                borderRadius="medium"
                borderWidth="thin"
                borderColor="gray-300"
                padding="size-250"
              >
                <Flex direction="column" gap="size-125">
                  {newOrigSource?.account_id !== toAccount?.account_id &&
                  newOrigSource?.source_system !== toAccount?.source_system ? (
                    <Heading
                      level={3}
                    >{`${toAccount?.account_id} (${toAccount?.source_system})`}</Heading>
                  ) : (
                    <Flex gap="size-125">
                      <Heading
                        level={3}
                      >{`${newOrigSource?.account_id} (${newOrigSource?.source_system})`}</Heading>
                      <Badge
                        variant="seafoam"
                        UNSAFE_style={{ fontSize: "smaller" }}
                      >
                        New original source
                      </Badge>
                    </Flex>
                  )}
                  <Flex direction="column">
                    <Text>{toAccount?.account_name}</Text>
                    <Text>{`${toAccount?.address[0]?.street}, ${
                      toAccount?.address[0]?.city
                    }, ${toAccount?.address[0]?.state}, ${
                      toAccount?.address[0]?.country
                    } ${
                      toAccount?.address[0]?.postalcode
                        ? `- `.concat(toAccount?.address[0]?.postalcode)
                        : ""
                    }  `}</Text>
                  </Flex>
                </Flex>
              </View>
            </Flex>
          </Flex>
          {newOrigSource?.account_id !== originalSourceAccount?.account_id &&
            newOrigSource?.source_system !==
              originalSourceAccount?.source_system && (
              <Flex justifyContent="center">
                <View
                  key={`${newOrigSource?.account_id}:${newOrigSource?.source_system}`}
                  aria-labelledby={`${newOrigSource?.source_system} source account id ${newOrigSource?.account_id}`}
                  width="fit-content"
                  height="fit-content"
                  UNSAFE_className="stepper-box"
                  borderRadius="medium"
                  borderWidth="thin"
                  borderColor="gray-300"
                  padding="size-250"
                >
                  <Flex direction="column" gap="size-125">
                    <Flex gap="size-125">
                      <Heading
                        level={3}
                      >{`${newOrigSource?.account_id} (${newOrigSource?.source_system})`}</Heading>
                      <Badge
                        variant="seafoam"
                        UNSAFE_style={{ fontSize: "smaller" }}
                      >
                        New original source
                      </Badge>
                    </Flex>
                    <Flex direction="column">
                      <Text>{newOrigSource?.account_name}</Text>
                      <Text>{`${newOrigSource?.address[0]?.street}, ${
                        newOrigSource?.address[0]?.city
                      }, ${newOrigSource?.address[0]?.state}, ${
                        newOrigSource?.address[0]?.country
                      } ${
                        newOrigSource?.address[0]?.postalcode
                          ? `- `.concat(newOrigSource?.address[0]?.postalcode)
                          : ""
                      }  `}</Text>
                    </Flex>
                  </Flex>
                </View>
              </Flex>
            )}
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Cancel
        </Button>
        <Button variant="accent" onPress={handleRemapConfirm}>
          Confirm
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

ConfirmFlipMappingDialog.propTypes = {
  fromAccount: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
    ])
  ).isRequired,
  toAccount: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
    ])
  ).isRequired,
  originalSourceAccount: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
    ])
  ).isRequired,
  handleNextStep: PropTypes.func.isRequired,
  setPageLoading: PropTypes.func.isRequired,
  setNewMapping: PropTypes.func.isRequired,
  newMapping: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
};

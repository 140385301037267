import {
  ActionButton,
  Flex,
  Link as V3Link,
  Tooltip,
  TooltipTrigger,
  View,
} from "@adobe/react-spectrum";
import Edit from "@spectrum-icons/workflow/Edit";
import UserAdd from "@spectrum-icons/workflow/UserAdd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CamFunctions } from "../../constants/CamFunctions";
import { USER } from "../../constants/RoleType";
import { EditGroupsPath } from "../../constants/Routes";
import useUserProfile from "../../context/user-context";
import CamSlidingPane from "../Common/CamSlidingPane/CamSlidingPane";
import Table from "../Common/Table";
import LoadingDialog from "../Dialog/LoadingDialog";
import GroupMembersInfoForm from "../Forms/GroupsForm/GroupMembersInfoForm";
import SubscribeGroupForm from "../Forms/GroupsForm/SubscribeGroupForm";

export default function GroupsList({ filters, items, columns, loadingState }) {
  const [groups, setGroups] = useState([]);
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [sidePaneData, setSidePaneData] = useState([]);
  const [sidePaneTitle, setSidePaneTitle] = useState("");
  const [selectedGroup, setSelectedGroup] = useState({});

  const history = useHistory();

  const { user } = useUserProfile();
  const [isLoadingDialog, setLoadingDialog] = useState(false);

  useEffect(() => {
    setGroups(items);
  }, [items]);

  const handleOnSubscribe = (groupName, groupRole) => {
    setIsSidePaneOpen(false);
    setSidePaneData([]);
    setSelectedGroup({ name: groupName, role: groupRole });
    setSidePaneTitle("Subscribe");
    setIsSidePaneOpen(!isSidePaneOpen);
  };

  const renderActionMenu = (groupName, groupRole, groupMembers) => (
    <TooltipTrigger>
      <ActionButton
        isDisabled={
          groupMembers.filter((member) => member?.email === user?.email)
            .length > 0
        }
        isQuiet
        onPress={() => handleOnSubscribe(groupName, groupRole)}
      >
        <View>
          <UserAdd
            color={
              groupMembers.filter((member) => member?.email === user?.email)
                .length === 0
                ? "informative"
                : ""
            }
          />
        </View>
      </ActionButton>
      <Tooltip>Subscribe</Tooltip>
    </TooltipTrigger>
  );

  const renderManageMenu = (group) => (
    <TooltipTrigger>
      <ActionButton
        isQuiet
        onPress={() =>
          history.push({
            pathname: EditGroupsPath,
            state: {
              group,
            },
          })
        }
      >
        <View>
          <Edit color="informative" />
        </View>
      </ActionButton>
      <Tooltip>Edit</Tooltip>
    </TooltipTrigger>
  );

  const renderSidePane = (paneTitle, data) => (
    <V3Link
      isQuiet
      onPress={() => {
        setSidePaneData(data);
        setSidePaneTitle(paneTitle);
        setIsSidePaneOpen(!isSidePaneOpen);
      }}
    >
      View
    </V3Link>
  );

  const renderCamFunctions = (selectedCamFunctions) => {
    const camFunctionsLabels = [];
    const camFunctions = CamFunctions();
    selectedCamFunctions.forEach((camFunction) => {
      camFunctionsLabels.push(
        camFunctions.find((camFunc) => camFunc.id === camFunction)?.label
      );
    });
    return <span>{camFunctionsLabels?.join(", ")}</span>;
  };

  const renderCell = (colKey, row) => {
    if (colKey === "actions") {
      return renderActionMenu(row.groupName, USER, row.groupMembers);
    }
    if (colKey === "manage") {
      return renderManageMenu(row);
    }
    if (colKey === "groupMembers") {
      return renderSidePane("Group Members", row[colKey]);
    }
    if (colKey === "camFunctions") {
      return renderCamFunctions(row[colKey]);
    }
    return <span>{row[colKey]}</span>;
  };

  const filterTableData = () =>
    groups.filter((group) =>
      group?.groupName?.toLowerCase().includes(filters?.name?.toLowerCase())
    );

  return (
    <View>
      <LoadingDialog isOpen={isLoadingDialog} />
      <Flex
        margin="size-300"
        height="size-6000"
        width="70%"
        direction="column"
        gap="size-150"
        UNSAFE_style={{
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
        UNSAFE_className="stepper-box"
      >
        <Table
          columns={columns}
          renderCell={renderCell}
          rows={filterTableData()}
          loadingState={loadingState}
        />
      </Flex>
      {isSidePaneOpen && (
        <CamSlidingPane
          isPaneOpenFlag={isSidePaneOpen}
          handleOpenDialog={() => setIsSidePaneOpen(false)}
          paneTitle={sidePaneTitle}
          Component={
            sidePaneData?.length > 0 ? (
              <GroupMembersInfoForm data={sidePaneData} />
            ) : (
              <SubscribeGroupForm
                groupDetails={selectedGroup}
                handleOpenDialog={() => setIsSidePaneOpen(false)}
                setLoadingDialog={setLoadingDialog}
              />
            )
          }
        />
      )}
    </View>
  );
}

GroupsList.defaultProps = {
  filters: {},
  items: [],
  loadingState: "",
};
GroupsList.propTypes = {
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string])),
  items: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number,
        PropTypes.bool,
      ])
    )
  ),
  columns: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    )
  ).isRequired,
  loadingState: PropTypes.string,
};

export const StandardizedAccountsLabel = () => {
  const COLUMNS = [
    {
      title: "CAM ID",
      key: "camid",
    },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Street",
      key: "street",
    },
    {
      title: "Street Sup",
      key: "street_sup",
    },
    {
      title: "City",
      key: "city",
    },
    {
      title: "Postal Code",
      key: "postalcode",
    },
    {
      title: "State",
      key: "state",
    },
    {
      title: "Country",
      key: "country",
    },
    {
      title: "Domain",
      key: "domain",
    },
    {
      title: "Duns Number",
      key: "duns_number",
    },
    {
      title: "Global Parent ID",
      key: "gtm_global_parent_id",
    },
    {
      title: "Global Parent Name",
      key: "gtm_global_parent_name",
    },
    {
      title: "Global Sub ID",
      key: "gtm_global_sub_id",
    },
    {
      title: "Global Sub Name",
      key: "gtm_global_sub_name",
    },
    {
      title: "Created At",
      key: "sliding_created_at",
    },
    {
      title: "Last Updated",
      key: "sliding_last_updated",
    },
  ];

  return COLUMNS;
};

export const SourceSystemAccountsLabel = () => {
  const COLUMNS = [
    {
      title: "Src Account ID",
      key: "account_id",
    },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Address",
      key: "address",
    },
    {
      title: "Source System",
      key: "source_system",
    },
    {
      title: "Industry",
      key: "crm_industry",
    },
    {
      title: "DX Sales Major Segment",
      key: "dx_sales_major_segment",
    },
    {
      title: "DX Sales Minor Segment",
      key: "dx_sales_minor_segment",
    },
    {
      title: "Global Parent ID",
      key: "global_parent_id",
    },
    {
      title: "Global Parent Name",
      key: "global_parent_name",
    },
    {
      title: "Global Sub ID",
      key: "global_sub_id",
    },
    {
      title: "Global Sub Name",
      key: "global_sub_name",
    },
    {
      title: "Approval Status",
      key: "approval_status",
    },
    // {
    //   title: "Duplicate",
    //   key: "dup_flag",
    // },
    {
      title: "Created At",
      key: "sliding_created_at",
    },
    {
      title: "Last Updated",
      key: "sliding_last_updated",
    },
    {
      title: "Dme Sales Major Segment",
      key: "dme_sales_major_segment",
    },
  ];

  return COLUMNS;
};

export const AddressLabel = () => {
  const COLUMNS = [
    {
      title: "Street",
      key: "street",
    },
    {
      title: "Street Sup",
      key: "street_sup",
    },
    {
      title: "City",
      key: "city",
    },
    {
      title: "Postal Code",
      key: "postalcode",
    },
    {
      title: "State",
      key: "state",
    },
    {
      title: "Country",
      key: "country",
    },
  ];

  return COLUMNS;
};

export const EAMSourceSystemAccountLabel = () => {
  const COLUMNS = [
    {
      title: "Account ID",
      key: "account_id",
    },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Address",
      key: "address",
    },
    {
      title: "Source System",
      key: "source_system",
    },
    {
      title: "Industry",
      key: "crm_industry",
    },
    {
      title: "DX Sales Major Segment",
      key: "dx_sales_major_segment",
    },
    {
      title: "DX Sales Minor Segment",
      key: "dx_sales_minor_segment",
    },
    {
      title: "Global Parent ID",
      key: "global_parent_id",
    },
    {
      title: "Global Parent Name",
      key: "global_parent_name",
    },
    {
      title: "Global Sub ID",
      key: "global_sub_id",
    },
    {
      title: "Global Sub Name",
      key: "global_sub_name",
    },
    {
      title: "Duplicate",
      key: "dup_flag",
    },
    {
      title: "Approval Status",
      key: "approval_status",
    },
    {
      title: "Dme Sales Major Segment",
      key: "dme_sales_major_segment",
    },
  ];

  return COLUMNS;
};

export const LAGGroupLabel = () => {
  const COLUMNS = [
    {
      title: "DOCID",
      key: "id",
    },
    {
      title: "LAGID",
      key: "lag_id",
    },
    {
      title: "CAMID",
      key: "cam_id",
    },
    {
      title: "ECCID",
      key: "ecc_id",
    },
    {
      title: "Account Name",
      key: "account_name",
    },
    {
      title: "Country",
      key: "country",
    },
    {
      title: "Domain",
      key: "domain",
    },
    {
      title: "Source System",
      key: "source_system",
    },
    {
      title: "Group",
      key: "group_key",
    },
    {
      title: "Related Group",
      key: "related_group_key",
    },
    {
      title: "Description",
      key: "group_description",
    },
    {
      title: "IMS Org Name",
      key: "ims_org_name",
    },
    {
      title: "IMS Org ID",
      key: "ims_org_id",
    },
  ];

  return COLUMNS;
};

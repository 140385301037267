import {
  Flex,
  Picker,
  Item,
  SearchField,
  ComboBox,
  useAsyncList,
  Button,
  Text,
  Link as V3Link,
  TooltipTrigger,
  ActionButton,
  Tooltip,
  View,
  DialogContainer,
} from "@adobe/react-spectrum";
import Search from "@spectrum-icons/workflow/Search";
import Add from "@spectrum-icons/workflow/Add";
import Edit from "@spectrum-icons/workflow/Edit";
import ViewDetail from "@spectrum-icons/workflow/ViewDetail";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { error } from "@react/react-spectrum/Toast";
import { LAGLookup } from "../../constants/Columns";
import Table from "../../components/Common/Table";
import ViewExtendedAttributesForm from "../../components/Forms/LAG/ViewExtendedAttributesForm";
import CamSlidingPane from "../../components/Common/CamSlidingPane/CamSlidingPane";
import LAGOverrideDetailsDialog from "../../components/Dialog/LAGOverrideDetailsDialog";
import { LAGGroupLabel } from "../../constants/SlidingPaneLabels";
import { ADMIN, OPERATOR, SUPERUSER } from "../../constants/RoleType";
import useValidUserAccess from "../../hooks/useValidUserAccess";
import { LAG } from "../../constants/CamFunctions";
import { lagApi } from "../../api/lagApi";
import { LAGSearchIdOptions } from "../../constants/PickerOptions";
import {
  CreateLogicalAccountGroupingPath,
  OverrideLogicalAccountGroupingPath,
} from "../../constants/Routes";

export default function LogicalAccountGrouping() {
  const [groupKeys, setGroupKeys] = useState([]);
  const [filters, setFilters] = useState({
    typeOfAccount: "eccId",
    id: "",
    group: "",
    domain: "",
    country: "",
  });
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [sidePaneData, setSidePaneData] = useState([]);
  const sidePaneLabels = LAGGroupLabel();
  const [selectedGroupId, setSelectedGroupId] = useState(new Set([]));
  const [lagType, setLagType] = useState("Create");
  const [searched, setSearched] = useState(false);
  const [viewOverrideDetails, setViewOverrideDetails] = useState(false);
  const [overrideDetailsData, setOverrideDetailsData] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [overrideDetailsColumns, setOverrideDetailsColumns] = useState([
    { uid: "group_key", name: "Group key" },
  ]);
  const options = LAGSearchIdOptions();

  const history = useHistory();
  const lookupColumns = LAGLookup();
  const isLagActionAllowed = useValidUserAccess(
    [ADMIN, SUPERUSER, OPERATOR],
    LAG
  );
  const { authState } = useOktaAuth();
  const [isSearchLoading, setSearchLoading] = useState("");

  useEffect(() => {
    lagApi.fetchGroupKeys(authState.accessToken).then((data) => {
      data.groups.push({ group_key: "", group_description: "All" });
      setGroupKeys(data.groups);
    });
  }, []);

  // useEffect(() => {
  //   if (history?.location?.state?.searchFilter) {
  //     setFilters((prev) => ({
  //       ...prev,
  //       ...history?.location?.state?.searchFilter,
  //     }));
  //   }
  // }, []);

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    if (selectedGroupId.size === 0) setLagType("Create");
    else setLagType("Extend");
  }, [selectedGroupId]);

  const list = useAsyncList({
    initialFilterText: "",
    async load({ filterText, cursor }) {
      let json = {
        results: [],
      };
      const lookupObj = cursor || {
        lag_id: filters.typeOfAccount === "lagId" ? filters.id : "",
        ecc_id: filters.typeOfAccount === "eccId" ? filters.id : "",
        cam_id: filters.typeOfAccount === "camId" ? filters.id : "",
        group_key: filters.group,
        domain: filters.domain,
        country: filters.country,
        from: 0,
      };
      if (filterText !== "") {
        setSearchLoading("loading");
        json = await lagApi.fetchLAGLookup(lookupObj, authState.accessToken);
      }
      const results = json?.results || [];

      setSearchLoading("");
      return {
        items: results,
        cursor:
          json?.next_from === -1
            ? null
            : {
                ...lookupObj,
                from: json?.next_from,
                visited_groups: json?.visited_groups,
                point_in_time_token: json?.point_in_time_token,
              },
      };
    },
    getKey: (item) => item?.id,
  });

  const countryComboBoxList = useAsyncList({
    initialFilterText: "",
    async load({ filterText }) {
      let json = [];
      if (filterText !== "") {
        json = await lagApi.fetchTypeaheadResults(
          {
            field_name: "country",
            value: filterText,
          },
          authState.accessToken
        );
        json = json.map((name, index) => ({ id: index, name }));
      }

      return {
        items: json,
      };
    },
  });

  const domainComboBoxList = useAsyncList({
    initialFilterText: "",
    async load({ filterText }) {
      let json = [];
      if (filterText !== "") {
        json = await lagApi.fetchTypeaheadResults(
          {
            field_name: "domain",
            value: filterText,
          },
          authState.accessToken
        );
        json = json.map((name, index) => ({ id: index, name }));
      }

      return {
        items: json,
      };
    },
  });

  const handleViewOverrideDetails = (groupKey, accountId) => {
    setViewOverrideDetails(true);
    lagApi
      .fetchOverrideGroupDetails(
        { group_key: groupKey, account_id: accountId },
        authState.accessToken
      )
      .then((data) => {
        setOverrideDetailsData(data);
        const dynamicColumns = [];
        Object.keys(data[0]).forEach((attr) => {
          if (attr !== "id") {
            dynamicColumns.push({
              uid: attr,
              name:
                attr.charAt(0).toUpperCase() +
                attr.slice(1).replaceAll("_", " "),
            });
          }
        });
        setOverrideDetailsColumns([
          ...overrideDetailsColumns,
          ...dynamicColumns,
        ]);
      })
      .catch((e) => handleError(e));
  };

  const renderCell = (colKey, row) => {
    let cellStyle = {};
    if (colKey === "lag_id") {
      return (
        <Text>
          <V3Link
            isQuiet
            onPress={() => {
              setSidePaneData(row);
              setIsSidePaneOpen(!isSidePaneOpen);
            }}
          >
            {row[colKey]}
          </V3Link>
        </Text>
      );
    }
    if (colKey === "overridden") {
      if (row[colKey] === "Y") {
        return (
          <TooltipTrigger>
            <ActionButton
              isQuiet
              onPress={() =>
                handleViewOverrideDetails(row.group_key, row.ecc_id)
              }
            >
              <View>
                <ViewDetail color="informative" />
              </View>
            </ActionButton>
            <Tooltip>View Override Details</Tooltip>
          </TooltipTrigger>
        );
      }
      return <></>;
    }
    if (row?.exact_match) cellStyle = { color: "green" };
    if (colKey === "group_description") {
      return (
        <Text UNSAFE_style={cellStyle}>
          {
            groupKeys.find((attr) => attr.group_key === row.group_key)
              .group_description
          }
        </Text>
      );
    }
    return <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };

  const handleCreateLag = () => {
    history.push(CreateLogicalAccountGroupingPath, {
      createType: lagType,
      searchFilter: filters,
      extendGroup: lagType === "Extend" ? selectedGroup : null,
    });
  };

  const handleOverrideLag = () => {
    history.push(OverrideLogicalAccountGroupingPath, {
      overrideGroup: selectedGroup,
      searchFilter: filters,
    });
  };

  const handleSelectGroup = (groupId) => {
    setSelectedGroupId(groupId);
    setSelectedGroup(list.items.find((obj) => obj.id === groupId.anchorKey));
  };

  return (
    <Flex direction="row" gap="size-250">
      <Flex direction="column" gap="size-125" width="90%">
        <Flex gap="size-150" wrap marginTop="size-300">
          <Picker
            width="115px"
            label="Choose type of account"
            items={options}
            selectedKey={filters.typeOfAccount}
            onSelectionChange={(selected) =>
              setFilters({ ...filters, typeOfAccount: selected })
            }
          >
            {(item) => <Item key={item.id}>{item.name}</Item>}
          </Picker>
          <SearchField
            value={filters.id}
            onChange={(value) => setFilters({ ...filters, id: value })}
            label="Search by id"
            onSubmit={() => {
              list.setFilterText(filters);
              setSearched(true);
            }}
          />
          <Picker
            width="120px"
            label="Group key"
            items={groupKeys}
            selectedKey={filters.group}
            onSelectionChange={(selected) =>
              setFilters({ ...filters, group: selected })
            }
          >
            {(item) => (
              <Item key={item.group_key}>
                {item.group_key === "" ? "All" : item.group_key}
              </Item>
            )}
          </Picker>
          <ComboBox
            label="Domain"
            items={domainComboBoxList.items}
            inputValue={
              domainComboBoxList.filterText
              // || history?.location?.state?.searchFilter?.domain
            }
            onInputChange={domainComboBoxList.setFilterText}
            loadingState={domainComboBoxList.loadingState}
            onSelectionChange={(value) => {
              setFilters({ ...filters, domain: value });
            }}
          >
            {(item) => <Item key={item?.name}>{item?.name}</Item>}
          </ComboBox>
          <ComboBox
            label="Country"
            items={countryComboBoxList.items}
            inputValue={
              countryComboBoxList.filterText
              // || history?.location?.state?.searchFilter?.country
            }
            onInputChange={countryComboBoxList.setFilterText}
            loadingState={countryComboBoxList.loadingState}
            onSelectionChange={(value) =>
              setFilters({ ...filters, country: value })
            }
          >
            {(item) => <Item key={item?.name}>{item?.name}</Item>}
          </ComboBox>
          <Flex alignItems="end" gap="size-125">
            <Button
              variant="accent"
              onPress={() => {
                list.setFilterText(filters);
                setSearched(true);
              }}
              isDisabled={
                (filters.id === "" || filters.id === null) &&
                (filters.domain === "" || filters.domain === null) &&
                (filters.country === "" || filters.country === null) &&
                filters.group === ""
              }
            >
              <Search />
              <Text>Search</Text>
            </Button>
          </Flex>
        </Flex>

        {searched && (
          <Flex direction="column" gap="size-150" marginTop="size-300">
            {isLagActionAllowed && (
              <Flex justifyContent="right" gap="size-125">
                <Button variant="accent" onPress={handleCreateLag}>
                  <Add aria-label="Create LAG" />
                  <Text>{`${lagType} LAG`}</Text>
                </Button>

                <Button
                  variant="accent"
                  onPress={handleOverrideLag}
                  isDisabled={selectedGroupId.size === 0}
                >
                  <Edit aria-label="Override LA" />
                  <Text>Override LAG</Text>
                </Button>
              </Flex>
            )}

            <Flex
              direction="row"
              gap="size-125"
              height={list.items > 0 ? "fit-content" : "static-size-4600"}
              UNSAFE_style={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
              }}
              UNSAFE_className="stepper-box"
            >
              <Table
                columns={lookupColumns}
                rows={list.items}
                renderCell={renderCell}
                loadingState={
                  isSearchLoading !== "" ? isSearchLoading : list.loadingState
                }
                onLoadMore={list.loadMore}
                density="compact"
                selectionMode={isLagActionAllowed ? "single" : "none"}
                onSelectionChange={handleSelectGroup}
                selectedKeys={selectedGroupId}
              />
            </Flex>
            <DialogContainer onDismiss={() => setViewOverrideDetails(false)}>
              {viewOverrideDetails && (
                <LAGOverrideDetailsDialog
                  overrideDetailsColumn={overrideDetailsColumns}
                  overrideDetailsData={overrideDetailsData}
                  groupKey="GRP1"
                />
              )}
            </DialogContainer>
          </Flex>
        )}

        {isSidePaneOpen && (
          <CamSlidingPane
            isPaneOpenFlag={isSidePaneOpen}
            handleOpenDialog={() => setIsSidePaneOpen(false)}
            paneTitle="View Extended Attributes"
            Component={
              <ViewExtendedAttributesForm
                data={sidePaneData}
                labels={sidePaneLabels}
                groupKeys={groupKeys}
              />
            }
          />
        )}
      </Flex>
    </Flex>
  );
}

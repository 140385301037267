import {
  ActionButton,
  Button,
  Flex,
  Heading,
  Text,
  View,
} from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import { error } from "@react/react-spectrum/Toast";
import { useOktaAuth } from "@okta/okta-react";
import Stepper from "../../../components/Common/Stepper/Stepper";
import { CreateNewLagGroupSteps } from "../../../constants/StepperItems";
import { LookupLogicalAccountGroupingPath } from "../../../constants/Routes";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import { lagApi } from "../../../api/lagApi";
import LoadingDialog from "../../../components/Dialog/LoadingDialog";

export default function CreateLAG() {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(1);
  const [createLagHeading, setCreateLagHeading] = useState();
  const [newLagRule, setNewLagRule] = useState([]);
  const [createGroupPayload, setCreateGroupPayload] = useState({
    group_key: "",
    group_description: "",
    requested_by: {},
    approvers_list: [],
    attributes: newLagRule,
  });
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const STEP_ITEMS = CreateNewLagGroupSteps();
  const { authState } = useOktaAuth();
  const [isPageLoading, setPageLoading] = useState(false);

  const handleError = (e) => {
    setPageLoading(false);
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    if (history?.location?.state?.createType === "Create")
      setCreateLagHeading("Create New LAG");
    else
      setCreateLagHeading(
        `Extend ${history?.location?.state?.extendGroup.group_key}`
      );
  });

  const handlePrevStep = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleNextStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleSubmitGroupRequest = () => {
    setPageLoading(true);
    lagApi
      .sendGroupRequest(createGroupPayload, authState.accessToken)
      .then(() => {
        setPageLoading(false);
        setConfirmationMessage("Group Request Successfully Created!");
        handleNextStep();
      })
      .catch((e) => handleError(e));
  };

  const handleBack = () => {
    if (activeStep === 3)
      history.push(LookupLogicalAccountGroupingPath, {
        searchFilter: {},
      });
    history.push(LookupLogicalAccountGroupingPath, {
      searchFilter: history?.location?.state?.searchFilter,
    });
  };

  return (
    <Flex direction="row" gap="size-125">
      <LoadingDialog isOpen={isPageLoading} />
      <Flex direction="column" gap="size-125" margin="size-300" width="80%">
        <Flex gap="size-125" justifyContent="space-between">
          <Heading>{createLagHeading}</Heading>
        </Flex>
        <Flex direction="column" gap="size-125">
          <View
            backgroundColor="static-white"
            borderRadius="medium"
            borderWidth="thin"
            borderColor="gray-300"
            padding="size-125"
            UNSAFE_className="stepper-box"
          >
            <Stepper items={STEP_ITEMS} activeItemStep={activeStep} />
          </View>
          <View data-multi-step UNSAFE_className="wrapper">
            <Step1
              activeStep={activeStep}
              lagType={history?.location?.state?.createType}
              extendGroupId={history?.location?.state?.extendGroup?.group_key}
              searchFilter={history?.location?.state?.searchFilter}
              setNewLagRule={setNewLagRule}
            />
            <Step2
              activeStep={activeStep}
              newLagRule={newLagRule}
              createGroupPayload={createGroupPayload}
              setCreateGroupPayload={setCreateGroupPayload}
            />
            <Step3
              activeStep={activeStep}
              createGroupPayload={createGroupPayload}
              confirmationMessage={confirmationMessage}
            />
          </View>
          <Flex marginTop="size-300" justifyContent="space-between">
            <Flex>
              <ActionButton isQuiet onPress={handleBack}>
                <ChevronLeft color="informative" />
                <Text>Logical Account Grouping</Text>
              </ActionButton>
            </Flex>
            <Flex gap="size-125">
              {activeStep > 1 && activeStep <= STEP_ITEMS.length - 1 && (
                <Button variant="primary" onPress={handlePrevStep}>
                  <Text>Previous</Text>
                </Button>
              )}
              {activeStep <= STEP_ITEMS.length - 1 &&
                activeStep !== STEP_ITEMS.length - 1 && (
                  <Button
                    variant="accent"
                    isDisabled={newLagRule.length === 0}
                    onPress={() => handleNextStep()}
                  >
                    <Text>Next</Text>
                  </Button>
                )}

              {activeStep === STEP_ITEMS.length - 1 && (
                <Button
                  variant="accent"
                  onPress={handleSubmitGroupRequest}
                  isDisabled={createGroupPayload?.effective_date === undefined}
                >
                  Submit
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

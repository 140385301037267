import axios from "../config/axios-config";

const generateEmbedToken = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get("/api/embedtoken");
  const responseData = await response.data;
  responseData.createdAt = Date.now();
  sessionStorage.setItem("powerbi-token-storage", JSON.stringify(responseData));
  return responseData;
};

const getEmbedToken = async (oktaToken) => {
  if (sessionStorage.getItem("powerbi-token-storage")) {
    const powerbiTokenStorage = JSON.parse(
      sessionStorage.getItem("powerbi-token-storage")
    );
    const elapsedTime = Date.now() - powerbiTokenStorage.createdAt;
    // 55 min in milliseconds
    if (elapsedTime >= 3300000) {
      return generateEmbedToken(oktaToken).then((token) => token.token);
    }
    return powerbiTokenStorage.token;
  }
  return generateEmbedToken(oktaToken).then((token) => token.token);
};

export default getEmbedToken;

import { useOktaAuth } from "@okta/okta-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { RootPath } from "../../constants/Routes";
import useUserProfile from "../../context/user-context";
import LoadingDialog from "../Dialog/LoadingDialog";
import CustomUnauthorized from "../Unauthorized/Unauthorized";

const RequireRbac = ({
  allowedFunctions,
  children,
  redirectPath,
  showUnauthorized,
}) => {
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  const pages = () => {
    if (
      allowedFunctions?.every((camFunc) =>
        user?.camFunctions?.includes(camFunc)
      )
    )
      return children;
    if (authState?.isAuthenticated) {
      if (showUnauthorized) return <CustomUnauthorized />;

      return <Redirect to={redirectPath} />;
    }

    return <Redirect to={RootPath} />;
  };

  return (
    <>
      <LoadingDialog isOpen={isLoading} />
      {pages()}
    </>
  );
};

RequireRbac.defaultProps = {
  children: [],
  allowedFunctions: [],
  redirectPath: "",
  showUnauthorized: false,
};
RequireRbac.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  allowedFunctions: PropTypes.arrayOf(PropTypes.string),
  redirectPath: PropTypes.string,
  showUnauthorized: PropTypes.bool,
};

export default withRouter(RequireRbac);

import {
  ActionButton,
  Button,
  ButtonGroup,
  ComboBox,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Item,
  Link as V3Link,
  Picker,
  SearchField,
  Text,
  TextField,
  useAsyncList,
  useDialogContainer,
  View,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import AddCircle from "@spectrum-icons/workflow/AddCircle";
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { crossSystemAccountApi } from "../../api/lookupApi";

import { EAMSearchSourceAccountColumns } from "../../constants/Columns";
import { EAMSourceSystemAccountSearchTypeOptions } from "../../constants/PickerOptions";
import { SourceSystemAccountsLabel } from "../../constants/SlidingPaneLabels";
import CamSlidingPane from "../Common/CamSlidingPane/CamSlidingPane";
import Table from "../Common/Table";
import AccountMappingForm from "../Forms/AccountMappingForm/AccountMappingForm";

export default function SearchSourceAccountsDialog({
  sourceSystem,
  setSyncSet,
}) {
  const [searchSourceAccountsFilter, setSearchSourceAccountsFilter] = useState({
    type: "account_id",
    text: "",
  });
  const [selectedAccount, setSelectedAccount] = useState();
  const searchSourceAccountColumns = EAMSearchSourceAccountColumns();
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [sidePaneData, setSidePaneData] = useState([]);
  const [sidePaneTitle, setSidePaneTitle] = useState("");
  const [sidePaneLabels, setSidePaneLabels] = useState([]);
  const dialog = useDialogContainer();
  const { authState } = useOktaAuth();
  const options = EAMSourceSystemAccountSearchTypeOptions();

  const list = useAsyncList({
    initialFilterText: "",
    async load({ filterText, cursor }) {
      let json = {
        results: [],
      };
      if (filterText !== "") {
        const uri =
          cursor ||
          `/api/crosssystemaccounts/sourcesystemaccounts/${searchSourceAccountsFilter.type}?searchValue=${filterText}&from=0&sourceSystem=${sourceSystem}`;
        json = await crossSystemAccountApi.fetchExternalSourceAccounts(
          uri,
          authState.accessToken
        );
      }

      let results = json?.results || [];
      if (list.items.length > 0) {
        results = json?.results?.filter((acc) =>
          list.items.every((listAcc) => listAcc?.id !== acc?.id)
        );
      }

      return {
        items: results,
        cursor: json?.next_url,
      };
    },
    getKey: (item) => item?.id,
  });

  const comboBoxList = useAsyncList({
    initialFilterText: "",
    async load({ filterText }) {
      let json = [];
      if (filterText !== "") {
        const uri = `/api/crosssystemaccounts/searchcompletionsuggester?accountName=${filterText}`;
        json = await crossSystemAccountApi.fetchTypeaheadAccountNames(
          uri,
          authState.accessToken
        );
        json = json.map((name, index) => ({ id: index, name }));
      }

      return {
        items: json,
      };
    },
  });

  const setSidePaneDetails = (data, title, labels) => {
    setSidePaneLabels(labels);
    setSidePaneData(data);
    setSidePaneTitle(title);
    setIsSidePaneOpen(!isSidePaneOpen);
  };

  const renderCell = (colKey, row) => {
    if (colKey === "actions") {
      return (
        <ActionButton
          isQuiet
          onPress={() => {
            setSelectedAccount(row);
            list?.items?.forEach((acc) => {
              if (acc?.id === row?.id)
                list.update(acc?.id, { ...acc, selected: true });
              else {
                list.update(acc?.id, { ...acc, selected: false });
              }
            });
          }}
        >
          <View>
            {row?.selected ||
            (selectedAccount?.account_id === row?.account_id &&
              selectedAccount?.source_system === row?.source_system) ? (
              <CheckmarkCircle color="informative" />
            ) : (
              <AddCircle color="positive" />
            )}
          </View>
        </ActionButton>
      );
    }
    if (colKey === "account_id")
      return (
        <V3Link
          isQuiet
          onPress={() => {
            setSidePaneDetails(
              row,
              row.account_name,
              SourceSystemAccountsLabel()
            );
          }}
        >
          {row[colKey]}
        </V3Link>
      );

    if (colKey === "address_key") {
      const address = `${row.address[0].street ? row.address[0].street : ""} ${
        row.address[0].street_sup ? row.address[0].street_sup : ""
      } ${row.address[0].city ? row.address[0].city : ""}, ${
        row.address[0].state ? row.address[0].state : ""
      } ${row.address[0].country ? row.address[0].country : ""} ${
        row.address[0].postalcode ? row.address[0].postalcode : ""
      }`;
      return <Text>{address}</Text>;
    }

    return <Text>{row[colKey]}</Text>;
  };

  return (
    <Dialog size="L" width="70rem">
      <Heading>Search source accounts</Heading>
      <Divider />
      <Content>
        <Flex marginTop="size-300" gap="size-125" wrap direction="column">
          <Flex gap="size-150">
            <Picker
              label="Choose type of search"
              items={options}
              selectedKey={searchSourceAccountsFilter?.type}
              onSelectionChange={(selected) => {
                setSearchSourceAccountsFilter({
                  text: "",
                  type: selected,
                });
                list.setFilterText("");
              }}
            >
              {(item) => <Item key={item.id}>{item.name}</Item>}
            </Picker>

            {searchSourceAccountsFilter?.type === "account_name" ? (
              <ComboBox
                label="Enter text"
                items={comboBoxList.items}
                inputValue={comboBoxList.filterText}
                onInputChange={comboBoxList.setFilterText}
                loadingState={comboBoxList.loadingState}
                onSelectionChange={(key) => {
                  comboBoxList.setFilterText(key);
                  list.setFilterText(key);
                }}
              >
                {(item) => <Item key={item?.name}>{item?.name}</Item>}
              </ComboBox>
            ) : (
              <SearchField
                label="Enter text"
                value={searchSourceAccountsFilter?.text}
                onChange={(value) => {
                  setSearchSourceAccountsFilter((prev) => ({
                    ...prev,
                    text: value,
                  }));
                }}
                onSubmit={() =>
                  list.setFilterText(searchSourceAccountsFilter?.text)
                }
              />
            )}

            <TextField label="Source system" value={sourceSystem} isReadOnly />
          </Flex>
          <Flex
            direction="row"
            gap="size-125"
            height="size-3600"
            marginTop="size-300"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={searchSourceAccountColumns}
              rows={list.items}
              renderCell={renderCell}
              loadingState={list.loadingState}
              onLoadMore={list.loadMore}
              density="compact"
            />
          </Flex>
        </Flex>
        {isSidePaneOpen && (
          <CamSlidingPane
            isPaneOpenFlag={isSidePaneOpen}
            handleOpenDialog={() => setIsSidePaneOpen(false)}
            paneTitle={sidePaneTitle}
            Component={
              <AccountMappingForm data={sidePaneData} labels={sidePaneLabels} />
            }
          />
        )}
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Cancel
        </Button>
        <Button
          variant="accent"
          isDisabled={!(selectedAccount && selectedAccount?.id)}
          onPress={() => {
            setSyncSet((prev) =>
              prev.map((prevItem) => {
                if (prevItem?.source_system === sourceSystem)
                  return { ...selectedAccount, isNewAccount: true };
                return prevItem;
              })
            );
            dialog.dismiss();
          }}
        >
          Confirm
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

SearchSourceAccountsDialog.propTypes = {
  sourceSystem: PropTypes.string.isRequired,
  setSyncSet: PropTypes.func.isRequired,
};

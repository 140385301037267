import React from "react";
import { Button, Flex, Form, TextField, View } from "@adobe/react-spectrum";
import PropTypes from "prop-types";

export default function OverrideGroupForm({
  overrideAttributes,
  overrideGroup,
  groupColumns,
  setIsSidePaneOpen,
  overridenAttributes,
  setOverridenAttributes,
}) {
  const handleOnSubmit = () => {
    setIsSidePaneOpen(false);
  };

  return (
    <Form
      UNSAFE_style={{ backgroundColor: "#F5F5F5" }}
      aria-labelledby="LAG Override Form"
    >
      <Flex direction="column" gap="size-500">
        {Object.keys(overrideGroup).map((attr) => {
          if (
            groupColumns.includes(attr) &&
            !Object.keys(overrideAttributes).includes(attr)
          )
            return (
              <TextField
                label={
                  attr.charAt(0).toUpperCase() + attr.slice(1).replace("_", " ")
                }
                value={overrideGroup[attr]}
                isDisabled
              />
            );
          return <></>;
        })}
        {Object.keys(overrideAttributes).map((attr) => (
          <TextField
            label={
              attr.charAt(0).toUpperCase() + attr.slice(1).replace("_", " ")
            }
            defaultValue={
              overridenAttributes &&
              Object.keys(overridenAttributes).includes(attr)
                ? overridenAttributes[attr]
                : overrideGroup[attr]
            }
            onChange={(value) =>
              setOverridenAttributes((prev) => ({ ...prev, [attr]: value }))
            }
          />
        ))}
        <View>
          <Button
            variant="accent"
            onPress={handleOnSubmit}
            isDisabled={Object.keys(overridenAttributes).length === 0}
          >
            Submit
          </Button>
        </View>
      </Flex>
    </Form>
  );
}

OverrideGroupForm.propTypes = {
  overrideAttributes: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
      PropTypes.bool,
    ])
  ).isRequired,
  overrideGroup: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    ])
  ).isRequired,
  groupColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  overridenAttributes: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    ])
  ).isRequired,
  setOverridenAttributes: PropTypes.func.isRequired,
  setIsSidePaneOpen: PropTypes.func.isRequired,
};

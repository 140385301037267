import React from "react";
import {
  Divider,
  Flex,
  View,
  Text,
  Link as V3Link,
  Footer,
} from "@adobe/react-spectrum";

export default function CustomFooter() {
  return (
    <View padding="2rem 1rem">
      <Divider size="M" />
      <Flex justifyContent="space-between" marginTop="1rem">
        <Footer>
          <Text>
            For any questions or feedback, please{" "}
            <V3Link>
              <a href="mailto:cbs-engg@adobe.com">contact us</a>
            </V3Link>
            . |{" "}
            <V3Link>
              <a
                href="https://wiki.corp.adobe.com/display/CBS/Core+Business+Services"
                target="_blank"
                rel="noopener noreferrer"
              >
                Documentation
              </a>
            </V3Link>
          </Text>
        </Footer>
        <Footer>
          <Text>&copy; Adobe Incorporated. All Rights Reserved.</Text>
        </Footer>
      </Flex>
    </View>
  );
}

import {
  Badge,
  Flex,
  Heading,
  Link as V3Link,
  Text,
  View,
} from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Table from "../../../../../components/Common/Table";
import { EAMColumns } from "../../../../../constants/Columns";
import { EAMSourceSystemAccountLabel } from "../../../../../constants/SlidingPaneLabels";

export default function SplitPickOrigStep({
  survivingSyncSet,
  newSyncSet,
  activeStep,
  setSidePaneDetails,
  setCompleteSurvivingSyncSet,
  setCompleteNewSyncSet,
}) {
  const [survivingSyncSourceKey, setSurvivingSyncSourceKey] = useState();
  const [newSyncSourceKey, setNewSyncSourceKey] = useState();
  const [incompleteSurvivingSyncSet, setIncompleteSurvivingSyncSet] =
    useState(survivingSyncSet);
  const [incompleteNewSyncSet, setIncompleteNewSyncSet] = useState(newSyncSet);
  const eamColumns = EAMColumns();

  useEffect(() => {
    if (
      survivingSyncSet &&
      survivingSyncSet.find((acc) => acc?.syncSource)?.id
    ) {
      setSurvivingSyncSourceKey(
        new Set([survivingSyncSet.find((acc) => acc?.syncSource)?.id])
      );
    }
    if (survivingSyncSet?.length === 1)
      setSurvivingSyncSourceKey(new Set([survivingSyncSet[0].id]));
  }, [survivingSyncSet]);

  useEffect(() => {
    if (newSyncSet && newSyncSet.find((acc) => acc?.syncSource)?.id) {
      setIncompleteNewSyncSet((prev) =>
        prev.map((prevItem) => {
          if (prevItem?.syncSource) return { ...prevItem, syncSource: false };
          return prevItem;
        })
      );
    }
  }, [newSyncSet]);

  useEffect(() => {
    if (survivingSyncSourceKey) {
      setCompleteSurvivingSyncSet((prev) =>
        prev.map((prevItem) => {
          if (prevItem?.id === [...survivingSyncSourceKey.values()][0])
            return { ...prevItem, syncSource: true };
          return { ...prevItem, syncSource: false };
        })
      );
      setIncompleteSurvivingSyncSet((prev) =>
        prev.map((prevItem) => {
          if (prevItem?.id === [...survivingSyncSourceKey.values()][0])
            return { ...prevItem, syncSource: true };
          return { ...prevItem, syncSource: false };
        })
      );
    }
  }, [survivingSyncSourceKey]);

  useEffect(() => {
    if (newSyncSourceKey) {
      setCompleteNewSyncSet((prev) =>
        prev.map((prevItem) => {
          if (prevItem?.id === [...newSyncSourceKey.values()][0])
            return { ...prevItem, syncSource: true };
          return { ...prevItem, syncSource: false };
        })
      );
      setIncompleteNewSyncSet((prev) =>
        prev.map((prevItem) => {
          if (prevItem?.id === [...newSyncSourceKey.values()][0])
            return { ...prevItem, syncSource: true };
          return { ...prevItem, syncSource: false };
        })
      );
    }
  }, [newSyncSourceKey]);

  const renderCell = (colKey, row) => {
    if (colKey === "account_id")
      return (
        <Flex gap="size-125">
          <V3Link
            isQuiet
            onPress={() => {
              setSidePaneDetails(
                row,
                row.account_name,
                EAMSourceSystemAccountLabel()
              );
            }}
            alignSelf={row?.syncSource ? "center" : "normal"}
          >
            {row[colKey]}
          </V3Link>
          {row?.syncSource && (
            <Badge
              variant="seafoam"
              alignSelf="center"
              UNSAFE_style={{ fontSize: "x-small" }}
            >
              Original source
            </Badge>
          )}
        </Flex>
      );
    if (colKey === "address_key") {
      const address = `${row.address[0].street ? row.address[0].street : ""} ${
        row.address[0].street_sup ? row.address[0].street_sup : ""
      } ${row.address[0].city ? row.address[0].city : ""}, ${
        row.address[0].state ? row.address[0].state : ""
      } ${row.address[0].country ? row.address[0].country : ""} ${
        row.address[0].postalcode ? row.address[0].postalcode : ""
      }`;
      return <Text>{address}</Text>;
    }
    return <Text>{row[colKey]}</Text>;
  };

  return (
    <View
      UNSAFE_className={`card ${activeStep === 1 ? "active" : "hide"}`}
      key="step1"
      overflow="auto"
    >
      <Flex direction="column" marginTop="size-130" gap="size-125">
        <Flex justifyContent="left">
          <View
            backgroundColor="static-white"
            borderRadius="medium"
            borderWidth="thin"
            borderColor="gray-300"
            padding="size-125"
            width="fit-content"
            UNSAFE_className="stepper-box"
          >
            <Heading>
              {incompleteSurvivingSyncSet?.some((acc) => acc?.syncSource)
                ? "Surviving "
                : "Pick orig source for surviving  "}
              sync set
            </Heading>
          </View>
        </Flex>
        <Flex
          direction="row"
          gap="size-125"
          UNSAFE_style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}
          UNSAFE_className="stepper-box"
        >
          <Table
            columns={eamColumns}
            rows={incompleteSurvivingSyncSet}
            renderCell={renderCell}
            selectedKeys={survivingSyncSourceKey}
            selectionMode={
              survivingSyncSet?.some((acc) => acc?.syncSource)
                ? "none"
                : "single"
            }
            density="compact"
            onSelectionChange={setSurvivingSyncSourceKey}
            disallowEmptySelection
          />
        </Flex>
      </Flex>

      <Flex direction="column" marginTop="size-200" gap="size-125">
        <Flex justifyContent="left">
          <View
            backgroundColor="static-white"
            borderRadius="medium"
            borderWidth="thin"
            borderColor="gray-300"
            padding="size-125"
            width="fit-content"
            UNSAFE_className="stepper-box"
          >
            <Heading>
              {incompleteNewSyncSet?.some((acc) => acc?.syncSource)
                ? "New "
                : "Pick orig source for new  "}
              sync set
            </Heading>
          </View>
        </Flex>
        <Flex
          direction="row"
          gap="size-125"
          UNSAFE_style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}
          UNSAFE_className="stepper-box"
        >
          <Table
            columns={eamColumns}
            rows={incompleteNewSyncSet}
            renderCell={renderCell}
            selectedKeys={newSyncSourceKey}
            selectionMode="single"
            density="compact"
            onSelectionChange={setNewSyncSourceKey}
            disallowEmptySelection
          />
        </Flex>
      </Flex>
    </View>
  );
}

SplitPickOrigStep.propTypes = {
  survivingSyncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  newSyncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  activeStep: PropTypes.number.isRequired,
  setSidePaneDetails: PropTypes.func.isRequired,
  setCompleteSurvivingSyncSet: PropTypes.func.isRequired,
  setCompleteNewSyncSet: PropTypes.func.isRequired,
};

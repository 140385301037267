import {
  ActionButton,
  Badge,
  Button,
  DialogContainer,
  Flex,
  Heading,
  Text,
  View,
} from "@adobe/react-spectrum";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CamSlidingPane from "../../../../components/Common/CamSlidingPane/CamSlidingPane";
import Stepper from "../../../../components/Common/Stepper/Stepper";
import ConfirmSplitMappingDialog from "../../../../components/Dialog/ConfirmSplitMappingDialog";
import LoadingDialog from "../../../../components/Dialog/LoadingDialog";
import PickStateCountryDialog from "../../../../components/Dialog/PickStateCountryDialog";
import AccountMappingForm from "../../../../components/Forms/AccountMappingForm/AccountMappingForm";
import { ExternalAccountMappingPath } from "../../../../constants/Routes";
import { SplitExternalAccountMappingSteps } from "../../../../constants/StepperItems";
import SplitConfirmStep from "./Steps/SplitConfirmStep";
import SplitCreateSyncSetStep from "./Steps/SplitCreateSyncSetStep";
import SplitFinishStep from "./Steps/SplitFinishStep";
import SplitPickOrigStep from "./Steps/SplitPickOrigStep";
import useValidCountriesStateLookup from "../../../../context/isocode-context";

export default function SplitExternalAccountMapping() {
  const [completeSurvivingSyncSet, setCompleteSurvivingSyncSet] = useState([]);
  const [completeNewSyncSet, setCompleteNewSyncSet] = useState([]);
  const [
    origSourceAccountSurvivingSyncSet,
    setOrigSourceAccountSurvivingSyncSet,
  ] = useState();
  const [origSourceAccountNewSyncSet, setOrigSourceAccountNewSyncSet] =
    useState();
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [sidePaneData, setSidePaneData] = useState([]);
  const [sidePaneTitle, setSidePaneTitle] = useState("");
  const [sidePaneLabels, setSidePaneLabels] = useState([]);
  const [typeOfDialog, setTypeOfDialog] = useState();
  const [activeStep, setActiveStep] = useState(1);

  const STEP_ITEMS = SplitExternalAccountMappingSteps();
  const [isPageLoading, setPageLoading] = useState(false);
  const history = useHistory();
  const [typeOfSyncSet, setTypeOfSyncSet] = useState("");

  const { validCountryStateCheck } = useValidCountriesStateLookup();

  useEffect(() => {
    if (
      history.location?.state?.survivingSyncSet &&
      history.location?.state?.newSyncSet
    ) {
      setCompleteSurvivingSyncSet([
        ...history.location?.state?.survivingSyncSet,
      ]);

      setCompleteNewSyncSet(
        history.location?.state?.newSyncSet?.map((acc) => {
          if (acc?.syncSource) return { ...acc, syncSource: false };
          return acc;
        })
      );
    } else {
      history.push(ExternalAccountMappingPath);
    }
  }, []);

  useEffect(() => {
    if (completeSurvivingSyncSet.length > 0) {
      setOrigSourceAccountSurvivingSyncSet(
        completeSurvivingSyncSet?.find((acc) => acc?.syncSource)
      );
    }
  }, [completeSurvivingSyncSet]);

  useEffect(() => {
    if (completeNewSyncSet.length > 0) {
      setOrigSourceAccountNewSyncSet(
        completeNewSyncSet?.find((acc) => acc?.syncSource)
      );
    }
  }, [completeNewSyncSet]);

  const handlePrevStep = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleNextStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    history.push(ExternalAccountMappingPath, {
      searchedAcc: history?.location?.state?.searchedAcc,
    });
  };

  const setSidePaneDetails = (data, title, labels) => {
    setSidePaneLabels(labels);
    setSidePaneData(data);
    setSidePaneTitle(title);
    setIsSidePaneOpen(!isSidePaneOpen);
  };

  const validateAndHandleNext = () => {
    if (
      activeStep === 1 &&
      completeSurvivingSyncSet.some(
        (acc) =>
          acc?.account_id === "new" &&
          (acc?.address[0]?.country?.length !== 2 ||
            (validCountryStateCheck.countries.includes(
              acc?.address[0]?.country
            ) &&
              !validCountryStateCheck.countryStateMap[
                acc?.address[0]?.country
              ].includes(acc?.address[0]?.state)))
      )
    ) {
      setTypeOfSyncSet("surviving sync");
    } else if (
      activeStep === 2 &&
      completeNewSyncSet.some(
        (acc) =>
          acc?.account_id === "new" &&
          (acc?.address[0]?.country?.length !== 2 ||
            (validCountryStateCheck.countries.includes(
              acc?.address[0]?.country
            ) &&
              !validCountryStateCheck.countryStateMap[
                acc?.address[0]?.country
              ].includes(acc?.address[0]?.state)))
      )
    ) {
      setTypeOfSyncSet("new sync");
    } else {
      handleNextStep();
    }
  };

  const handleDialogNext = (state, country) => {
    if (typeOfSyncSet === "surviving sync") {
      setCompleteSurvivingSyncSet((prev) =>
        prev.map((prevItem) => {
          if (prevItem?.account_id === "new") {
            return {
              ...prevItem,
              address: [
                {
                  ...prevItem?.address[0],
                  state: state || prevItem?.address[0]?.state,
                  country: country || prevItem?.address[0]?.country,
                },
              ],
            };
          }
          return prevItem;
        })
      );
    } else if (typeOfSyncSet === "new sync") {
      setCompleteNewSyncSet((prev) =>
        prev.map((prevItem) => {
          if (prevItem?.account_id === "new") {
            return {
              ...prevItem,
              address: [
                {
                  ...prevItem?.address[0],
                  state: state || prevItem?.address[0]?.state,
                  country: country || prevItem?.address[0]?.country,
                },
              ],
            };
          }
          return prevItem;
        })
      );
    }

    setTypeOfSyncSet("");
  };

  useEffect(() => {
    if (
      activeStep === 2 &&
      completeSurvivingSyncSet.some(
        (acc) =>
          acc?.account_id === "new" &&
          (acc?.address[0]?.country?.length !== 2 ||
            (validCountryStateCheck.countries.includes(
              acc?.address[0]?.country
            ) &&
              !validCountryStateCheck.countryStateMap[
                acc?.address[0]?.country
              ].includes(acc?.address[0]?.state)))
      )
    ) {
      setTypeOfSyncSet("surviving sync");
    }
    if (
      activeStep === 3 &&
      completeNewSyncSet.some(
        (acc) =>
          acc?.account_id === "new" &&
          (acc?.address[0]?.country?.length !== 2 ||
            (validCountryStateCheck.countries.includes(
              acc?.address[0]?.country
            ) &&
              !validCountryStateCheck.countryStateMap[
                acc?.address[0]?.country
              ].includes(acc?.address[0]?.state)))
      )
    ) {
      setTypeOfSyncSet("new sync");
    }
  }, [completeSurvivingSyncSet, completeNewSyncSet]);

  useEffect(() => {
    if (typeOfSyncSet === "surviving sync" || typeOfSyncSet === "new sync")
      setTypeOfDialog("pickStateCountry");
  }, [typeOfSyncSet]);

  return (
    <Flex direction="column" gap="size-125" margin="size-300" width="70%">
      <LoadingDialog isOpen={isPageLoading} />
      <Flex gap="size-125" justifyContent="space-between">
        <Heading>Split external account mapping</Heading>
        <Badge
          variant="seafoam"
          UNSAFE_style={{ fontSize: "small" }}
        >{`CAM Id: ${history?.location?.state?.searchedAcc?.cam_id}`}</Badge>
      </Flex>
      <Flex direction="column" gap="size-125">
        <View
          backgroundColor="static-white"
          borderRadius="medium"
          borderWidth="thin"
          borderColor="gray-300"
          padding="size-125"
          UNSAFE_className="stepper-box"
        >
          <Stepper items={STEP_ITEMS} activeItemStep={activeStep} />
        </View>
        <View data-multi-step UNSAFE_className="wrapper">
          <SplitPickOrigStep
            activeStep={activeStep}
            newSyncSet={history.location?.state?.newSyncSet}
            survivingSyncSet={history.location?.state?.survivingSyncSet}
            setCompleteSurvivingSyncSet={setCompleteSurvivingSyncSet}
            setCompleteNewSyncSet={setCompleteNewSyncSet}
            setSidePaneDetails={setSidePaneDetails}
          />
          <View
            UNSAFE_className={`card ${activeStep === 2 ? "active" : "hide"}`}
            key="step2"
          >
            <SplitCreateSyncSetStep
              title="Surviving sync set"
              syncSet={completeSurvivingSyncSet}
              setSyncSet={setCompleteSurvivingSyncSet}
              setSidePaneDetails={setSidePaneDetails}
              origSourceAccount={origSourceAccountSurvivingSyncSet}
              activeStep={activeStep}
            />
          </View>
          <View
            UNSAFE_className={`card ${activeStep === 3 ? "active" : "hide"}`}
            key="step3"
          >
            <SplitCreateSyncSetStep
              title="New sync set"
              syncSet={completeNewSyncSet}
              setSyncSet={setCompleteNewSyncSet}
              setSidePaneDetails={setSidePaneDetails}
              origSourceAccount={origSourceAccountNewSyncSet}
              activeStep={activeStep}
            />
          </View>
          <View
            UNSAFE_className={`card ${activeStep === 4 ? "active" : "hide"}`}
            key="step4"
          >
            <SplitConfirmStep
              survivingSyncSet={completeSurvivingSyncSet}
              newSyncSet={completeNewSyncSet}
            />
          </View>
          <View
            UNSAFE_className={`card ${activeStep === 5 ? "active" : "hide"}`}
            key="step5"
          >
            <SplitFinishStep
              currentSyncSet={history.location?.state?.currentSyncSet}
              survivingSyncSet={completeSurvivingSyncSet}
              newSyncSet={completeNewSyncSet}
            />
          </View>
        </View>
        <Flex marginTop="size-300" justifyContent="space-between">
          <Flex>
            <ActionButton isQuiet onPress={handleBack}>
              <ChevronLeft color="informative" />
              <Text>External account mapping</Text>
            </ActionButton>
          </Flex>
          <Flex gap="size-125">
            {activeStep > 1 && activeStep <= STEP_ITEMS.length - 1 && (
              <Button variant="primary" onPress={handlePrevStep}>
                <Text>Previous</Text>
              </Button>
            )}
            {activeStep <= STEP_ITEMS.length - 1 &&
              activeStep !== STEP_ITEMS.length - 1 && (
                <Button
                  variant="accent"
                  isDisabled={
                    !completeSurvivingSyncSet.some((acc) => acc?.syncSource) ||
                    !completeNewSyncSet.some((acc) => acc?.syncSource)
                  }
                  onPress={validateAndHandleNext}
                >
                  <Text>Next</Text>
                </Button>
              )}

            {activeStep === STEP_ITEMS.length - 1 && (
              <Button
                variant="accent"
                onPress={() => setTypeOfDialog("confirmSplitSyncSet")}
              >
                Submit
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      {isSidePaneOpen && (
        <CamSlidingPane
          isPaneOpenFlag={isSidePaneOpen}
          handleOpenDialog={() => setIsSidePaneOpen(false)}
          paneTitle={sidePaneTitle}
          Component={
            <AccountMappingForm data={sidePaneData} labels={sidePaneLabels} />
          }
        />
      )}

      <DialogContainer onDismiss={() => setTypeOfDialog("")}>
        {typeOfDialog === "confirmSplitSyncSet" && (
          <ConfirmSplitMappingDialog
            currentSyncSet={history.location?.state?.currentSyncSet}
            survivingSyncSet={completeSurvivingSyncSet}
            newSyncSet={completeNewSyncSet}
            handleNextStep={handleNextStep}
            setSurvivingSyncSet={setCompleteSurvivingSyncSet}
            setNewSyncSet={setCompleteNewSyncSet}
            setPageLoading={setPageLoading}
          />
        )}
        {typeOfDialog === "pickStateCountry" && (
          <PickStateCountryDialog
            handleNext={(state, country) => {
              handleDialogNext(state, country);
              handleNextStep();
            }}
            handleBack={() => {
              setTypeOfSyncSet("");
              if (typeOfSyncSet === "surviving sync") setActiveStep(1);
              if (typeOfSyncSet === "new sync") setActiveStep(2);
            }}
          />
        )}
      </DialogContainer>
    </Flex>
  );
}

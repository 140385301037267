import React from "react";
import { Form, View, Text } from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import AddressCard from "./AddressCard/AddressCard";

const AccountMappingForm = (props) => {
  const { data, labels } = props;
  const style = { color: "#4B4B4B", fontSize: "1rem" };
  return (
    <Form UNSAFE_style={{ backgroundColor: "#F5F5F5" }}>
      {labels.map((item) => (
        <View key={item.key} marginBottom={14}>
          <Text UNSAFE_style={{ color: "#707070", fontSize: "0.82rem" }}>
            {item.title}
          </Text>
          <View>
            {item.key === "address" ? (
              <AddressCard addresses={data[item.key]} />
            ) : (
              <Text
                UNSAFE_style={
                  item.key === "street"
                    ? { ...style, whiteSpace: "pre-wrap" }
                    : style
                }
              >
                {data[item.key]}
              </Text>
            )}
          </View>
        </View>
      ))}
    </Form>
  );
};

AccountMappingForm.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType(
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.object)
    )
  ).isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, key: PropTypes.string })
  ).isRequired,
};

export default AccountMappingForm;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Badge, Flex, Heading, Text, View } from "@adobe/react-spectrum";
import Table from "../../../../../components/Common/Table";
import { EAMConfirmChangesColumns } from "../../../../../constants/Columns";

export default function SplitConfirmStep({ survivingSyncSet, newSyncSet }) {
  const [completeSurvivingSyncSet, setCompleteSurvivingSyncSet] = useState([]);
  const [completeNewSyncSet, setCompleteNewSyncSet] = useState([]);
  const eamConfirmChangesColumns = EAMConfirmChangesColumns();

  useEffect(() => {
    setCompleteSurvivingSyncSet(
      survivingSyncSet?.filter(
        (acc) => !("isNewAccount" in acc) || acc?.isNewAccount
      )
    );
    setCompleteNewSyncSet(
      newSyncSet?.filter((acc) => !("isNewAccount" in acc) || acc?.isNewAccount)
    );
  }, [survivingSyncSet, newSyncSet]);

  const renderCell = (colKey, row) => {
    let cellStyle = {};

    if (row?.isNewAccount) {
      cellStyle = { color: "green" };
    }

    if (colKey === "account_id" && row?.syncSource)
      return (
        <Flex gap="size-125">
          <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
          <Badge
            variant="seafoam"
            alignSelf="center"
            UNSAFE_style={{ fontSize: "x-small" }}
          >
            Original source
          </Badge>
        </Flex>
      );

    return <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };

  return (
    <Flex marginTop="size-300" justifyContent="space-between" gap="size-125">
      <View width="50%">
        <Flex justifyContent="center">
          <View
            backgroundColor="static-white"
            borderRadius="medium"
            borderWidth="thin"
            borderColor="gray-300"
            padding="size-125"
            width="size-2000"
            UNSAFE_className="stepper-box"
          >
            <Flex justifyContent="center">
              <Heading>Surviving sync set</Heading>
            </Flex>
          </View>
        </Flex>
        <Flex
          direction="row"
          gap="size-125"
          marginTop="size-300"
          UNSAFE_style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}
          UNSAFE_className="stepper-box"
        >
          <Table
            columns={eamConfirmChangesColumns}
            rows={completeSurvivingSyncSet}
            renderCell={renderCell}
            density="compact"
          />
        </Flex>
      </View>
      <View width="50%">
        <Flex justifyContent="center">
          <View
            backgroundColor="static-white"
            borderRadius="medium"
            borderWidth="thin"
            borderColor="gray-300"
            padding="size-125"
            width="size-2000"
            UNSAFE_className="stepper-box"
          >
            <Flex justifyContent="center">
              <Heading>New sync set</Heading>
            </Flex>
          </View>
        </Flex>
        <Flex
          direction="row"
          gap="size-125"
          marginTop="size-300"
          UNSAFE_style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
          }}
          UNSAFE_className="stepper-box"
        >
          <Table
            columns={eamConfirmChangesColumns}
            rows={completeNewSyncSet}
            renderCell={renderCell}
            density="compact"
          />
        </Flex>
      </View>
    </Flex>
  );
}

SplitConfirmStep.propTypes = {
  survivingSyncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
  newSyncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
      ])
    )
  ).isRequired,
};

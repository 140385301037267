export const RemapExternalAccountSteps = () => {
  const STEP_ITEMS = [
    { step: 1, title: "Select", description: "Move in account" },
    { step: 2, title: "Confirm", description: "Validate & submit changes" },
    { step: 3, title: "Finish", description: "View new changes" },
  ];

  return STEP_ITEMS;
};

export const SplitExternalAccountMappingSteps = () => {
  const STEP_ITEMS = [
    { step: 1, title: "Pick", description: "Orig source for sync sets" },
    { step: 2, title: "Complete", description: "Surviving sync set" },
    { step: 3, title: "Create", description: "New split sync set" },
    { step: 4, title: "Confirm", description: "Validate & submit changes" },
    { step: 5, title: "Finish", description: "View new changes" },
  ];

  return STEP_ITEMS;
};

export const CreateNewLagGroupSteps = () => {
  const STEP_ITEMS = [
    { step: 1, title: "Create", description: "Create new group" },
    { step: 2, title: "Confirm", description: "Validate & submit changes" },
    { step: 3, title: "Finish", description: "View new changes" },
  ];

  return STEP_ITEMS;
};

export const OverrideLagGroupSteps = () => {
  const STEP_ITEMS = [
    {
      step: 1,
      title: "Override",
      description: "Override attributes of existing group",
    },
    { step: 2, title: "Finish", description: "View new changes" },
  ];

  return STEP_ITEMS;
};

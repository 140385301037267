import {
  Badge,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Text,
  useDialogContainer,
  View,
} from "@adobe/react-spectrum";
import { useOktaAuth } from "@okta/okta-react";
import { success, error } from "@react/react-spectrum/Toast";
import Unmerge from "@spectrum-icons/workflow/Unmerge";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { eamApi } from "../../api/eamApi";
import { EAMConfirmChangesColumns } from "../../constants/Columns";
import useUserProfile from "../../context/user-context";
import Table from "../Common/Table";
import useValidCountriesStateLookup from "../../context/isocode-context";

export default function ConfirmSplitMappingDialog({
  currentSyncSet,
  survivingSyncSet,
  newSyncSet,
  handleNextStep,
  setSurvivingSyncSet,
  setNewSyncSet,
  setPageLoading,
}) {
  const eamColumns = EAMConfirmChangesColumns();
  const [newSurvSyncSet, setNewSurvSyncSet] = useState([]);
  const [newSplitSyncSet, setNewSplitSyncSet] = useState([]);
  const [splitPayload, setSplitPayload] = useState({
    requested_by: "",
    surviving_sync_set: null,
    new_sync_set: null,
  });
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();
  const dialog = useDialogContainer();
  const { validCountryStateCheck } = useValidCountriesStateLookup();

  const renderCell = (colKey, row) => {
    let cellStyle = {};

    if (row?.isNewAccount) {
      cellStyle = { color: "green" };
    }

    if (colKey === "account_id" && row?.syncSource)
      return (
        <Flex gap="size-125">
          <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>
          <Badge
            variant="seafoam"
            alignSelf="center"
            UNSAFE_style={{ fontSize: "x-small" }}
          >
            Original source
          </Badge>
        </Flex>
      );

    return <Text UNSAFE_style={cellStyle}>{row[colKey]}</Text>;
  };

  const validateAndFetchAddress = (
    survivingSyncSetOriginalSource,
    newSyncSetOriginalSource
  ) => {
    const survivingSyncNewAddress = survivingSyncSet?.find(
      (acc) => acc?.account_id === "new"
    );
    const newSyncNewAddress = newSyncSet?.find(
      (acc) => acc?.account_id === "new"
    );

    let survivingSyncOrigSourceData = null;
    let newSyncOrigSourceData = null;

    if (
      survivingSyncSetOriginalSource?.address[0]?.country?.length !== 2 ||
      (validCountryStateCheck.countries.includes(
        survivingSyncSetOriginalSource?.address[0]?.country
      ) &&
        !validCountryStateCheck.countryStateMap[
          survivingSyncSetOriginalSource?.address[0]?.country
        ].includes(survivingSyncSetOriginalSource?.address[0]?.state))
    ) {
      survivingSyncOrigSourceData = {
        address: {
          country: survivingSyncNewAddress?.address[0]?.country,
          state: survivingSyncNewAddress?.address[0]?.state,
        },
      };
    }

    if (
      newSyncSetOriginalSource?.address[0]?.country?.length !== 2 ||
      (validCountryStateCheck.countries.includes(
        newSyncSetOriginalSource?.address[0]?.country
      ) &&
        !validCountryStateCheck.countryStateMap[
          newSyncSetOriginalSource?.address[0]?.country
        ].includes(newSyncSetOriginalSource?.address[0]?.state))
    ) {
      newSyncOrigSourceData = {
        address: {
          country: newSyncNewAddress?.address[0]?.country,
          state: newSyncNewAddress?.address[0]?.state,
        },
      };
    }

    return { survivingSyncOrigSourceData, newSyncOrigSourceData };
  };

  const handleSplitPayload = () => {
    const curentSyncSetOriginalSource = currentSyncSet?.find(
      (acc) => acc?.syncSource
    );
    const survivingSyncSetOriginalSource = survivingSyncSet?.find(
      (acc) => acc?.syncSource
    );
    const newSyncSetOriginalSource = newSyncSet?.find((acc) => acc?.syncSource);

    const survivingSyncAccounts = survivingSyncSet
      ?.filter((acc) => acc?.isNewAccount)
      ?.map((acc) => {
        if (acc?.account_id === "new")
          return {
            copy_orig_crm: true,
            crm_sourcesystem_name: acc?.source_system,
          };

        return {
          crm_account_id: acc?.account_id,
          crm_sourcesystem_name: acc?.source_system,
        };
      });

    const newSyncAccounts = newSyncSet?.map((acc) => {
      if (acc?.account_id === "new")
        return {
          copy_orig_crm: true,
          crm_sourcesystem_name: acc?.source_system,
        };

      return {
        crm_account_id: acc?.account_id,
        crm_sourcesystem_name: acc?.source_system,
      };
    });

    const origSourceData = validateAndFetchAddress(
      survivingSyncSetOriginalSource,
      newSyncSetOriginalSource
    );

    setSplitPayload((prev) => ({
      ...prev,
      surviving_sync_set: {
        orig_source_data: origSourceData?.survivingSyncOrigSourceData,
        orig_source_system_account_id: curentSyncSetOriginalSource?.account_id,
        orig_source_system_name: curentSyncSetOriginalSource?.source_system,
        set_orig_source:
          survivingSyncSetOriginalSource &&
          `${survivingSyncSetOriginalSource?.account_id}:${survivingSyncSetOriginalSource?.source_system}` !==
            `${curentSyncSetOriginalSource?.account_id}:${curentSyncSetOriginalSource?.source_system}`
            ? {
                orig_source_system_account_id:
                  survivingSyncSetOriginalSource?.account_id,
                orig_source_system_name:
                  survivingSyncSetOriginalSource?.source_system,
              }
            : null,
        sync_accounts: [...survivingSyncAccounts],
      },
      new_sync_set: {
        orig_source_data: origSourceData?.newSyncOrigSourceData,
        set_orig_source: {
          orig_source_system_account_id: newSyncSetOriginalSource?.account_id,
          orig_source_system_name: newSyncSetOriginalSource?.source_system,
        },
        sync_accounts: [...newSyncAccounts],
      },
    }));
  };

  useEffect(() => {
    const newSurvivingSetRows = survivingSyncSet?.filter(
      (acc) => !("isNewAccount" in acc) || acc?.isNewAccount
    );

    const newSelectedSetRows = newSyncSet?.filter(
      (acc) => !("isNewAccount" in acc) || acc?.isNewAccount
    );

    setNewSurvSyncSet(newSurvivingSetRows);
    setNewSplitSyncSet(newSelectedSetRows);
    handleSplitPayload();
  }, [survivingSyncSet, newSyncSet]);

  useEffect(() => {
    setSplitPayload((prev) => ({
      ...prev,
      requested_by: user?.userId,
    }));
  }, []);

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
    setPageLoading(false);
  };

  const handleSplitConfirm = () => {
    dialog.dismiss();
    setPageLoading(true);
    eamApi
      .splitExternalAccountMapping(splitPayload, authState.accessToken)
      .then((data) => {
        if (
          data?.surviving_mcs_mapping?.mcs_synced_accounts &&
          data?.new_mcs_mapping?.mcs_synced_accounts
        ) {
          setSurvivingSyncSet(data?.surviving_mcs_mapping?.mcs_synced_accounts);
          setNewSyncSet(data?.new_mcs_mapping?.mcs_synced_accounts);
          success(`Split has been performed successfully!`, {
            timeout: 5000,
          });
          setPageLoading(false);
          handleNextStep();
        } else {
          handleError("error");
        }
      })
      .catch((e) => handleError(e));
  };

  return (
    <Dialog size="L" width="75rem">
      <Heading>Confirm split changes</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-500">
          <Text>
            Are you sure you want to split current sync set as shown below?
          </Text>
          <Flex direction="row" gap="size-125">
            <Flex
              direction="column"
              justifyContent="center"
              gap="size-125"
              width="50%"
            >
              <Flex justifyContent="center">
                <Heading level={4}>Current sync set</Heading>
              </Flex>
              <Flex
                direction="row"
                gap="size-125"
                UNSAFE_style={{
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                }}
                UNSAFE_className="stepper-box"
              >
                <Table
                  columns={eamColumns}
                  rows={currentSyncSet}
                  renderCell={renderCell}
                  selectionMode="none"
                  density="compact"
                />
              </Flex>
            </Flex>
            <Flex justifyContent="center" alignItems="center">
              <View>
                <Unmerge color="informative" aria-label="Split" />
              </View>
            </Flex>
            <Flex direction="column" gap="size-125" width="50%">
              <Flex justifyContent="center">
                <Heading level={4}>New surving sync set</Heading>
              </Flex>

              <Flex
                direction="row"
                gap="size-125"
                UNSAFE_style={{
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                }}
                UNSAFE_className="stepper-box"
              >
                <Table
                  columns={eamColumns}
                  rows={newSurvSyncSet}
                  renderCell={renderCell}
                  density="compact"
                />
              </Flex>
              <Flex justifyContent="center">
                <Heading level={4}>New selected sync set</Heading>
              </Flex>

              <Flex
                direction="row"
                gap="size-125"
                UNSAFE_style={{
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                }}
                UNSAFE_className="stepper-box"
              >
                <Table
                  columns={eamColumns}
                  rows={newSplitSyncSet}
                  renderCell={renderCell}
                  density="compact"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Cancel
        </Button>
        <Button variant="accent" onPress={handleSplitConfirm}>
          Confirm
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

ConfirmSplitMappingDialog.propTypes = {
  currentSyncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.array,
      ])
    )
  ).isRequired,
  survivingSyncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.array,
      ])
    )
  ).isRequired,
  newSyncSet: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.array,
      ])
    )
  ).isRequired,
  handleNextStep: PropTypes.func.isRequired,
  setSurvivingSyncSet: PropTypes.func.isRequired,
  setNewSyncSet: PropTypes.func.isRequired,
  setPageLoading: PropTypes.func.isRequired,
};

import { Flex } from "@adobe/react-spectrum";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  ReceivedGroupRequestsPath,
  SentGroupRequestsPath,
} from "../../../constants/Routes";
import Card from "../../../components/Common/Card/Card";

export default function GroupRequests() {
  const history = useHistory();

  const handleOnPress = (path) => {
    history.push(path);
  };
  return (
    <Flex gap="size-200" margin="1rem">
      <Card
        title="Submitted Group Requests"
        content="List all group requests submitted by me."
        handleOnPress={() => handleOnPress(SentGroupRequestsPath)}
      />
      <Card
        title="Pending Request Approvals"
        content="List all group requests pending for approvals."
        handleOnPress={() => handleOnPress(ReceivedGroupRequestsPath)}
      />
    </Flex>
  );
}

const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `${window.apcConfig.cam.REACT_APP_URL}/cam/login/callback`;

const OIDC_CONFIG = {
  oidc: {
    clientId: window.apcConfig.cam.OKTA_CLIENT_ID,
    issuer: window.apcConfig.cam.OKTA_ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email", "groups"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    tokenManager: {
      storage: "sessionStorage",
    },
  },
};

export default OIDC_CONFIG;

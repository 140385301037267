import axios from "../config/axios-config";
/* eslint-disable import/prefer-default-export */

const fetchGroupKeys = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const responseData = await axios.post(`/api/lag/getgroupkeylist`, {
    status: "approved",
  });
  return responseData.data;
};

const fetchTypeaheadResults = async (params, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/lag/typeahead`, params);
  const responseData = await response.data;
  return responseData;
};

const fetchLAGLookup = async (params, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/lag/searchlag`, params);
  const responseData = await response.data;
  return responseData;
};

const fetchGroupableFields = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(`/api/lag/getgroupingfields`);
  const responseData = await response.data;
  return responseData;
};

const fetchGroupInfo = async (params, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/lag/viewgroup`, params);
  const responseData = await response.data;
  return responseData;
};

const fetchPreviewGroupData = async (params, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/lag/searchbeforecreate`, params);
  const responseData = await response.data;
  return responseData;
};

const fetchGroupKey = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(`/api/lag/generategroupkey`);
  const responseData = await response.data;
  return responseData.group_key;
};

const sendGroupRequest = async (request, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/lag/creategroup`, request);
  const responseData = await response.data;
  return responseData;
};

const fetchOverrideGroupDetails = async (params, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/lag/overridehistory`, params);
  const responseData = await response.data;
  return responseData.lag_override_history;
};

const sendOverrideChanges = async (params, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/lag/override`, params);
  const responseData = await response.data;
  return responseData;
};

const fetchGroupsByStatus = async (params, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/lag/listgroups`, params);
  const responseData = await response.data;
  return responseData;
};

const approveLAGRequest = async (params, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/lag/approvegroup`, params);
  const responseData = await response.data;
  return responseData;
};

const rejectLAGRequest = async (params, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/lag/rejectgroup`, params);
  const responseData = await response.data;
  return responseData;
};

const fetchCodePromotionEnvs = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(`/api/lag/listlagenv`);
  const responseData = await response.data;
  return responseData;
};

const promoteGroup = async (params, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/lag/promotegroup`, params);
  const responseData = await response.data;
  return responseData;
};

export const lagApi = {
  fetchGroupKeys,
  fetchLAGLookup,
  fetchTypeaheadResults,
  fetchGroupableFields,
  fetchGroupInfo,
  fetchPreviewGroupData,
  fetchGroupKey,
  sendGroupRequest,
  fetchOverrideGroupDetails,
  sendOverrideChanges,
  fetchGroupsByStatus,
  approveLAGRequest,
  rejectLAGRequest,
  fetchCodePromotionEnvs,
  promoteGroup,
};

import { lightTheme, Provider as ProviderV3 } from "@adobe/react-spectrum";
import Close from "@spectrum-icons/workflow/Close";
import PropTypes from "prop-types";
import React, { useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./CamSlidingPane.css";

const CamSlidingPane = (props) => {
  const { isPaneOpenFlag, paneTitle, handleOpenDialog, Component } = props;
  const [state, setState] = useState({
    isPaneOpen: isPaneOpenFlag,
    isPaneOpenLeft: false,
  });

  return (
    <SlidingPane
      className="cam-sliding-pane"
      closeIcon={<Close />}
      isOpen={state.isPaneOpen}
      width="25rem"
      title={paneTitle}
      onRequestClose={() => {
        setState({ isPaneOpen: false });
        handleOpenDialog();
      }}
    >
      <ProviderV3 theme={lightTheme} height="inherit">
        {Component}
      </ProviderV3>
    </SlidingPane>
  );
};

CamSlidingPane.defaultProps = {
  Component: undefined,
};
CamSlidingPane.propTypes = {
  isPaneOpenFlag: PropTypes.bool.isRequired,
  paneTitle: PropTypes.string.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  Component: PropTypes.node,
};

export default CamSlidingPane;

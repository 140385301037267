import {
  Flex,
  View,
  Text,
  TooltipTrigger,
  ActionButton,
  Tooltip,
} from "@adobe/react-spectrum";
import Edit from "@spectrum-icons/workflow/Edit";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "../../../../components/Common/Table";
import CamSlidingPane from "../../../../components/Common/CamSlidingPane/CamSlidingPane";
import OverrideGroupForm from "../../../../components/Forms/LAG/OverrideGroupForm";
import { lagApi } from "../../../../api/lagApi";

export default function Step1({
  activeStep,
  overrideGroup,
  changedAttributes,
  setChangedAttributes,
  overrideAttributes,
  setOverrideAttributes,
  overrideGroupColumns,
  setOverrideGroupColumns,
  groupDescription,
}) {
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const { authState } = useOktaAuth();

  useEffect(() => {
    lagApi
      .fetchGroupInfo(
        { group_key: overrideGroup.group_key },
        authState.accessToken
      )
      .then((data) => {
        setOverrideAttributes(
          Object.fromEntries(
            data.attributes.map((attr) => [
              attr.field_name,
              overrideGroup[attr.field_name],
            ])
          )
        );
        const dynamicColumns = [];
        data.attributes
          .map((a) => a.field_name)
          .forEach((attr) => {
            dynamicColumns.push({
              uid: attr,
              name:
                attr.charAt(0).toUpperCase() + attr.slice(1).replace("_", " "),
            });
          });
        dynamicColumns.push({
          name: "Edit",
          uid: "edit",
          maxWidth: 50,
        });
        setOverrideGroupColumns([...overrideGroupColumns, ...dynamicColumns]);
      });
  }, []);

  const renderCell = (colKey, row) => {
    if (Object.keys(overrideAttributes).includes(colKey)) {
      if (changedAttributes && Object.keys(changedAttributes).includes(colKey))
        return (
          <Flex direction="row" alignItems="center">
            <Text UNSAFE_style={{ color: "green" }}>
              {changedAttributes[colKey]}
            </Text>
          </Flex>
        );
      return <Text>{row[colKey]}</Text>;
    }
    if (colKey === "edit")
      return (
        <TooltipTrigger alignItems="right">
          <ActionButton isQuiet onPress={() => setIsSidePaneOpen(true)}>
            <View>
              <Edit color="informative" />
            </View>
          </ActionButton>
          <Tooltip>Override Value</Tooltip>
        </TooltipTrigger>
      );
    if (colKey === "group_description")
      return (
        <Text UNSAFE_style={{ color: "darkgrey" }}>{groupDescription}</Text>
      );
    return <Text UNSAFE_style={{ color: "darkgrey" }}>{row[colKey]}</Text>;
  };

  return (
    <View
      UNSAFE_className={`card ${activeStep === 1 ? "active" : "hide"}`}
      key="step1"
      overflow="auto"
    >
      <Flex direction="row" gap="size-250">
        <Flex direction="column" gap="size-125" width="100%">
          <Flex
            direction="row"
            gap="size-125"
            marginTop="size-300"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={overrideGroupColumns}
              rows={[{ ...overrideGroup }]}
              selectionMode="none"
              renderCell={renderCell}
              density="compact"
            />
          </Flex>
        </Flex>
        {isSidePaneOpen && (
          <CamSlidingPane
            isPaneOpenFlag={isSidePaneOpen}
            handleOpenDialog={() => setIsSidePaneOpen(false)}
            paneTitle="Override Group"
            Component={
              <OverrideGroupForm
                overrideAttributes={overrideAttributes}
                overrideGroup={overrideGroup}
                groupColumns={overrideGroupColumns.map((col) => col.uid)}
                setIsSidePaneOpen={setIsSidePaneOpen}
                overridenAttributes={changedAttributes}
                setOverridenAttributes={setChangedAttributes}
              />
            }
          />
        )}
      </Flex>
    </View>
  );
}

Step1.propTypes = {
  activeStep: PropTypes.number.isRequired,
  overrideGroup: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    ])
  ).isRequired,
  changedAttributes: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    ])
  ).isRequired,
  setChangedAttributes: PropTypes.func.isRequired,
  overrideAttributes: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    ])
  ).isRequired,
  setOverrideAttributes: PropTypes.func.isRequired,
  overrideGroupColumns: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ).isRequired,
  setOverrideGroupColumns: PropTypes.func.isRequired,
  groupDescription: PropTypes.string.isRequired,
};

import React from "react";
import AppLogo from "./AppLogo/AppLogo";
import MainNavigation from "./MainNavigation/MainNavigation";
import "./header.css";
import UserInfo from "./UserInfo/UserInfo";

function Header() {
  return (
    <div className="header cam-font">
      <div className="header-container">
        <AppLogo />
        <MainNavigation />
        <UserInfo />
      </div>
    </div>
  );
}

export default Header;

import {
  Divider,
  Flex,
  Heading,
  Item,
  SearchField,
  View,
  DialogContainer,
  ActionButton,
  Picker,
  Link as V3Link,
  Text,
  Header,
} from "@adobe/react-spectrum";
import Workflow from "@spectrum-icons/workflow/Workflow";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { error } from "@react/react-spectrum/Toast";
import CamSlidingPane from "../../components/Common/CamSlidingPane/CamSlidingPane";
import AccountRelDialog from "../../components/Dialog/AccountRelDialog";
import Table from "../../components/Common/Table";
import AccountMappingForm from "../../components/Forms/AccountMappingForm/AccountMappingForm";
import {
  AccountRelationshipColumn,
  SourceSystemAccountsColumn,
  StandardizedAccountsColumn,
} from "../../constants/Columns";
import {
  SourceSystemAccountsLabel,
  StandardizedAccountsLabel,
} from "../../constants/SlidingPaneLabels";
import "./account-mapping.css";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import { crossSystemAccountApi } from "../../api/lookupApi";

const options = [
  {
    id: "camid",
    name: "CAM Id",
  },
  {
    id: "accountid",
    name: "Source System Account Id",
  },
];

export default function AccountMapping() {
  const LOOKUP_REQUEST = {};
  const standardizedAccountsColumn = StandardizedAccountsColumn();
  const sourceSystemAccountsColumn = SourceSystemAccountsColumn();
  const accountRelationshipColumn = AccountRelationshipColumn();
  const [lookupAccounts, setLookupAccounts] = useState([]);
  const [standardisedAccounts, setStandardisedAccounts] = useState();
  const [sourceSystemAccounts, setSourceSystemAccounts] = useState([]);
  const [sourceSystemAccountMap, setSourceSystemAccountMap] = useState({});
  const [camId, setCamId] = useState("");
  const [sourceSystemAccountId, setSourceSystemAccountId] = useState("");
  const { authState } = useOktaAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [slidingPaneData, setSlidingPaneData] = useState({});
  const [slidingPaneLabels, setSlidingPaneLabels] = useState([]);
  const [camidSelected, setCamidSelected] = useState("");
  const [accountRelData, setAccountRelData] = useState([]);
  const [typeOfDialog, setTypeOfDialog] = useState();
  const [filters, setFilters] = useState({ typeOfAccount: "camid", id: "" });
  const [isPageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (filters.typeOfAccount === "accountid") {
      setSourceSystemAccountId(filters.id);
    }
    if (filters.typeOfAccount === "camid") {
      setCamId(filters.id);
    }
  }, [filters]);

  const createStandardizedAccount = (account, index) => {
    const standardizedAccount = account;
    standardizedAccount.address_key = `${
      account.street ? account.street : ""
    } ${account.street_sup ? account.street_sup : ""} ${
      account.city ? account.city : ""
    }, ${account.state ? account.state : ""} ${
      account.country ? account.country : ""
    } ${account.postalcode ? account.postalcode : ""}`;
    const createdDate = new Date(account.createddatetime);
    standardizedAccount.sliding_created_at = `${createdDate.toUTCString()} by ${
      account.createdby
    }`;
    const modifiedDate = new Date(account.modifieddatetime);
    standardizedAccount.sliding_last_updated = `${modifiedDate.toUTCString()} by ${
      account.modifiedby
    }`;
    standardizedAccount.index = index;

    if (index === 0) setCamidSelected(standardizedAccount.camid);
    return standardizedAccount;
  };

  const createSourceSystem = (sourceSystemList) => {
    sourceSystemList.forEach((account, index) => {
      const sourceSystemAccount = account;
      sourceSystemAccount.index = index;
      const createdDate = new Date(account.createddatetime);
      const modifiedDate = new Date(account.modifieddatetime);
      sourceSystemAccount.sliding_created_at = `${createdDate.toUTCString()} by ${
        account.createdby
      }`;
      sourceSystemAccount.sliding_last_updated = `${modifiedDate.toUTCString()} by ${
        account.modifiedby
      }`;
      const address = account.address[0];
      sourceSystemAccount.address_key = `${
        address.street ? address.street : ""
      } ${address.street_sup ? address.street_sup : ""} ${
        address.city ? address.city : ""
      }, ${address.state ? address.state : ""} ${
        address.country ? address.country : ""
      } ${address.postalcode ? address.postalcode : ""}`;
      sourceSystemAccount.street = address.street ? address.street : "";
      sourceSystemAccount.street_sup = address.street_sup
        ? address.street_sup
        : "";
      sourceSystemAccount.city = address.city ? address.city : "";
      sourceSystemAccount.state = address.state ? address.state : "";
      sourceSystemAccount.postalcode = address.postalcode
        ? address.postalcode
        : "";
      sourceSystemAccount.country = address.country ? address.country : "";
      sourceSystemAccount.id = account.source_system.concat(
        ":",
        account.account_id
      );
    });
    return sourceSystemList;
  };

  useEffect(() => {
    if (lookupAccounts.length > 0 && !lookupAccounts[0].message) {
      const stdAccounts = [];

      lookupAccounts.forEach((accounts, index) => {
        const standardizedAccount = accounts.standardized_account;
        const stdData = createStandardizedAccount(standardizedAccount, index);
        stdAccounts.push(stdData);

        const sourceSystemList = accounts.source_system_account_list;
        if (sourceSystemList) {
          const srcActList = createSourceSystem(sourceSystemList);
          sourceSystemAccountMap[stdData.camid] = srcActList;
          setSourceSystemAccountMap(sourceSystemAccountMap);
          if (index === 0) {
            setSourceSystemAccounts(srcActList);
          }
        }
      });

      setStandardisedAccounts(stdAccounts);
    }
  }, [lookupAccounts]);

  const clearStandardisedAndSourceAccounts = () => {
    setStandardisedAccounts([]);
    setSourceSystemAccounts([]);
  };

  const handleSourceAccountChange = (camid) => {
    setCamidSelected(camid);
    const sourceSystemList = sourceSystemAccountMap[camid];
    const srcActList = createSourceSystem(sourceSystemList);
    setSourceSystemAccounts(srcActList);
  };

  const handleError = (e) => {
    if (e.toString().toLowerCase().includes("network"))
      error(`Server not reachable! Please contact CAM team!`, {
        timeout: 5000,
      });
    else if (e?.response?.data) {
      error(e?.response?.data?.message, {
        timeout: 5000,
      });
    } else {
      error(`Sorry something went wrong! Please contact CAM team!`, {
        timeout: 5000,
      });
    }
  };

  const changeSuccessState = (data) => {
    const msg = data[0].message;
    if (msg && msg !== "" && msg.trim() !== "") {
      clearStandardisedAndSourceAccounts();
      setLookupAccounts([]);
    } else {
      clearStandardisedAndSourceAccounts();
      setLookupAccounts([...data]);
    }
  };

  const getData = () => {
    setPageLoading(true);
    if (filters.typeOfAccount === "camid") {
      LOOKUP_REQUEST.camid = camId;
    } else if (filters.typeOfAccount === "accountid") {
      LOOKUP_REQUEST.account_id = sourceSystemAccountId;
    }

    if (Object.keys(LOOKUP_REQUEST).length > 0) {
      crossSystemAccountApi
        .fetchAccountMappings(LOOKUP_REQUEST, authState.accessToken)
        .then((data) => {
          changeSuccessState(data);
          setPageLoading(false);
        })
        .catch((e) => {
          handleError(e);
          setPageLoading(false);
        });
    }
  };

  const handleOpenDialog = (data, accountType) => {
    let label = [];
    if (accountType === "standardized") {
      label = StandardizedAccountsLabel();
    } else {
      label = SourceSystemAccountsLabel();
    }

    setSlidingPaneData(data);
    setSlidingPaneLabels(label);
    setOpenDialog(!openDialog);
  };

  const renderCamTableCell = (colKey, data) => {
    if (colKey === "camid") {
      return (
        <Text>
          <V3Link
            isQuiet
            onPress={() => handleOpenDialog(data, "standardized")}
          >
            {data[colKey]}
          </V3Link>
        </Text>
      );
    }

    if (colKey === "modifieddatetime") {
      const date = new Date(data[colKey]);
      return <Text>{date.toUTCString()}</Text>;
    }

    return <Text className="prewrap">{data[colKey]}</Text>;
  };

  function handleActRelPopup(data) {
    if (data.account_relationship_list) {
      setAccountRelData(data.account_relationship_list);
      setTypeOfDialog("accRelPopup");
    }
  }

  const renderSourceAccountCell = (colKey, data) => {
    if (colKey === "modifieddatetime") {
      const date = new Date(data[colKey]);
      return <Text>{date.toUTCString()}</Text>;
    }

    if (colKey === "account_id") {
      return (
        <Text>
          <V3Link
            isQuiet
            onPress={() => handleOpenDialog(data, "sourcesystem")}
          >
            {data[colKey]}
          </V3Link>
        </Text>
      );
    }

    if (colKey === "account_relationship") {
      const isRel =
        data.account_relationship_list &&
        data.account_relationship_list.length > 0;
      return (
        <>
          {isRel && (
            <ActionButton
              aria-label="Icon only"
              onPress={() => handleActRelPopup(data)}
              isQuiet
            >
              <Workflow />
            </ActionButton>
          )}
        </>
      );
    }

    return <Text className="prewrap">{data[colKey]}</Text>;
  };

  const renderAccountRelCell = (colKey, data) => {
    if (colKey === "modifieddatetime") {
      const date = new Date(data[colKey]);
      return <Text>{date.toUTCString()}</Text>;
    }

    return <Text className="prewrap">{data[colKey]}</Text>;
  };

  const standardizedAccountRowClicked = (camid) => {
    handleSourceAccountChange(camid);
  };

  return (
    <View marginTop="1rem" margin="size-300">
      <LoadingDialog isOpen={isPageLoading} />
      <Heading>Look up account mapping</Heading>
      <Flex gap="size-150" wrap marginTop="size-300">
        <Picker
          width="200px"
          label="Choose type of account"
          items={options}
          selectedKey={filters.typeOfAccount}
          onSelectionChange={(selected) => {
            setFilters((prev) => ({ ...prev, typeOfAccount: selected }));
          }}
        >
          {(item) => <Item key={item.id}>{item.name}</Item>}
        </Picker>
        <SearchField
          value={filters.id}
          onChange={(value) => {
            setFilters((prev) => ({ ...prev, id: value }));
          }}
          label="Search by id"
          onSubmit={getData}
        />
      </Flex>
      {standardisedAccounts && (
        <View>
          <Flex
            marginTop="size-700"
            UNSAFE_className="stepper-box"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            height={
              standardisedAccounts?.length > 0
                ? "fit-content"
                : "static-size-4600"
            }
          >
            <Table
              columns={standardizedAccountsColumn}
              rows={standardisedAccounts}
              renderCell={renderCamTableCell}
              selectionMode="none"
              density="compact"
              onAction={standardizedAccountRowClicked}
            />
          </Flex>
          {sourceSystemAccounts.length > 0 && (
            <View>
              <Divider size="M" marginTop="size-600" />
              {sourceSystemAccounts.length > 0 && (
                <Header id="asa" marginTop="10px">
                  Source Accounts For CAM ID : {camidSelected}
                </Header>
              )}
              {sourceSystemAccounts.length > 0 && (
                <>
                  <Flex
                    marginTop="size-300"
                    UNSAFE_className="stepper-box"
                    UNSAFE_style={{
                      backgroundColor: "white",
                      borderRadius: "0.5rem",
                    }}
                    height={
                      sourceSystemAccounts?.length < 4
                        ? "fit-content"
                        : "size-3400"
                    }
                  >
                    <Table
                      id="accountrelTable"
                      columns={sourceSystemAccountsColumn}
                      rows={sourceSystemAccounts}
                      renderCell={renderSourceAccountCell}
                      selectionMode="none"
                      density="compact"
                    />
                  </Flex>
                  <DialogContainer onDismiss={() => setTypeOfDialog("")}>
                    {typeOfDialog === "accRelPopup" && (
                      <AccountRelDialog
                        accountRelationshipColumn={accountRelationshipColumn}
                        accountRelData={accountRelData}
                        renderAccountRelCell={renderAccountRelCell}
                      />
                    )}
                  </DialogContainer>
                </>
              )}
            </View>
          )}
        </View>
      )}
      {openDialog && (
        <CamSlidingPane
          isPaneOpenFlag={openDialog}
          handleOpenDialog={() => setOpenDialog(false)}
          paneTitle={slidingPaneData.account_name}
          Component={
            <AccountMappingForm
              data={slidingPaneData}
              labels={slidingPaneLabels}
            />
          }
        />
      )}
    </View>
  );
}
